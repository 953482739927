import sharedImports from '../../../component/Comman/sharedImports';
const {
    React,
    AppBar,
    Box,
    Stack,
    Grid,
    Card,
    Slide,
    CardContent,
    Select,
    Button,
    Toolbar,
    DemoItem,
    MenuItem,
    PropTypes,
    DatePicker,
    Typography,
    FormControl,
    AdapterDayjs,
    useScrollTrigger,
    LocalizationProvider,
    HighlightOffIcon,
    VisibilityOutlinedIcon,
} = sharedImports;

const drawerWidth = 300;
function HideOnScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
};
const styles = {
    TableRow: {
        th: {
            p: 2,
            svg: {
                float: 'right',
                color: '#1976d2'
            },
        },
        td: {
            border: '1px solid lightgray',
            p: 1,
        }
    },
    h: {
        minHeight: '40px !important'
    },
    bgWhite: {
        bgcolor: 'white',
        color: 'black'
    }
}


export default function DenseAppBar(props) {
    const [age, setAge] = React.useState('');


    const handleChange = (event) => {
        setAge(event.target.value);
    };
    return (
        <>
            <HideOnScroll {...props}>
                <AppBar
                    sx={{
                        width: { sm: `calc(100% - ${drawerWidth}px)` },
                        ml: { sm: `${drawerWidth}px` },
                        mt: { xs: '53px', sm: '64px' },
                        zIndex: 1099
                    }}
                    elevation={1}
                >
                    <Toolbar sx={[styles.h, styles.bgWhite]}>
                        <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Grid item>
                                <Typography variant="h6" noWrap component="div" sx={{ m: 1, fontWeight: 'bold' }}>
                                    Referral List
                                </Typography>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
            </HideOnScroll>

            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    '& > :not(style)': {
                        m: 1.5,
                        mt: 8,
                        width: "100%",
                    },
                }}
            >


                <Card variant="outlined" sx={{ padding: 2 }}>
                    <CardContent>
                        <Grid container spacing={1}>
                            <Grid item xs={12} md={3}>
                                <Typography sx={{ fontWeight: 'bold' }}>From Date <Box component='span' sx={{ color: 'red' }}>*</Box></Typography>
                                <Grid item xs={12} md={11}>
                                    <FormControl fullWidth>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoItem >
                                                <DatePicker />
                                            </DemoItem>
                                        </LocalizationProvider>
                                    </FormControl>
                                </Grid>
                            </Grid>


                            <Grid item xs={12} md={3}>
                                <Typography sx={{ fontWeight: 'bold' }}>To Date <Box component='span' sx={{ color: 'red' }}>*</Box></Typography>
                                <Grid item xs={12} md={11}>
                                    <FormControl fullWidth>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoItem >
                                                <DatePicker />
                                            </DemoItem>
                                        </LocalizationProvider>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} md={3}>
                                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>View Mode</Typography>
                                <Grid item xs={12} md={11}>
                                    <FormControl fullWidth>
                                        <Select
                                            value={age}
                                            onChange={handleChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem sx={{ ml: 3 }} value="">
                                                <>PDF</>
                                            </MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={1}>Councelling</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={2}>Diabetes Prevention Awareness</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={3}>First Aid Training</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={4}>OBESITY MANAGMENT</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={3}></Grid>

                            <Grid item xs={12} md={3}>
                                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Clinic</Typography>
                                <Grid item xs={12} md={11}>
                                    <FormControl fullWidth>
                                        <Select
                                            value={age}
                                            onChange={handleChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem sx={{ ml: 3 }} value="">
                                                <>-ALL-</>
                                            </MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={1}>Shalby Hospital</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={2}>Sterling</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} md={3}>
                                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Type</Typography>
                                <Grid item xs={12} md={11}>
                                    <FormControl fullWidth>
                                        <Select
                                            value={age}
                                            onChange={handleChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem sx={{ ml: 3 }} value="">
                                                <>-ALL-</>
                                            </MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={1}>Councelling</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={2}>Diabetes Prevention Awareness</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={3}>First Aid Training</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={4}>OBESITY MANAGMENT</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>

                        </Grid>
                    </CardContent>

                    <Stack spacing={1} direction="row" sx={{ justifyContent: 'center', mb: 2, mt: 2 }}>

                        <Button variant="contained" sx={{ textTransform: 'none', backgroundColor: '#0e559d' }} startIcon={<VisibilityOutlinedIcon />}>
                            View Report
                        </Button>
                        <Button variant="outlined" sx={{ textTransform: 'none' }} startIcon={<HighlightOffIcon />}>
                            Clear
                        </Button>

                    </Stack>

                </Card>
            </Box>
        </>
    )
}