import sharedImports from '../../../Comman/sharedImports';
const {
    React,
    PropTypes,
    Box,
    Grid,
    Slide,
    AppBar,
    FormControlLabel,
    FileCopyOutlinedIcon,
    Checkbox,
    Button,
    Toolbar,
    Typography,
    Select,
    MenuItem,
    TextField,
    CssBaseline,
    FormControl,
    useScrollTrigger,
    CancelOutlinedIcon,
    SearchOutlinedIcon,
} = sharedImports;


const drawerWidth = 300;
function HideOnScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
};
const styles = {
    TableRow: {
        th: {
            svg: {
                float: 'right',
                color: '#1976d2'
            },
        },
        td: {
            border: '1px solid lightgray',
        }
    },
    h: {
        minHeight: '40px !important'
    },
    bgWhite: {
        bgcolor: 'white',
        color: 'black'
    }
}

export default function TestDue(props) {

    const [age, setAge] = React.useState('');
    function handleChange(event, newValue) {
        setAge(event.target.value);
    }

    return (
        <>
            <React.Fragment>
                <Box>
                    <CssBaseline />
                    <HideOnScroll {...props}>
                        <AppBar
                            sx={{
                                width: { sm: `calc(100% - ${drawerWidth}px)` },
                                ml: { sm: `${drawerWidth}px` },
                                mt: { xs: '53px', sm: '64px' },
                                zIndex: 1099
                            }}
                            elevation={1}
                        >
                            <Toolbar sx={[styles.h, styles.bgWhite]}>
                                <Grid container>
                                    <Grid item xs={12} md={12}>
                                        <Typography variant="h6" sx={{ fontWeight: 'bold' }} noWrap component="div">
                                            Test Due
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Toolbar>
                        </AppBar>
                    </HideOnScroll>
                    <Box sx={{ mt: 7, mb: 2, mx: 2, bgcolor: 'white', p: 3, borderRadius: 1 }}>
                        <Box component="div" xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Age From</Box>
                                    <Grid item xs={12} md={11}>
                                        <TextField
                                            required
                                            id="filled-search"
                                            fullWidth
                                            size='small'
                                            placeholder="Age From"
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Age To</Box>
                                    <Grid item xs={12} md={11}>
                                        <TextField
                                            required
                                            id="filled-search"
                                            fullWidth
                                            size='small'
                                            placeholder="Age To"
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Frequency Months</Box>
                                    <Grid item xs={12} md={11}>
                                        <TextField
                                            required
                                            id="filled-search"
                                            fullWidth
                                            size='small'
                                            placeholder="Frequency Months"
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Employee Code</Box>
                                    <Grid item xs={12} md={11}>
                                        <TextField
                                            required
                                            id="filled-search"
                                            fullWidth
                                            size='small'
                                            placeholder="Employee Code"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={3}>
                                    <FormControlLabel
                                        value="end"
                                        control={<Checkbox />}
                                        label="Deparment Group"
                                        labelPlacement="end"
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <FormControlLabel
                                        value="end"
                                        control={<Checkbox />}
                                        label="Deparment"
                                        labelPlacement="end"
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <FormControlLabel
                                        value="end"
                                        control={<Checkbox />}
                                        label="Target Group"
                                        labelPlacement="end"
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <FormControlLabel
                                        value="end"
                                        control={<Checkbox />}
                                        label="Process Type"
                                        labelPlacement="end"
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={3}>
                                    <FormControlLabel
                                        value="end"
                                        control={<Checkbox />}
                                        label="Gender"
                                        labelPlacement="end"
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <FormControlLabel
                                        value="end"
                                        control={<Checkbox />}
                                        label="Employee Type"
                                        labelPlacement="end"
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <FormControlLabel
                                        value="end"
                                        control={<Checkbox />}
                                        label="Employee Sub Type"
                                        labelPlacement="end"
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <FormControlLabel
                                        value="end"
                                        control={<Checkbox />}
                                        label="Grade"
                                        labelPlacement="end"
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} sx={{ mb: 1 }}>
                                <Grid item xs={12} md={3}>
                                    <FormControlLabel
                                        value="end"
                                        control={<Checkbox />}
                                        label="Designation"
                                        labelPlacement="end"
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <FormControlLabel
                                        value="end"
                                        control={<Checkbox />}
                                        label="Designated Position"
                                        labelPlacement="end"
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <FormControlLabel
                                        value="end"
                                        control={<Checkbox />}
                                        label="Sub Location"
                                        labelPlacement="end"
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <FormControlLabel
                                        value="end"
                                        control={<Checkbox />}
                                        label="Company"
                                        labelPlacement="end"
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} sx={{ mb: 4 }}>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Test List <Box component='span' sx={{ color: 'red' }}>*</Box></Box>
                                    <Grid item xs={12} md={11}>
                                        <FormControl fullWidth>
                                            <Select
                                                value={age}
                                                onChange={handleChange}
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Without label' }}
                                            >
                                                <MenuItem sx={{ ml: 3 }} value="">
                                                    <>-All-</>
                                                </MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={1}>Hazard Group Checkup 2023</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={2}>Annual Health Checkup 2022-23</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={3}>Pre Placement</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={4}>Pre Retirement</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Due Frequency Months</Box>
                                    <Grid item xs={12} md={11}>
                                        <TextField
                                            required
                                            id="filled-search"
                                            fullWidth
                                            size='small'
                                            placeholder="Due Frequency Months"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} direction='row' xm={12} sm={12} md={12} lg={12} xl={12} justifyContent='center'>
                                <Grid item>
                                    <Button variant='contained' sx={{ textTransform: 'none' }} startIcon={<SearchOutlinedIcon />}>Search</Button>
                                </Grid>
                                <Grid item>
                                    <Button variant='outlined' sx={{ textTransform: 'none' }} startIcon={<CancelOutlinedIcon />}>Clear</Button>
                                </Grid>
                                <Grid item>
                                    <Button variant='outlined' sx={{ textTransform: 'none' }} startIcon={<FileCopyOutlinedIcon />}>Export</Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Box>
            </React.Fragment>
        </>
    )
}