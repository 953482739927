import sharedImports from '../../../Comman/sharedImports';
const {
    React,
    PropTypes,
    Box,
    Grid,
    Slide,
    AppBar,
    FormControlLabel,
    DeleteOutlineIcon,
    Button,
    Toolbar,
    TextField,
    Checkbox,
    Typography,
    EditNoteOutlinedIcon,
    CssBaseline,
    useScrollTrigger,
    CancelOutlinedIcon,
    FormatListBulletedOutlinedIcon,
    SaveOutlinedIcon,
} = sharedImports;


const drawerWidth = 300;
function HideOnScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
};
const styles = {
    TableRow: {
        th: {
            padding: '5px',
            svg: {
                float: 'right',
                color: '#1976d2'
            },
        },
        td: {
            padding: '5px',
            border: '1px solid lightgray',
        }
    },
    h: {
        minHeight: '40px !important'
    },
    bgWhite: {
        bgcolor: 'white',
        color: 'black'
    }
}

export default function TagsMasterUpdate(props) {

    return (
        <>
            <React.Fragment>
                <Box>
                    <CssBaseline />
                    <HideOnScroll {...props}>
                        <AppBar
                            sx={{
                                width: { sm: `calc(100% - ${drawerWidth}px)` },
                                ml: { sm: `${drawerWidth}px` },
                                mt: { xs: '53px', sm: '64px' },
                                zIndex: 1099
                            }}
                            elevation={1}
                        >
                            <Toolbar sx={[styles.h, styles.bgWhite]}>
                                <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Grid item>
                                        <Typography variant="h6" noWrap component="div" sx={{ m: 1 }}>
                                            Tags Master
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Button variant='outlined' startIcon={<SaveOutlinedIcon />} sx={{ m: 1 }}> Save </Button>
                                        <Button variant='contained' startIcon={<EditNoteOutlinedIcon />} sx={{ m: 1 }}> Edit </Button>
                                        <Button variant='outlined' startIcon={<DeleteOutlineIcon />} sx={{ m: 1 }}> Delete </Button>
                                        <Button variant='outlined' startIcon={<CancelOutlinedIcon />} sx={{ m: 1 }}>Clear</Button>
                                    </Grid>
                                    <Grid item>
                                        <Button variant='contained' startIcon={<FormatListBulletedOutlinedIcon />} sx={{ m: 1 }}> List </Button>
                                    </Grid>
                                </Grid>
                            </Toolbar>
                        </AppBar>
                    </HideOnScroll>
                    <Box sx={{ mt: 5, mb: 2, mx: 2, bgcolor: 'white', p: 3, borderRadius: 1 }}>
                        <Box component="div" xs={12}>
                            <Grid container spacing={2} sx={{ mb: 2 }}>
                                <Grid item xs={12} md={3}>
                                    <Box>Code <Box component='span' sx={{ color: 'red' }}>*</Box></Box>
                                    <TextField
                                        id="filled-search"
                                        type="search"
                                        placeholder='Code'
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} sx={{ mb: 2 }}>
                                <Grid item xs={12} md={3}>
                                    <Box>Title <Box component='span' sx={{ color: 'red' }}>*</Box></Box>
                                    <TextField
                                        id="filled-search"
                                        type="search"
                                        placeholder='Title'
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} sx={{ mb: 2 }}>
                                <Grid item xs={12} md={3}>
                                    <FormControlLabel
                                        value="end"
                                        control={<Checkbox />}
                                        label="InActive"
                                        labelPlacement="end"
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Box>
            </React.Fragment>
        </>
    )
}