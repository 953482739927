import sharedImports from '../../../Comman/sharedImports';
const {
    React,
    Box,
    Alert,
    Paper,
    Grid,
    Table,
    Select,
    Stack,
    Slide,
    Button,
    Divider,
    Checkbox,
    styled,
    AppBar,
    Toolbar,
    TableRow,
    PropTypes,
    TableBody,
    MenuItem,
    TextField,
    FormControl,
    TableHead,
    SearchIcon,
    TableCell,
    Typography,
    useScrollTrigger,
    SaveOutlinedIcon,
    TableContainer,
    tableCellClasses,
    HighlightOffIcon,
    FormControlLabel,
    FileUploadOutlinedIcon,
    UnfoldMoreOutlinedIcon,
} = sharedImports;

const drawerWidth = 300;
function HideOnScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
};

const VisuallyHiddenInput = styled('input')({});
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));
const rows = [
    { temporaryCode: 65325421, employeeCode: null, name: 'palak', department: 'Finance', status: 'Not Started', fitness: 'Pending', reason: null },
    { temporaryCode: 65325421, employeeCode: null, name: 'palak', department: 'Finance', status: 'Not Started', fitness: 'Pending', reason: null },
    { temporaryCode: 879879879, employeeCode: null, name: 'palak', department: 'Finance', status: 'Not Started', fitness: 'Pending', reason: null },
    { temporaryCode: 879879879, employeeCode: null, name: 'palak', department: 'Finance', status: 'Not Started', fitness: 'Pending', reason: null },
    { temporaryCode: 986544, employeeCode: null, name: 'palak', department: 'Finance', status: 'Not Started', fitness: 'Pending', reason: null },
    { temporaryCode: 986544, employeeCode: null, name: 'palak', department: 'Finance', status: 'Not Started', fitness: 'Pending', reason: null },
    { temporaryCode: 986544, employeeCode: null, name: 'palak', department: 'Finance', status: 'Not Started', fitness: 'Pending', reason: null },
];

export default function EmployeeDataUpdate(props) {

    const [state, setState] = React.useState({
        Category: '',
        SubCategory: '',
        Company: '',
        DepartmentGroup: '',
        Department: '',
        Type: '',
        Status: '',
        Location: ''
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setState(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const styles = {
        firstForm: {
            padding: '16px 16px 0px 16px',
            backgroundColor: '#f1f1f1'
        },
        bgWhite: {
            backgroundColor: 'white',
            color: 'black'
        },
        wFull: {
            width: '100%'
        },
        marginBottom2: {
            marginBottom: 2,
        },
        TableRow: {
            th: {
                width: '12.5%',
                svg: {
                    float: 'right',
                    color: '#1976d2'
                },
            },
            td: {
                border: '1px solid lightgray',
            }
        },
        h: {
            minHeight: '40px !important'
        },
        p20: {
            padding: '20px'
        },
        bRadius: {
            borderRadius: '5px',
            marginTop: '45px'
        },
        color: {
            color: 'red',
            fontWeight: 'bold'
        }
    }

    return (
        <Box sx={styles.root}>
            <HideOnScroll {...props}>
                <AppBar
                    sx={{
                        width: { sm: `calc(100% - ${drawerWidth}px)` },
                        ml: { sm: `${drawerWidth}px` },
                        mt: { xs: '53px', sm: '64px' },
                        zIndex: 1099
                    }}
                    elevation={1}
                >
                    <Toolbar sx={[styles.h, styles.bgWhite]}>
                        <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Grid item xs={6}>
                                <Typography variant="h6" noWrap component="div" sx={{ m: 1, fontWeight: 'bold' }}>
                                    Employee Data Update
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Button variant="contained" sx={{ textTransform: 'none', m: 1 }} startIcon={<SaveOutlinedIcon />}>Save</Button>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
            </HideOnScroll>
            <Box sx={styles.firstForm}>
                <Box sx={[styles.bgWhite, styles.wFull, styles.p20, styles.bRadius]} className='MainPadding'>
                    <Grid container spacing={2} sx={styles.pAlart}>
                        <Grid item xs={12}>
                            <Alert variant="soft" sx={styles.color}>
                                Import From Excel Then File Name Must be like this EmployeeDataUpdate_Import_Template.xls <br />Notes : Sheet Name Must be Sheet1. <br /> Notes : After Import Please Click on save button then Save Data.
                            </Alert>
                        </Grid>
                        <Grid item xs={12} sx={{ mt: 3, mb: 3 }}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Box sx={{ fontWeight: 'bold' }}>Category <Box component='span' sx={{ color: 'red' }}>*</Box></Box>
                            <FormControl fullWidth>
                                <Select
                                    name="Category"
                                    value={state.Category}
                                    onChange={handleChange}
                                    displayEmpty
                                    inputProps={{ 'aria-label': 'Without label' }}
                                >
                                    <MenuItem sx={{ ml: 3 }} value="">
                                        <>-Select-</>
                                    </MenuItem>
                                    <MenuItem sx={{ ml: 3 }} value={10}>Ten</MenuItem>
                                    <MenuItem sx={{ ml: 3 }} value={20}>Twenty</MenuItem>
                                    <MenuItem sx={{ ml: 3 }} value={30}>Thirty</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <Box sx={{ fontWeight: 'bold' }}>Sub Category <Box component='span' sx={{ color: 'red' }}>*</Box></Box>
                            <FormControl fullWidth>
                                <Select
                                    name="SubCategory"
                                    value={state.SubCategory}
                                    onChange={handleChange}
                                    displayEmpty
                                    inputProps={{ 'aria-label': 'Without label' }}
                                >
                                    <MenuItem sx={{ ml: 3 }} value="">
                                        <>-Select-</>
                                    </MenuItem>
                                    <MenuItem sx={{ ml: 3 }} value={10}>Ten</MenuItem>
                                    <MenuItem sx={{ ml: 3 }} value={20}>Twenty</MenuItem>
                                    <MenuItem sx={{ ml: 3 }} value={30}>Thirty</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>


                        <Grid item xs={12} md={3}>
                            <Box sx={{ fontWeight: 'bold' }}>Company</Box>
                            <FormControl fullWidth>
                                <Select
                                    name="Company"
                                    value={state.Company}
                                    onChange={handleChange}
                                    displayEmpty
                                    inputProps={{ 'aria-label': 'Without label' }}
                                >
                                    <MenuItem sx={{ ml: 3 }} value="">
                                        <>-Select-</>
                                    </MenuItem>
                                    <MenuItem sx={{ ml: 3 }} value={10}>Ten</MenuItem>
                                    <MenuItem sx={{ ml: 3 }} value={20}>Twenty</MenuItem>
                                    <MenuItem sx={{ ml: 3 }} value={30}>Thirty</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <Box sx={{ fontWeight: 'bold' }}>Department Group</Box>
                            <FormControl fullWidth>
                                <Select
                                    name="DepartmentGroup"
                                    value={state.DepartmentGroup}
                                    onChange={handleChange}
                                    displayEmpty
                                    inputProps={{ 'aria-label': 'Without label' }}
                                >
                                    <MenuItem sx={{ ml: 3 }} value="">
                                        <>-Select-</>
                                    </MenuItem>
                                    <MenuItem sx={{ ml: 3 }} value={10}>Ten</MenuItem>
                                    <MenuItem sx={{ ml: 3 }} value={20}>Twenty</MenuItem>
                                    <MenuItem sx={{ ml: 3 }} value={30}>Thirty</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <Box sx={{ fontWeight: 'bold' }}>Department</Box>
                            <FormControl fullWidth>
                                <Select
                                    name="Department"
                                    value={state.Department}
                                    onChange={handleChange}
                                    displayEmpty
                                    inputProps={{ 'aria-label': 'Without label' }}
                                >
                                    <MenuItem sx={{ ml: 3 }} value="">
                                        <>-Select-</>
                                    </MenuItem>
                                    <MenuItem sx={{ ml: 3 }} value={10}>Ten</MenuItem>
                                    <MenuItem sx={{ ml: 3 }} value={20}>Twenty</MenuItem>
                                    <MenuItem sx={{ ml: 3 }} value={30}>Thirty</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <Box sx={{ fontWeight: 'bold' }}>Type</Box>
                            <FormControl fullWidth>
                                <Select
                                    name="Type"
                                    value={state.Type}
                                    onChange={handleChange}
                                    displayEmpty
                                    inputProps={{ 'aria-label': 'Without label' }}
                                >
                                    <MenuItem sx={{ ml: 3 }} value="">
                                        <>-Select-</>
                                    </MenuItem>
                                    <MenuItem sx={{ ml: 3 }} value={10}>Ten</MenuItem>
                                    <MenuItem sx={{ ml: 3 }} value={20}>Twenty</MenuItem>
                                    <MenuItem sx={{ ml: 3 }} value={30}>Thirty</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <Box sx={{ fontWeight: 'bold' }}>Status <Box component='span' sx={{ color: 'red' }}>*</Box></Box>
                            <FormControl fullWidth>
                                <Select
                                    name="Status"
                                    value={state.Status}
                                    onChange={handleChange}
                                    displayEmpty
                                    inputProps={{ 'aria-label': 'Without label' }}
                                >
                                    <MenuItem sx={{ ml: 3 }} value="">
                                        <>-Select-</>
                                    </MenuItem>
                                    <MenuItem sx={{ ml: 3 }} value={10}>Ten</MenuItem>
                                    <MenuItem sx={{ ml: 3 }} value={20}>Twenty</MenuItem>
                                    <MenuItem sx={{ ml: 3 }} value={30}>Thirty</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <FormControlLabel
                                value="end"
                                control={<Checkbox />}
                                label="Blank Employee Data"
                                labelPlacement="end"
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Box sx={{ fontWeight: 'bold' }}>Location <Box component='span' sx={{ color: 'red' }}>*</Box></Box>
                            <FormControl fullWidth>
                                <Select
                                    name="Location"
                                    value={state.Location}
                                    onChange={handleChange}
                                    displayEmpty
                                    inputProps={{ 'aria-label': 'Without label' }}
                                >
                                    <MenuItem sx={{ ml: 3 }} value="">
                                        <>-Select-</>
                                    </MenuItem>
                                    <MenuItem sx={{ ml: 3 }} value={10}>Ten</MenuItem>
                                    <MenuItem sx={{ ml: 3 }} value={20}>Twenty</MenuItem>
                                    <MenuItem sx={{ ml: 3 }} value={30}>Thirty</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <Box sx={{ fontWeight: 'bold' }}>Code</Box>
                            <TextField
                                required
                                id="filled-search"
                                fullWidth
                                size='small'
                                placeholder="Code Like"
                            />
                        </Grid>

                        <Grid item xs={12} md={3}></Grid>
                        <Grid item xs={12} md={3}></Grid>
                        <Grid item xs={12} md={3}>
                            <VisuallyHiddenInput type="file" />
                        </Grid>
                        <Grid item xs={12}>
                            <Stack spacing={2} direction="row" justifyContent='center' sx={[styles.marginBottom2, { bgcolor: '#ebebeb', padding: '10px' }]}>
                                <Button variant="contained" sx={{ textTransform: 'none' }} startIcon={<SearchIcon />}>Search</Button>
                                <Button variant="outlined" sx={{ textTransform: 'none' }} startIcon={<HighlightOffIcon />}>Clear</Button>
                                <Button variant="outlined" sx={{ textTransform: 'none' }} startIcon={<SaveOutlinedIcon />}>Export</Button>
                                <Button variant="contained" sx={{ textTransform: 'none' }} startIcon={<FileUploadOutlinedIcon />}>Import From Excel</Button>
                            </Stack>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <Box sx={styles.firstForm}>
                <Box sx={[styles.bgWhite, styles.wFull, styles.p20, styles.bRadius]} className='MainPadding'>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={8}></Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                required
                                id="filled-search"
                                fullWidth
                                size='small'
                                placeholder="Code Like"
                            />
                        </Grid>
                        <Grid item xs={12} md={1}>
                            <Button variant="outlined" startIcon={<SaveOutlinedIcon />} sx={{ float: 'right', textTransform: 'none' }}>Apply All</Button>

                        </Grid>
                        <Grid item sx={{ minWidth: { xs: 1, sm: 1, xl: 1 }, maxWidth: { md: 1, lg: 1 } }}>
                            <TableContainer component={Paper}>
                                <Table aria-label="customized table">
                                    <TableHead>
                                        <TableRow sx={styles.TableRow}>
                                            <StyledTableCell>Code <UnfoldMoreOutlinedIcon /></StyledTableCell>
                                            <StyledTableCell>Name <UnfoldMoreOutlinedIcon /></StyledTableCell>
                                            <StyledTableCell>Employee Department <UnfoldMoreOutlinedIcon /></StyledTableCell>
                                            <StyledTableCell>Employee Data <UnfoldMoreOutlinedIcon /></StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {rows.map((row) => (
                                            <StyledTableRow key={row.temporaryCode} sx={styles.TableRow}>
                                                <StyledTableCell> {row.temporaryCode} </StyledTableCell>
                                                <StyledTableCell>{row.employeeCode}</StyledTableCell>
                                                <StyledTableCell>{row.name}</StyledTableCell>
                                                <StyledTableCell>{row.department}</StyledTableCell>
                                            </StyledTableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Box>
    )
}