import sharedImports from '../../../Comman/sharedImports';
const {
    React,
    PropTypes,
    Box,
    Link,
    Grid,
    Slide,
    ArrowBackIcon,
    AppBar,
    Select,
    MenuItem,
    Button,
    AddCircleOutlineOutlinedIcon,
    Toolbar,
    SearchOutlinedIcon,
    Typography,
    FormControl,
    LocalizationProvider,
    AdapterDayjs,
    DemoItem,
    DatePicker,
    TextField,
    FileCopyOutlinedIcon,
    CssBaseline,
    useScrollTrigger,
    styled,
    UnfoldMoreOutlinedIcon,
    TableBody,
    CancelOutlinedIcon,
    TableContainer,
    TableCell,
    Paper,
    tableCellClasses,
    Table,
    TableHead,
    TableRow,
} = sharedImports;


const drawerWidth = 300;
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));
const reportListRows = [
    { orderIndex: 1, checkingParameterItemName: 'BP Instrument- First-aid room', checkingParameterName: 'Cleanliness of Kitchen' },
    { orderIndex: 2, checkingParameterItemName: 'ECG', checkingParameterName: 'Cleanliness of Kitchen' },
    { orderIndex: 3, checkingParameterItemName: 'Oxygen concentrator', checkingParameterName: 'Cleanliness of Kitchen' },
    { orderIndex: 4, checkingParameterItemName: 'Concentrator', checkingParameterName: 'Cleanliness of Kitchen' },
    { orderIndex: 5, checkingParameterItemName: 'Infusion pump', checkingParameterName: 'Cleanliness of Kitchen' },
    { orderIndex: 6, checkingParameterItemName: 'Thermal Gun', checkingParameterName: 'Cleanliness of Kitchen' },
    { orderIndex: 7, checkingParameterItemName: 'Glucometer', checkingParameterName: 'Cleanliness of Kitchen' },
    { orderIndex: 8, checkingParameterItemName: 'Two para oximeter', checkingParameterName: 'Cleanliness of Kitchen' },
];
function HideOnScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
};
const styles = {
    TableRow: {
        th: {
            border: '1px solid #a3a3a3',
            padding: '5px',
            svg: {
                float: 'right',
                color: '#1976d2'
            },
        },
        td: {
            padding: '5px',
            border: '1px solid lightgray',
        }
    },
    h: {
        minHeight: '40px !important'
    },
    bgWhite: {
        bgcolor: 'white',
        color: 'black'
    },
    textTransform: {
        textTransform: 'none',
    },
    hover: {
        textTransform: 'none',
        "&:hover": {
            backgroundColor: '#89f36e'
        },
    }
}

export default function CheckingParameterItemMaster(props) {

    const [age, setAge] = React.useState('');
    function handleChange(event) {
        setAge(event.target.value);
    }

    return (
        <>
            <React.Fragment>
                <Box>
                    <CssBaseline />
                    <HideOnScroll {...props}>
                        <AppBar
                            sx={{
                                width: { sm: `calc(100% - ${drawerWidth}px)` },
                                ml: { sm: `${drawerWidth}px` },
                                mt: { xs: '53px', sm: '64px' },
                                zIndex: 1099
                            }}
                            elevation={1}
                        >
                            <Toolbar sx={[styles.h, styles.bgWhite]}>
                                <Grid container>
                                    <Grid item xs={12} md={10}>
                                        <Typography variant="h6" noWrap component="div">
                                            Checking Parameter Item Master
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                        <Link to='/dashboard/support/canteen/checking-parameter-item-master/create'>
                                            <Button variant='contained' startIcon={<AddCircleOutlineOutlinedIcon />} sx={[{ mr: 1, bgcolor: 'gray' }, styles.textTransform]}> Add </Button>
                                        </Link>
                                        <Button variant='outlined' startIcon={<ArrowBackIcon />} sx={styles.textTransform}> Back </Button>
                                    </Grid>
                                </Grid>
                            </Toolbar>
                        </AppBar>
                    </HideOnScroll>
                    <Box sx={{ mt: 4, mb: 2, mx: 2, bgcolor: 'white', p: 3, borderRadius: 1 }}>
                        <Box component="div" xs={12}>
                            <Grid container spacing={2} sx={{ mb: 5 }}>
                                <Grid item xs={12} md={3}>
                                    <Box>Name</Box>
                                    <TextField
                                        id="filled-search"
                                        type="search"
                                        size='small'
                                        placeholder='Name Like'
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Box>Checking Parameter</Box>
                                    <FormControl fullWidth>
                                        <Select
                                            value={age}
                                            onChange={handleChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem value="">
                                                --All--
                                            </MenuItem>
                                            <MenuItem value={1}>Councelling</MenuItem>
                                            <MenuItem value={2}>Diabetes Prevention Awareness</MenuItem>
                                            <MenuItem value={3}>First Aid Training</MenuItem>
                                            <MenuItem value={4}>OBESITY MANAGMENT</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Box>From Date</Box>
                                    <FormControl fullWidth>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoItem>
                                                <DatePicker />
                                            </DemoItem>
                                        </LocalizationProvider>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Box>From Date</Box>
                                    <FormControl fullWidth>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoItem>
                                                <DatePicker />
                                            </DemoItem>
                                        </LocalizationProvider>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Button variant='contained' startIcon={<SearchOutlinedIcon />} sx={[{ mr: 0.5 }, styles.textTransform]}>Search</Button>
                                <Button variant='outlined' startIcon={<CancelOutlinedIcon />} sx={[{ ml: 0.5 }, styles.textTransform]}>Clear</Button>
                            </Grid>
                        </Box>
                    </Box>
                    <Box sx={{ mt: 2, mb: 7, mx: 2, bgcolor: 'white', p: 3, borderRadius: 1 }}>
                        <Box component="div" xs={12}>
                            <Grid container spacing={2} direction='row' xm={12} sm={12} md={12} lg={12} xl={12} justifyContent='space-between' sx={{ mb: 2 }}>
                                <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Grid item sx={{ mr: 2, display: 'flex', alignItems: 'center' }}>
                                        <Box component='span'>Show </Box>
                                        <TextField
                                            id="outlined-number"
                                            type="number"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            defaultValue='10'
                                            sx={{ width: '9ch' }}
                                            size='small'
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Box component='span'>entries </Box>
                                        <Button variant='outlined' startIcon={<FileCopyOutlinedIcon />} sx={styles.textTransform}>Export</Button>
                                    </Grid>
                                </Grid>
                                <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Box component='span'>Search: </Box>
                                    <TextField
                                        id="filled-search"
                                        type="search"
                                        size='small'
                                    />
                                </Grid>
                            </Grid>
                            <TableContainer component={Paper} sx={{ mb: 2 }}>
                                <Table aria-label="customized table">
                                    <TableHead>
                                        <TableRow sx={styles.TableRow}>
                                            <StyledTableCell><UnfoldMoreOutlinedIcon /></StyledTableCell>
                                            <StyledTableCell>Checking Parameter Item Name<UnfoldMoreOutlinedIcon /></StyledTableCell>
                                            <StyledTableCell>Checking Parameter Name<UnfoldMoreOutlinedIcon /></StyledTableCell>
                                            <StyledTableCell>Order Index<UnfoldMoreOutlinedIcon /></StyledTableCell>
                                            <StyledTableCell>InActive<UnfoldMoreOutlinedIcon /></StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {reportListRows.map((row) => (
                                            <StyledTableRow key={row.checkingParameterItemName} sx={styles.TableRow}>
                                                <StyledTableCell sx={{ color: '#0178ff' }}>
                                                    <Link to='/support/canteen/checking-parameter-item-master/update'>
                                                        Select
                                                    </Link>
                                                </StyledTableCell>
                                                <StyledTableCell>{row.checkingParameterItemName}</StyledTableCell>
                                                <StyledTableCell>{row.checkingParameterName}</StyledTableCell>
                                                <StyledTableCell>{row.orderIndex}</StyledTableCell>
                                                <StyledTableCell>
                                                    <Button variant='contained' sx={[{ bgcolor: '#e2f9d3', color: '#269f40', boxShadow: 'none' }, styles.hover]}>Active</Button>
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Grid container spacing={2} direction='row' xm={12} sm={12} md={12} lg={12} xl={12} justifyContent='space-between'>
                                <Grid item>
                                    Showing 1 to 8 of 8 entries
                                </Grid>
                                <Grid item>
                                    <Button variant='contained' sx={[{ mr: 2 }, styles.textTransform]} disabled>Previous</Button>
                                    <Button variant='contained' sx={styles.textTransform} disabled>Next</Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Box>
            </React.Fragment>
        </>
    )
}