import sharedImports from '../../../Comman/sharedImports';
const {
    Box,
    Grid,
    React,
    Table,
    Paper,
    Slide,
    Select,
    AppBar,
    styled,
    Button,
    Toolbar,
    DemoItem,
    TableRow,
    MenuItem,
    TableHead,
    PropTypes,
    TableBody,
    TableCell,
    TextField,
    SearchIcon,
    DatePicker,
    Typography,
    FormControl,
    CssBaseline,
    AdapterDayjs,
    TableContainer,
    useScrollTrigger,
    tableCellClasses,
    CancelOutlinedIcon,
    SearchOutlinedIcon,
    FileCopyOutlinedIcon,
    LocalizationProvider,
    UnfoldMoreOutlinedIcon,
} = sharedImports;



const drawerWidth = 300;
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },

    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));
const reportListRows = [
    { department: '-', notStarted: 0, inProgress: 0, approved: 0, completed: 125, parked: 0, close: 0, totalCount: 125 },
    { department: 'Investor Relation', notStarted: 0, inProgress: 0, approved: 0, completed: 1, parked: 0, close: 0, totalCount: 1 },
    { department: 'ABEX', notStarted: 0, inProgress: 0, approved: 0, completed: 155, parked: 0, close: 0, totalCount: 155 },
    { department: 'Admin - ACH', notStarted: 0, inProgress: 0, approved: 0, completed: 1, parked: 0, close: 0, totalCount: 1 },
    { department: 'Administraion', notStarted: 0, inProgress: 0, approved: 0, completed: 2, parked: 0, close: 0, totalCount: 2 },
    { department: 'Administration', notStarted: 0, inProgress: 0, approved: 0, completed: 33, parked: 0, close: 0, totalCount: 33 },
    { department: 'AIR CARGO', notStarted: 0, inProgress: 0, approved: 0, completed: 18, parked: 0, close: 0, totalCount: 18 },
    { department: 'Airports', notStarted: 0, inProgress: 0, approved: 0, completed: 46, parked: 0, close: 0, totalCount: 46 },
    { department: 'Analytics', notStarted: 0, inProgress: 0, approved: 0, completed: 1, parked: 0, close: 0, totalCount: 1 },
    { department: 'Architectural Services', notStarted: 0, inProgress: 0, approved: 0, completed: 4, parked: 0, close: 0, totalCount: 4 },
    { department: 'BUSSINESS DEVELOPMENT', notStarted: 0, inProgress: 0, approved: 0, completed: 54, parked: 0, close: 0, totalCount: 54 },
    { department: 'Bussiness Development & Sales', notStarted: 0, inProgress: 0, approved: 0, completed: 1, parked: 0, close: 0, totalCount: 1 },
    { department: 'Bussiness Development GRP', notStarted: 0, inProgress: 0, approved: 0, completed: 7, parked: 0, close: 0, totalCount: 7 },
    { department: 'Bussiness Development GRP (POWER SALES)', notStarted: 0, inProgress: 0, approved: 0, completed: 9, parked: 0, close: 0, totalCount: 9 },
];
function HideOnScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
};
const styles = {
    TableRow: {
        th: {
            width: '12.5%',
            svg: {
                float: 'right',
                color: '#1976d2'
            },
        },
        td: {
            border: '1px solid lightgray',
        }
    },
    h: {
        minHeight: '40px !important'
    },
    bgWhite: {
        bgcolor: 'white',
        color: 'black'
    }
}

export default function EmployeeAnalysis(props) {
    const [state, setState] = React.useState({
        examinationType: '',
        Round: '',
        DepartmentGroup: '',
        Department: ''
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setState(prevState => ({
            ...prevState,
            [name]: value
        }));
    };
    return (
        <>
            <React.Fragment>
                <Box>
                    <CssBaseline />
                    <HideOnScroll {...props}>
                        <AppBar
                            sx={{
                                width: { sm: `calc(100% - ${drawerWidth}px)` },
                                ml: { sm: `${drawerWidth}px` },
                                mt: { xs: '53px', sm: '64px' },
                                zIndex: 1099
                            }}
                            elevation={1}
                        >
                            <Toolbar sx={[styles.h, styles.bgWhite]}>
                                <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Grid item>
                                        <Typography variant="h6" noWrap component="div" sx={{ m: 1, fontWeight: 'bold' }}>
                                            Employee Analysis
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Toolbar>
                        </AppBar>
                    </HideOnScroll>
                    <Box sx={{ mt: 8, mb: 2, mx: 2, bgcolor: 'white', p: 3, borderRadius: 1 }}>
                        <Box component="div" xs={12}>
                            <Grid container spacing={1} sx={{ mb: 1 }}>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Examination Type <Box component='span' sx={{ color: 'red' }}>*</Box></Box>
                                    <Grid item xs={12} md={11}>
                                        <FormControl fullWidth>
                                            <Select
                                                name="examinationType"
                                                value={state.examinationType}
                                                onChange={handleChange}
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Without label' }}
                                            >
                                                <MenuItem sx={{ ml: 3 }} value="">
                                                    <>-Select-</>
                                                </MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={1}>Periodic</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={2}>Laboratory</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={3}>Medicine</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={4}>SETRID-COLD</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Round <Box component='span' sx={{ color: 'red' }}>*</Box></Box>
                                    <Grid item xs={12} md={11}>
                                        <FormControl fullWidth>
                                            <Select
                                                name="Round"
                                                value={state.Round}
                                                onChange={handleChange}
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Without label' }}
                                            >
                                                <MenuItem sx={{ ml: 3 }} value="">
                                                    <>-Select-</>
                                                </MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={1}>Annual Health Checkup 2021-22</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={2}>Laboratory</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={3}>Medicine</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={4}>SETRID-COLD</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Department Group</Box>
                                    <Grid item xs={12} md={11}>
                                        <FormControl fullWidth>
                                            <Select
                                                name="DepartmentGroup"
                                                value={state.DepartmentGroup}
                                                onChange={handleChange}
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Without label' }}
                                            >
                                                <MenuItem sx={{ ml: 3 }} value="">
                                                    <>-ALL-</>
                                                </MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={1}>General</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={2}>Noise</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={3}>Dust</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={4}>Noise and Dust</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={5}>Canteen</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Department </Box>
                                    <Grid item xs={12} md={11}>
                                        <FormControl fullWidth>
                                            <Select
                                                name="Department"
                                                value={state.Department}
                                                onChange={handleChange}
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Without label' }}
                                            >
                                                <MenuItem sx={{ ml: 3 }} value="">
                                                    <>-ALL-</>
                                                </MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={1}>Periodic</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={2}>Laboratory</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={3}>Medicine</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={4}>SETRID-COLD</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container spacing={1} sx={{ mb: 4 }}>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>From Date</Box>
                                    <Grid item xs={12} md={11}>
                                        <FormControl fullWidth>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DemoItem >
                                                    <DatePicker />
                                                </DemoItem>
                                            </LocalizationProvider>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>To Date</Box>
                                    <Grid item xs={12} md={11}>
                                        <FormControl fullWidth>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DemoItem >
                                                    <DatePicker />
                                                </DemoItem>
                                            </LocalizationProvider>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Typography variant="body1">
                                        <Box sx={{ fontWeight: 'bold' }}>Employee Code</Box>
                                    </Typography>
                                    <Grid container spacing={0}>
                                        <Grid item xs={12} md={11}>
                                            <TextField
                                                required
                                                id="filled-search"
                                                fullWidth
                                                size='small'
                                                placeholder='Employee Code'
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={1} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
                                            <SearchIcon />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Typography variant="body1">
                                        <Box sx={{ fontWeight: 'bold' }}>Employee Name</Box>
                                    </Typography>
                                    <Grid item xs={12} md={11}>
                                        <TextField
                                            required
                                            id="filled-search"
                                            fullWidth
                                            size='small'
                                            placeholder='Employee Name'
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container spacing={1} direction='row' xm={12} sm={12} md={12} lg={12} xl={12} justifyContent='center'>
                                <Grid item>
                                    <Button variant='contained' sx={{ textTransform: 'none' }} startIcon={<SearchOutlinedIcon />}>Search</Button>
                                </Grid>
                                <Grid item>
                                    <Button variant='outlined' sx={{ textTransform: 'none' }} startIcon={<CancelOutlinedIcon />}>Clear</Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                    <Box sx={{ mt: 2, mb: 7, mx: 2, bgcolor: 'white', p: 3, borderRadius: 1 }}>
                        <Box component="div" xs={12}>
                            <Grid container spacing={2} direction='row' xm={12} sm={12} md={12} lg={12} xl={12} justifyContent='space-between' sx={{ mb: 2 }}>
                                <Grid item>
                                    <Button variant='outlined' sx={{ textTransform: 'none' }} startIcon={<FileCopyOutlinedIcon />}>Export</Button>
                                </Grid>
                                <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Box component='span' sx={{ fontWeight: 'bold' }}>Search: </Box>
                                    <TextField
                                        id="filled-search"
                                        type="search"
                                        size='small'
                                    />
                                </Grid>
                            </Grid>
                            <TableContainer component={Paper}>
                                <Table aria-label="customized table">
                                    <TableHead>
                                        <TableRow sx={styles.TableRow}>
                                            <StyledTableCell>Department<UnfoldMoreOutlinedIcon /></StyledTableCell>
                                            <StyledTableCell>Not Started<UnfoldMoreOutlinedIcon /></StyledTableCell>
                                            <StyledTableCell>In Progress<UnfoldMoreOutlinedIcon /></StyledTableCell>
                                            <StyledTableCell>Approved<UnfoldMoreOutlinedIcon /></StyledTableCell>
                                            <StyledTableCell>Completed<UnfoldMoreOutlinedIcon /></StyledTableCell>
                                            <StyledTableCell>Parked<UnfoldMoreOutlinedIcon /></StyledTableCell>
                                            <StyledTableCell>Close<UnfoldMoreOutlinedIcon /></StyledTableCell>
                                            <StyledTableCell>Total Count<UnfoldMoreOutlinedIcon /></StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {reportListRows.map((row) => (
                                            <StyledTableRow key={row.department} sx={styles.TableRow}>
                                                <StyledTableCell> {row.department} </StyledTableCell>
                                                <StyledTableCell>{row.notStarted}</StyledTableCell>
                                                <StyledTableCell>{row.inProgress}</StyledTableCell>
                                                <StyledTableCell>{row.approved}</StyledTableCell>
                                                <StyledTableCell>{row.completed}</StyledTableCell>
                                                <StyledTableCell>{row.parked}</StyledTableCell>
                                                <StyledTableCell>{row.close}</StyledTableCell>
                                                <StyledTableCell>{row.totalCount}</StyledTableCell>
                                            </StyledTableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Box>
                </Box>
            </React.Fragment>
        </>
    )
}