import sharedImports from '../../../Comman/sharedImports';
const {
    React,
    AppBar,
    Box,
    Tab,
    Tabs,
    Grid,
    Slide,
    Card,
    Toolbar,
    PropTypes,
    TextField,
    Typography,
    CardContent,
    useScrollTrigger,
} = sharedImports;

const drawerWidth = 300;
function HideOnScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
};

const styles = {
    TableRow: {
        th: {
            p: 1,
            svg: {
                float: 'right',
                color: '#1976d2'
            },
        },
        td: {
            border: '1px solid lightgray',
            p: 1,
        }
    },
    h: {
        minHeight: '40px !important'
    },
    bgWhite: {
        bgcolor: 'white',
        color: 'black'
    }
}



function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 6 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function DenseAppBar(props) {

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <>
            <HideOnScroll {...props}>
                <AppBar
                    sx={{
                        width: { sm: `calc(100% - ${drawerWidth}px)` },
                        ml: { sm: `${drawerWidth}px` },
                        mt: { xs: '53px', sm: '64px' },
                        zIndex: 1099
                    }}
                    elevation={1}
                >
                    <Toolbar sx={[styles.h, styles.bgWhite]}>
                        <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Grid item>
                                <Typography variant="h6" noWrap component="div" sx={{ m: 1, fontWeight: 'bold' }}>
                                    Health Content
                                </Typography>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
            </HideOnScroll>

            <Card sx={{ m: 2, mt: 8, }}>

                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label="Disease Wise" sx={{ ml: 5, textTransform: 'none', color: '#1976d2', }}{...a11yProps(0)} />
                        <Tab label="Type Wise" sx={{ textTransform: 'none', color: '#1976d2', }} {...a11yProps(1)} />
                    </Tabs>
                </Box>

                <CustomTabPanel value={value} index={0}>
                    <TextField
                        required
                        id="filled-search"
                        size='small'
                        placeholder='Search Here'
                        sx={{ mt: -6, width: 300 }}

                    />
                    <Grid sx={{ mt: -8, mb: 6, ml: 32, }}>
                        <Typography sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', wordSpacing: '25px', fontWeight: 'bold' }}>All A B C D E F G H I J K L M N O P Q R S T U V <br />W X Y Z</Typography>
                    </Grid>

                    <Grid container spacing={4}>
                        <Grid item xs={12} md={3} >
                            <Card sx={{ height: 70 }}>
                                <CardContent>
                                    <Typography sx={{ fontWeight: 600 }} >
                                        Physiotherapy
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Card sx={{ height: 70 }}>
                                <CardContent>
                                    <Typography sx={{ fontWeight: 600 }} >
                                        Yoga and Practices
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Card sx={{ height: 70 }}>
                                <CardContent>
                                    <Typography sx={{ fontWeight: 600 }} >
                                        Health & Nutrition
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Card sx={{ height: 70 }}>
                                <CardContent>
                                    <Typography sx={{ fontWeight: 600 }} >
                                        Lifestyle
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Card sx={{ height: 70 }}>
                                <CardContent>
                                    <Typography sx={{ fontWeight: 600 }} >
                                        Breathing Practices
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Card sx={{ height: 70 }}>
                                <CardContent>
                                    <Typography sx={{ fontWeight: 600 }} >
                                        Guide to a happy gut
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Card sx={{ height: 100 }}>
                                <CardContent>
                                    <Typography sx={{ fontWeight: 600 }} >
                                        5 Foods that will help you<br />
                                        avoid acidity
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Card sx={{ height: 70 }}>
                                <CardContent>
                                    <Typography sx={{ fontWeight: 600 }} >
                                        Food for asthma
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Card sx={{ height: 70 }}>
                                <CardContent>
                                    <Typography sx={{ fontWeight: 600 }} >
                                        Hypertension
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Card sx={{ height: 70 }}>
                                <CardContent>
                                    <Typography sx={{ fontWeight: 600 }} >
                                        Lungs health
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Card sx={{ height: 70 }}>
                                <CardContent>
                                    <Typography sx={{ fontWeight: 600 }} >
                                        Migraine
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>

                </CustomTabPanel>


                <CustomTabPanel value={value} index={1}>
                    <TextField
                        required
                        id="filled-search"
                        size='small'
                        placeholder='Search Here'
                        sx={{ mt: -6, width: 300 }}
                    />

                    <Grid sx={{ mt: -8, mb: 6, ml: 32, }}>
                        <Typography sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', wordSpacing: '25px', fontWeight: 'bold' }}>All A B C D E F G H I J K L M N O P Q R S T U V <br />W X Y Z</Typography>
                    </Grid>

                    <Grid container spacing={4}>
                        <Grid item xs={12} md={3}>
                            <Card sx={{ height: 70 }}>
                                <CardContent>
                                    <Typography sx={{ fontWeight: 600 }} >
                                        Migraine
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Card sx={{ height: 70 }}>
                                <CardContent>
                                    <Typography sx={{ fontWeight: 600 }} >
                                        Lungs health
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Card sx={{ height: 70 }}>
                                <CardContent>
                                    <Typography sx={{ fontWeight: 600 }} >
                                        Hypertension
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Card sx={{ height: 100 }}>
                                <CardContent>
                                    <Typography sx={{ fontWeight: 600 }} >
                                        Foods to avoid if you have <br />
                                        asthma.
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Card sx={{ height: 100 }}>
                                <CardContent>
                                    <Typography sx={{ fontWeight: 600 }} >
                                        5 foods that will help you<br />
                                        avoid acidity
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Card sx={{ height: 70 }}>
                                <CardContent>
                                    <Typography sx={{ fontWeight: 600 }} >
                                        Guide to a happy gut
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Card sx={{ height: 100 }}>
                                <CardContent>
                                    <Typography sx={{ fontWeight: 600 }} >
                                        7 Healthy lunch tips for<br />
                                        healthier you
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Card sx={{ height: 70 }}>
                                <CardContent>
                                    <Typography sx={{ fontWeight: 600 }} >
                                        Yogic Practices
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Card sx={{ height: 100 }}>
                                <CardContent>
                                    <Typography sx={{ fontWeight: 600 }} >
                                        All about wheat grass & it's<br /> benefits.
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Card sx={{ height: 70 }}>
                                <CardContent>
                                    <Typography sx={{ fontWeight: 600 }} >
                                        Health & Nutrition
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Card sx={{ height: 70 }}>
                                <CardContent>
                                    <Typography sx={{ fontWeight: 600 }} >
                                        Relaxation Techniques
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Card sx={{ height: 70 }}>
                                <CardContent>
                                    <Typography sx={{ fontWeight: 600 }} >
                                        Physiotherapy
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>

                </CustomTabPanel>

            </Card>


        </>
    );
}