import * as React from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import SideBarMenu from './sideBarMenu';
import { Link, Outlet } from "react-router-dom";
import Drawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';
import Divider from '@mui/material/Divider';
import Toolbar from '@mui/material/Toolbar';
import MenuIcon from '@mui/icons-material/Menu';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CssBaseline from '@mui/material/CssBaseline';
import { Button } from '@mui/material';

const drawerWidth = 300;

export default function ResponsiveDrawer(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [isClosing, setIsClosing] = React.useState(false);

  const currentDate = new Date();
  let currentYear = currentDate.getFullYear();

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  const drawer = (
    <div>
      <Toolbar sx={{fontWeight: 'bold',justifyContent: 'center', fontSize: "25px"}}>Health Care</Toolbar>
      <Divider />
      <List>
        <SideBarMenu />
      </List>
    </div>
  );

  const styles = {
    toolbar: {
      minHeight: '42px !important',
    },
    boxPadding: {
      padding: '24px 0px !important',
    },
    dFlex: {
      display: 'flex',
    },
    wFull: {
      width: '100%',
    }
  }

  // Remove this const when copying and pasting into your project.
  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={[styles.dFlex, styles.wFull]}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>

          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ ml: 'auto', width: 'fit-content', backgroundColor: 'white', textTransform: 'none' }}
          >
            <Link to='/'>
              Log out
            </Link>
          </Button>

        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onTransitionEnd={handleDrawerTransitionEnd}
          onClose={handleDrawerClose}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={[{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` }, bgcolor: '#f1f1f1' }, styles.boxPadding, styles.wFull]}
      >
        <Toolbar sx={styles.toolbar} />
        <Typography paragraph>
          <Outlet />
        </Typography>
      </Box>
      <AppBar
        position="fixed"
        sx={{
          top: 'auto', bottom: 0,
          bgcolor: '#ffffff',
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` }
        }}
      >
        <Toolbar sx={{ justifyContent: 'center', color: '#1976d2' }}>
          <Typography
            sx={{fontSize: '15px'}}
            variant="h6"
            noWrap
            component="div">
            Copyright © {currentYear} Health Care, ALL right reserved
          </Typography>
        </Toolbar>
      </AppBar>
    </Box>
  );
}