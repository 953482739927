import sharedImports from '../../../Comman/sharedImports';
const {
    React,
    PropTypes,
    Box,
    Grid,
    Slide,
    AppBar,
    FormControl,
    Select,
    FileCopyOutlinedIcon,
    TableContainer,
    Paper,
    Table,
    SearchOutlinedIcon,
    styled,
    FormControlLabel,
    Checkbox,
    TableCell,
    Tabs,
    Tab,
    tableCellClasses,
    TableHead,
    TableBody,
    MenuItem,
    LocalizationProvider,
    AdapterDayjs,
    DemoItem,
    TableRow,
    DatePicker,
    Button,
    Toolbar,
    Typography,
    CssBaseline,
    useScrollTrigger,
    CancelOutlinedIcon,
} = sharedImports;


const drawerWidth = 300;
function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));
const reportListRows = [
    { subLocation: 'Ahmedabad', tobacco: 50, smoker: 170, alcohol: 173, underWeight: 101, overWeight: 1243, obese: 459, tb: 1, copd: 0, preHypertension: 322, hypertension: 92, preDiab: 989 },
    { subLocation: 'Total', tobacco: 50, smoker: 170, alcohol: 173, underWeight: 101, overWeight: 1243, obese: 459, tb: 1, copd: 0, preHypertension: 322, hypertension: 92, preDiab: 989 },
];
function HideOnScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
};
const styles = {
    TableRow: {
        th: {
            svg: {
                float: 'right',
                color: '#1976d2'
            },
        },
        td: {
            border: '1px solid lightgray',
        }
    },
    h: {
        minHeight: '40px !important'
    },
    bgWhite: {
        bgcolor: 'white',
        color: 'black'
    }
}

export default function MISOPDDashboard(props) {

    const [age, setAge] = React.useState('');
    const [value, setValue] = React.useState(0);
    function handleChange(event, newValue) {
        setAge(event.target.value);
        setValue(newValue);
    }

    return (
        <>
            <React.Fragment>
                <Box>
                    <CssBaseline />
                    <HideOnScroll {...props}>
                        <AppBar
                            sx={{
                                width: { sm: `calc(100% - ${drawerWidth}px)` },
                                ml: { sm: `${drawerWidth}px` },
                                mt: { xs: '53px', sm: '64px' },
                                zIndex: 1099
                            }}
                            elevation={1}
                        >
                            <Toolbar sx={[styles.h, styles.bgWhite]}>
                                <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Grid item>
                                        <Typography variant="h6" noWrap component="div" sx={{ m: 1, fontWeight: 'bold' }}>
                                            MIS OPD Dashboard
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Toolbar>
                        </AppBar>
                    </HideOnScroll>
                    <Box sx={{ mt: 5, mb: 2, mx: 2, bgcolor: 'white', p: 3, borderRadius: 1 }}>
                        <Box component="div" xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Sub Location</Box>
                                    <Grid item xs={12} md={11}>
                                        <FormControl fullWidth>
                                            <Select
                                                value={age}
                                                onChange={handleChange}
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Without label' }}
                                            >
                                                <MenuItem sx={{ ml: 3 }} value="">
                                                    <>-All-</>
                                                </MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={1}>Councelling</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={2}>Diabetes Prevention Awareness</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={3}>First Aid Training</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={4}>OBESITY MANAGMENT</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>From Date</Box>
                                    <Grid item xs={12} md={11}>
                                        <FormControl fullWidth>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DemoItem >
                                                    <DatePicker />
                                                </DemoItem>
                                            </LocalizationProvider>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>To Date</Box>
                                    <Grid item xs={12} md={11}>
                                        <FormControl fullWidth>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DemoItem >
                                                    <DatePicker />
                                                </DemoItem>
                                            </LocalizationProvider>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} sx={{ mb: 2 }}>
                                <Grid item xs={12} md={3}>
                                    <FormControlLabel
                                        value="end"
                                        control={<Checkbox />}
                                        label="Other Filter"
                                        labelPlacement="end"
                                        sx={{ mt: 2 }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} sx={{ mb: 2 }}>
                                <Grid item xs={12} md={12} align='center'>
                                    <Button variant='contained' startIcon={<SearchOutlinedIcon />} sx={{ m: 1, textTransform: 'none' }}>Search</Button>
                                    <Button variant='outlined' startIcon={<CancelOutlinedIcon />} sx={{ m: 1, textTransform: 'none' }}>Clear</Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Box>
                <Box sx={{ mt: 2, mb: 2, mx: 2, bgcolor: 'white', p: 3, borderRadius: 1 }}>
                    <Box component="div" xs={12}>
                        <Grid container spacing={4}>
                            <Grid item xs={12} md={3}>
                                <Box component='div' sx={{ bgcolor: '#5f72b9', p: 3, borderRadius: 2, color: '#ffffff' }}>
                                    <h1>0</h1>
                                    <p>Cost</p>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <Box component='div' sx={{ bgcolor: 'green', p: 3, borderRadius: 2, color: '#ffffff' }}>
                                    <h1>0</h1>
                                    <p>OPD Count</p>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <Box component='div' sx={{ bgcolor: '#ff8100', p: 3, borderRadius: 2, color: '#ffffff' }}>
                                    <h1>0</h1>
                                    <p>Employee Type</p>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <Box component='div' sx={{ bgcolor: 'red', p: 3, borderRadius: 2, color: '#ffffff' }}>
                                    <h1>0</h1>
                                    <p>Visit Purpose</p>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
                <Box sx={{ mt: 2, mb: 7, mx: 2, bgcolor: 'white', p: 3, borderRadius: 1 }}>
                    <Box component="div" xs={12}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                <Tab sx={{textTransform: 'none', color: '#1976d2',}} label="Expense" {...a11yProps(0)} />
                                <Tab sx={{textTransform: 'none', color: '#1976d2',}} label="OPD Count" {...a11yProps(1)} />
                                <Tab sx={{textTransform: 'none', color: '#1976d2',}} label="Employee Type" {...a11yProps(2)} />
                                <Tab sx={{textTransform: 'none', color: '#1976d2',}} label="Visit Purpose" {...a11yProps(3)} />
                                <Tab sx={{textTransform: 'none', color: '#1976d2',}} label="Visit Purpose Wise Employee Type" {...a11yProps(4)} />
                                <Tab sx={{textTransform: 'none', color: '#1976d2',}} label="Health Connect" {...a11yProps(5)} />
                                <Tab sx={{textTransform: 'none', color: '#1976d2',}} label="Canteen" {...a11yProps(6)} />
                                <Tab sx={{textTransform: 'none', color: '#1976d2',}} label="Pantry" {...a11yProps(7)} />
                                <Tab sx={{textTransform: 'none', color: '#1976d2',}} label="Disease System Analysis" {...a11yProps(8)} />
                                <Tab sx={{textTransform: 'none', color: '#1976d2',}} label="Disease Analysis" {...a11yProps(9)} />
                            </Tabs>
                        </Box>
                        <CustomTabPanel value={value} index={0}>
                            <Box component='h2' align='center' sx={{ m: 0, p: 0 }}>Count Detail</Box>
                            <Box component="div" xs={12}>
                                <Grid container spacing={2} direction='row' xm={12} sm={12} md={12} lg={12} xl={12} justifyContent='end' sx={{ mb: 2 }}>
                                    <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                                        <Grid item>
                                            <Button variant='outlined' sx={{textTransform: 'none'}} startIcon={<FileCopyOutlinedIcon />}>Export</Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <TableContainer component={Paper} sx={{ mb: 2 }}>
                                    <Table aria-label="customized table">
                                        <TableHead>
                                            <TableRow sx={styles.TableRow}>
                                                <StyledTableCell>Sub Location</StyledTableCell>
                                                <StyledTableCell>Freezed Employee</StyledTableCell>
                                                <StyledTableCell>Examined Employee</StyledTableCell>
                                                <StyledTableCell>Pending Based on Freeze</StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {reportListRows.map((row) => (
                                                <StyledTableRow key={row.subLocation} sx={styles.TableRow}>
                                                    <StyledTableCell>{row.subLocation}</StyledTableCell>
                                                    <StyledTableCell>{row.freezedEmployee}</StyledTableCell>
                                                    <StyledTableCell>{row.examinedEmployee}</StyledTableCell>
                                                    <StyledTableCell>{row.pendingBasedonFreeze}</StyledTableCell>
                                                </StyledTableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <Grid container spacing={2} direction='row' xm={12} sm={12} md={12} lg={12} xl={12} justifyContent='space-between'>
                                    <Grid item sx={{fontWeight: 'bold'}}>
                                        Showing 1 to 10 of 10 entries
                                    </Grid>
                                    <Grid item>
                                        <Button variant='contained' sx={{ mr: 2, textTransform: 'none' }} disabled>Previous</Button>
                                        <Button variant='contained' sx={{textTransform: 'none'}} disabled>Next</Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={1}>
                            <Box component='h2' align='center' sx={{ m: 0, p: 0 }}>Target Group Detail</Box>
                            <Box component="div" xs={12}>
                                <Grid container spacing={2} direction='row' xm={12} sm={12} md={12} lg={12} xl={12} justifyContent='end' sx={{ mb: 2 }}>
                                    <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                                        <Grid item>
                                            <Button variant='outlined' sx={{textTransform: 'none'}} startIcon={<FileCopyOutlinedIcon />}>Export</Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <TableContainer component={Paper} sx={{ mb: 2 }}>
                                    <Table aria-label="customized table">
                                        <TableHead>
                                            <TableRow sx={styles.TableRow}>
                                                <StyledTableCell>SubLocation</StyledTableCell>
                                                <StyledTableCell>-</StyledTableCell>
                                                <StyledTableCell>Canteen</StyledTableCell>
                                                <StyledTableCell>Dust</StyledTableCell>
                                                <StyledTableCell>General</StyledTableCell>
                                                <StyledTableCell>Noise and Dust</StyledTableCell>
                                                <StyledTableCell>Noise</StyledTableCell>
                                                <StyledTableCell>Total</StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {reportListRows.map((row) => (
                                                <StyledTableRow key={row.subLocation} sx={styles.TableRow}>
                                                    <StyledTableCell>{row.subLocation}</StyledTableCell>
                                                    <StyledTableCell>{row.dash}</StyledTableCell>
                                                    <StyledTableCell>{row.canteen}</StyledTableCell>
                                                    <StyledTableCell>{row.dush}</StyledTableCell>
                                                    <StyledTableCell>{row.general}</StyledTableCell>
                                                    <StyledTableCell>{row.noiseAndDust}</StyledTableCell>
                                                    <StyledTableCell>{row.noise}</StyledTableCell>
                                                    <StyledTableCell>{row.Total}</StyledTableCell>
                                                </StyledTableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <Grid container spacing={2} direction='row' xm={12} sm={12} md={12} lg={12} xl={12} justifyContent='space-between'>
                                    <Grid item sx={{fontWeight: 'bold'}}>
                                        Showing 1 to 10 of 10 entries
                                    </Grid>
                                    <Grid item>
                                        <Button variant='contained' sx={{ mr: 2, textTransform: 'none' }} disabled>Previous</Button>
                                        <Button variant='contained' sx={{textTransform: 'none'}} disabled>Next</Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={2}>
                            <Box component='h2' align='center' sx={{ m: 0, p: 0 }}>Location Wise Health Analysis</Box>
                            <Box component="div" xs={12}>
                                <Grid container spacing={2} direction='row' xm={12} sm={12} md={12} lg={12} xl={12} justifyContent='end' sx={{ mb: 2 }}>
                                    <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                                        <Grid item>
                                            <Button variant='outlined' sx={{textTransform: 'none'}} startIcon={<FileCopyOutlinedIcon />}>Export</Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <TableContainer component={Paper} sx={{ mb: 2 }}>
                                    <Table aria-label="customized table">
                                        <TableHead>
                                            <TableRow sx={styles.TableRow}>
                                                <StyledTableCell>Sub Location</StyledTableCell>
                                                <StyledTableCell>Total Examined Employee</StyledTableCell>
                                                <StyledTableCell>Generated</StyledTableCell>
                                                <StyledTableCell>NotGenerated</StyledTableCell>
                                                <StyledTableCell>Health Index (%)</StyledTableCell>
                                                <StyledTableCell>Indicator</StyledTableCell>
                                                <StyledTableCell>Very Healthy (%)</StyledTableCell>
                                                <StyledTableCell>Healthy (%)</StyledTableCell>
                                                <StyledTableCell>Low Risk (%)</StyledTableCell>
                                                <StyledTableCell>Moderate Risk (%)</StyledTableCell>
                                                <StyledTableCell>High Risk (%)</StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {reportListRows.map((row) => (
                                                <StyledTableRow key={row.subLocation} sx={styles.TableRow}>
                                                    <StyledTableCell>{row.subLocation}</StyledTableCell>
                                                    <StyledTableCell>{row.totalExaminedEmployee}</StyledTableCell>
                                                    <StyledTableCell>{row.generated}</StyledTableCell>
                                                    <StyledTableCell>{row.notGenerated}</StyledTableCell>
                                                    <StyledTableCell>{row.healthIndex}</StyledTableCell>
                                                    <StyledTableCell>{row.indicator}</StyledTableCell>
                                                    <StyledTableCell>{row.veryHealthy}</StyledTableCell>
                                                    <StyledTableCell>{row.healthy}</StyledTableCell>
                                                    <StyledTableCell>{row.lowRisk}</StyledTableCell>
                                                    <StyledTableCell>{row.moderateRisk}</StyledTableCell>
                                                    <StyledTableCell>{row.highRisk}</StyledTableCell>
                                                </StyledTableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <Grid container spacing={2} direction='row' xm={12} sm={12} md={12} lg={12} xl={12} justifyContent='space-between'>
                                    <Grid item sx={{fontWeight: 'bold'}}>
                                        Showing 1 to 10 of 10 entries
                                    </Grid>
                                    <Grid item>
                                        <Button variant='contained' sx={{ mr: 2, textTransform: 'none' }} disabled>Previous</Button>
                                        <Button variant='contained' sx={{textTransform: 'none'}} disabled>Next</Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={3}>
                            <Box component='h2' align='center' sx={{ m: 0, p: 0 }}>Diagnosis Tracker</Box>
                            <Box component="div" xs={12}>
                                <Grid container spacing={2} direction='row' xm={12} sm={12} md={12} lg={12} xl={12} justifyContent='end' sx={{ mb: 2 }}>
                                    <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                                        <Grid item>
                                            <Button variant='outlined' sx={{textTransform: 'none'}} startIcon={<FileCopyOutlinedIcon />}>Export</Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <TableContainer component={Paper} sx={{ mb: 2 }}>
                                    <Table aria-label="customized table">
                                        <TableHead>
                                            <TableRow sx={styles.TableRow}>
                                                <StyledTableCell>Sub Location</StyledTableCell>
                                                <StyledTableCell>Tobacco</StyledTableCell>
                                                <StyledTableCell>Smoker</StyledTableCell>
                                                <StyledTableCell>Alcohol</StyledTableCell>
                                                <StyledTableCell>UnderWeight</StyledTableCell>
                                                <StyledTableCell>OverWeight</StyledTableCell>
                                                <StyledTableCell>Obese</StyledTableCell>
                                                <StyledTableCell>TB</StyledTableCell>
                                                <StyledTableCell>COPD</StyledTableCell>
                                                <StyledTableCell>PreHypertension</StyledTableCell>
                                                <StyledTableCell>Hypertension</StyledTableCell>
                                                <StyledTableCell>Pre Diab</StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {reportListRows.map((row) => (
                                                <StyledTableRow key={row.subLocation} sx={styles.TableRow}>
                                                    <StyledTableCell>{row.subLocation}</StyledTableCell>
                                                    <StyledTableCell>{row.tobacco}</StyledTableCell>
                                                    <StyledTableCell>{row.smoker}</StyledTableCell>
                                                    <StyledTableCell>{row.alcohol}</StyledTableCell>
                                                    <StyledTableCell>{row.underWeight}</StyledTableCell>
                                                    <StyledTableCell>{row.overWeight}</StyledTableCell>
                                                    <StyledTableCell>{row.obese}</StyledTableCell>
                                                    <StyledTableCell>{row.tb}</StyledTableCell>
                                                    <StyledTableCell>{row.copd}</StyledTableCell>
                                                    <StyledTableCell>{row.preHypertension}</StyledTableCell>
                                                    <StyledTableCell>{row.hypertension}</StyledTableCell>
                                                    <StyledTableCell>{row.preDiab}</StyledTableCell>
                                                </StyledTableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <Grid container spacing={2} direction='row' xm={12} sm={12} md={12} lg={12} xl={12} justifyContent='space-between'>
                                    <Grid item sx={{fontWeight: 'bold'}}>
                                        Showing 1 to 10 of 10 entries
                                    </Grid>
                                    <Grid item>
                                        <Button variant='contained' sx={{ mr: 2, textTransform: 'none' }} disabled>Previous</Button>
                                        <Button variant='contained' sx={{textTransform: 'none'}} disabled>Next</Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={4}>
                            <Box component='h2' align='center' sx={{ m: 0, p: 0 }}>Status Detail</Box>
                            <Box component="div" xs={12}>
                                <Grid container spacing={2} direction='row' xm={12} sm={12} md={12} lg={12} xl={12} justifyContent='end' sx={{ mb: 2 }}>
                                    <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                                        <Grid item>
                                            <Button variant='outlined' sx={{textTransform: 'none'}} startIcon={<FileCopyOutlinedIcon />}>Export</Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <TableContainer component={Paper} sx={{ mb: 2 }}>
                                    <Table aria-label="customized table">
                                        <TableHead>
                                            <TableRow sx={styles.TableRow}>
                                                <StyledTableCell>Sub Location</StyledTableCell>
                                                <StyledTableCell>Total Freezed Employee</StyledTableCell>
                                                <StyledTableCell>InProgress</StyledTableCell>
                                                <StyledTableCell>Completed</StyledTableCell>
                                                <StyledTableCell>Closed</StyledTableCell>
                                                <StyledTableCell>Not Started</StyledTableCell>
                                                <StyledTableCell>Generated</StyledTableCell>
                                                <StyledTableCell>Not Generated</StyledTableCell>
                                                <StyledTableCell>Health Index (%)</StyledTableCell>
                                                <StyledTableCell>Indicator</StyledTableCell>
                                                <StyledTableCell>Very Healthy (%)</StyledTableCell>
                                                <StyledTableCell>Healthy (%)</StyledTableCell>
                                                <StyledTableCell>Low Risk (%)</StyledTableCell>
                                                <StyledTableCell>Moderate Risk (%)</StyledTableCell>
                                                <StyledTableCell>High Risk (%)</StyledTableCell>
                                                <StyledTableCell>Classic Well (%)</StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {reportListRows.map((row) => (
                                                <StyledTableRow key={row.subLocation} sx={styles.TableRow}>
                                                    <StyledTableCell>{row.subLocation}</StyledTableCell>
                                                    <StyledTableCell>{row.totalFreezedEmployee}</StyledTableCell>
                                                    <StyledTableCell>{row.inprogress}</StyledTableCell>
                                                    <StyledTableCell>{row.completed}</StyledTableCell>
                                                    <StyledTableCell>{row.closed}</StyledTableCell>
                                                    <StyledTableCell>{row.notStarted}</StyledTableCell>
                                                    <StyledTableCell>{row.generated}</StyledTableCell>
                                                    <StyledTableCell>{row.notGenerated}</StyledTableCell>
                                                    <StyledTableCell>{row.healthIndex}</StyledTableCell>
                                                    <StyledTableCell>{row.indicator}</StyledTableCell>
                                                    <StyledTableCell>{row.veryHealthy}</StyledTableCell>
                                                    <StyledTableCell>{row.healthy}</StyledTableCell>
                                                    <StyledTableCell>{row.lowRisk}</StyledTableCell>
                                                    <StyledTableCell>{row.moderateRisk}</StyledTableCell>
                                                    <StyledTableCell>{row.highRisk}</StyledTableCell>
                                                    <StyledTableCell>{row.classicWell}</StyledTableCell>
                                                </StyledTableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <Grid container spacing={2} direction='row' xm={12} sm={12} md={12} lg={12} xl={12} justifyContent='space-between'>
                                    <Grid item sx={{fontWeight: 'bold'}}>
                                        Showing 1 to 10 of 10 entries
                                    </Grid>
                                    <Grid item>
                                        <Button variant='contained' sx={{ mr: 2, textTransform: 'none' }} disabled>Previous</Button>
                                        <Button variant='contained'  sx={{textTransform: 'none' }} disabled>Next</Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={5}>
                            <Box component='h2' align='center' sx={{ m: 0, p: 0 }}>Examination Counselling</Box>
                            <Box component="div" xs={12}>
                                <Grid container spacing={2} direction='row' xm={12} sm={12} md={12} lg={12} xl={12} justifyContent='end' sx={{ mb: 2 }}>
                                    <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                                        <Grid item>
                                            <Button variant='outlined' sx={{textTransform: 'none' }} startIcon={<FileCopyOutlinedIcon />}>Export</Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <TableContainer component={Paper} sx={{ mb: 2 }}>
                                    <Table aria-label="customized table">
                                        <TableHead>
                                            <TableRow sx={styles.TableRow}>
                                                <StyledTableCell>Sub Location</StyledTableCell>
                                                <StyledTableCell>Total</StyledTableCell>
                                                <StyledTableCell>Done</StyledTableCell>
                                                <StyledTableCell>Not Done</StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {reportListRows.map((row) => (
                                                <StyledTableRow key={row.subLocation} sx={styles.TableRow}>
                                                    <StyledTableCell>{row.subLocation}</StyledTableCell>
                                                    <StyledTableCell>{row.total}</StyledTableCell>
                                                    <StyledTableCell>{row.done}</StyledTableCell>
                                                    <StyledTableCell>{row.notDone}</StyledTableCell>
                                                </StyledTableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <Grid container spacing={2} direction='row' xm={12} sm={12} md={12} lg={12} xl={12} justifyContent='space-between'>
                                    <Grid item sx={{fontWeight: 'bold'}}>
                                        Showing 1 to 10 of 10 entries
                                    </Grid>
                                    <Grid item>
                                        <Button variant='contained' sx={{ mr: 2, textTransform: 'none' }} disabled>Previous</Button>
                                        <Button variant='contained' sx={{textTransform: 'none' }} disabled>Next</Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={6}></CustomTabPanel>
                        <CustomTabPanel value={value} index={7}></CustomTabPanel>
                        <CustomTabPanel value={value} index={8}></CustomTabPanel>
                        <CustomTabPanel value={value} index={9}></CustomTabPanel>
                    </Box>
                </Box>
            </React.Fragment>
        </>
    )
}