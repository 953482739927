import sharedImports from '../../../Comman/sharedImports';
const {
    React,
    PropTypes,
    Box,
    Grid,
    Slide,
    Link,
    Select,
    AppBar,
    Button,
    Toolbar,
    MenuItem,
    Typography,
    TextField,
    CssBaseline,
    FormControl,
    useScrollTrigger,
    CancelOutlinedIcon,
    LocalizationProvider,
    DemoItem,
    DatePicker,
    VisibilityOutlinedIcon,
    AddCircleOutlineOutlinedIcon,
    AdapterDayjs,
} = sharedImports;


const drawerWidth = 300;
function HideOnScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
};
const styles = {
    TableRow: {
        th: {
            width: '10%',
            padding: '5px',
            svg: {
                float: 'right',
                color: '#1976d2'
            },
        },
        td: {
            padding: '5px',
            border: '1px solid lightgray',
        }
    },
    h: {
        minHeight: '40px !important'
    },
    bgWhite: {
        bgcolor: 'white',
        color: 'black'
    }
}

export default function ClaimAnalysis(props) {

    const [state, setState] = React.useState({
        Report: ''
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setState(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    return (
        <>
            <React.Fragment>
                <Box>
                    <CssBaseline />
                    <HideOnScroll {...props}>
                        <AppBar
                            sx={{
                                width: { sm: `calc(100% - ${drawerWidth}px)` },
                                ml: { sm: `${drawerWidth}px` },
                                mt: { xs: '53px', sm: '64px' },
                                zIndex: 1099
                            }}
                            elevation={1}
                        >
                            <Toolbar sx={[styles.h, styles.bgWhite]}>
                                <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Grid item xs={12} md={10}>
                                        <Typography variant="h6" noWrap component="div" sx={{ m: 1, fontWeight: 'bold' }}>
                                            Claim Analysis
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                        <Link to='/dashboard/support/asset-management/asset-restoration/create'>
                                            <Button variant='contained' startIcon={<AddCircleOutlineOutlinedIcon />} sx={{ bgcolor: 'gray', textTransform: 'none', m: 1 }}> Add </Button>
                                        </Link>
                                    </Grid>
                                </Grid>
                            </Toolbar>
                        </AppBar>
                    </HideOnScroll>
                    <Box sx={{ mt: 8, mb: 2, mx: 2, bgcolor: 'white', p: 3, borderRadius: 1 }}>
                        <Box component="div" xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Report <Box component='span' sx={{ color: 'red' }}>*</Box></Box>
                                    <Grid item xs={12} md={11}>
                                        <FormControl fullWidth>
                                            <Select
                                                name="Report"
                                                value={state.Report}
                                                onChange={handleChange}
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Without label' }}
                                            >
                                                <MenuItem sx={{ ml: 3 }} value="">
                                                    <>-All-</>
                                                </MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={1}>BP Instrument- First-aid room</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={2}>ECG</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={3}>Oxygen Concentrator</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={4}>Concentrator</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={5}>Infusion pump</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={6}>Thermal Gun</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={7}>Glucometer</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={8}>Two para oximeter</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Policy Number <Box component='span' sx={{ color: 'red' }}>*</Box></Box>
                                    <Grid item xs={12} md={11}>
                                        <TextField
                                            required
                                            id="filled-search"
                                            fullWidth
                                            size='small'
                                            placeholder="Policy Number "
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} sx={{ mb: 4 }}>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>From Date <Box component='span' sx={{ color: 'red' }}>*</Box></Box>
                                    <Grid item xs={12} md={11}>
                                        <FormControl fullWidth>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DemoItem >
                                                    <DatePicker />
                                                </DemoItem>
                                            </LocalizationProvider>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>To Date <Box component='span' sx={{ color: 'red' }}>*</Box></Box>
                                    <Grid item xs={12} md={11}>
                                        <FormControl fullWidth>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DemoItem >
                                                    <DatePicker />
                                                </DemoItem>
                                            </LocalizationProvider>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} direction='row' xm={12} sm={12} md={12} lg={12} xl={12} justifyContent='center'>
                                <Grid item>
                                    <Button variant='contained' sx={{ textTransform: 'none' }} startIcon={<VisibilityOutlinedIcon />}>View Report</Button>
                                </Grid>
                                <Grid item>
                                    <Button variant='outlined' sx={{ textTransform: 'none' }} startIcon={<CancelOutlinedIcon />}>Clear</Button>
                                </Grid>

                            </Grid>
                        </Box>
                    </Box>
                </Box>
            </React.Fragment>
        </>
    )
}