import sharedImports from '../../../Comman/sharedImports';
const {
    React,
    Box,
    Grid,
    Select,
    Paper,
    Table,
    Stack,
    styled,
    Button,
    AppBar,
    Slide,
    Toolbar,
    Checkbox,
    TableRow,
    MenuItem,
    TextField,
    TableBody,
    FormControl,
    DatePicker,
    TableHead,
    DemoItem,
    SearchIcon,
    Typography,
    AdapterDayjs,
    TableContainer,
    TableCell,
    PropTypes,
    tableCellClasses,
    NoteAddIcon,
    useScrollTrigger,
    FormControlLabel,
    HighlightOffIcon,
    SaveAsOutlinedIcon,
    LocalizationProvider,
    UnfoldMoreOutlinedIcon,
} = sharedImports;

const drawerWidth = 300;
function HideOnScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const rows = [
    { temporaryCode: 65325421, employeeCode: null, name: 'palak', department: 'Finance', status: 'Not Started', fitness: 'Pending', reason: null },
    { temporaryCode: 65325421, employeeCode: null, name: 'palak', department: 'Finance', status: 'Not Started', fitness: 'Pending', reason: null },
    { temporaryCode: 879879879, employeeCode: null, name: 'palak', department: 'Finance', status: 'Not Started', fitness: 'Pending', reason: null },
    { temporaryCode: 879879879, employeeCode: null, name: 'palak', department: 'Finance', status: 'Not Started', fitness: 'Pending', reason: null },
    { temporaryCode: 986544, employeeCode: null, name: 'palak', department: 'Finance', status: 'Not Started', fitness: 'Pending', reason: null },
    { temporaryCode: 986544, employeeCode: null, name: 'palak', department: 'Finance', status: 'Not Started', fitness: 'Pending', reason: null },
    { temporaryCode: 986544, employeeCode: null, name: 'palak', department: 'Finance', status: 'Not Started', fitness: 'Pending', reason: null },
];

export default function HRListing(props) {

    const [state, setState] = React.useState({
        Function: '',
        Department: '',
        DepartmentGroup: '',
        Report: '',
        Filter: ''
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setState(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const styles = {
        firstForm: {
            padding: '16px 16px 34px 16px',
            backgroundColor: '#f1f1f1',
        },
        bgWhite: {
            backgroundColor: 'white',
            color: 'black'
        },
        wFull: {
            width: '100%'
        },
        marginBottom5: {
            marginBottom: 5,
            mt: 5
        },
        TableRow: {
            th: {
                width: '12.5%',
                svg: {
                    float: 'right',
                    color: '#1976d2'
                },
            },
            td: {
                border: '1px solid lightgray',
            },
        },
        h: {
            minHeight: '40px !important'
        },
    }

    return (
        <>
            <Box sx={styles.root}>
                <HideOnScroll {...props}>
                    <AppBar
                        sx={{
                            width: { sm: `calc(100% - ${drawerWidth}px)` },
                            ml: { sm: `${drawerWidth}px` },
                            mt: { xs: '53px', sm: '64px' },
                            zIndex: 1099
                        }}
                        elevation={1}
                    >
                        <Toolbar sx={[styles.h, styles.bgWhite]}>
                            <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Grid item>
                                    <Typography variant="h6" noWrap component="div" sx={{ m: 1, fontWeight: 'bold' }}>
                                        HR Listing
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Toolbar>
                    </AppBar>
                </HideOnScroll>

                <Box sx={styles.firstForm}>
                    <Grid container spacing={2} sx={{ marginTop: '45px' }}>
                        <Grid item xs={12} md={3}>
                            <Box sx={{ fontWeight: 'bold' }}>Function</Box>
                            <Grid item xs={12} md={11}>
                                <FormControl fullWidth>
                                    <Select
                                        name="Function"
                                        value={state.Function}
                                        onChange={handleChange}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        <MenuItem sx={{ ml: 3 }} value="">
                                            <>-Select-</>
                                        </MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={10}>Ten</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={20}>Twenty</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={30}>Thirty</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <Box sx={{ fontWeight: 'bold' }}>Department</Box>
                            <Grid item xs={12} md={11}>
                                <FormControl fullWidth>
                                    <Select
                                        name="Department"
                                        value={state.Department}
                                        onChange={handleChange}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        <MenuItem sx={{ ml: 3 }} value="">
                                            <>-Select-</>
                                        </MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={10}>Ten</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={20}>Twenty</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={30}>Thirty</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <Box sx={{ fontWeight: 'bold' }}>Department Group</Box>
                            <Grid item xs={12} md={11}>
                                <FormControl fullWidth>
                                    <Select
                                        name="DepartmentGroup"
                                        value={state.DepartmentGroup}
                                        onChange={handleChange}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        <MenuItem sx={{ ml: 3 }} value="">
                                            <>-Select-</>
                                        </MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={10}>Ten</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={20}>Twenty</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={30}>Thirty</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={3}></Grid>

                        <Grid item xs={12} md={3}>
                            <Box sx={{ fontWeight: 'bold' }}>Code</Box>
                            <Grid item xs={12} md={11}>
                                <TextField
                                    required
                                    id="filled-search"
                                    fullWidth
                                    size='small'
                                    placeholder="Outlined"
                                />
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <Box sx={{ fontWeight: 'bold' }}>Code Like</Box>
                            <Grid item xs={12} md={11}>
                                <TextField
                                    required
                                    id="filled-search"
                                    fullWidth
                                    size='small'
                                    placeholder="Code Like"
                                />
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <Box sx={{ fontWeight: 'bold' }}>Report</Box>
                            <Grid item xs={12} md={11}>
                                <FormControl fullWidth>
                                    <Select
                                        name="Report"
                                        value={state.Report}
                                        onChange={handleChange}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        <MenuItem sx={{ ml: 3 }} value="">
                                            <>-Select-</>
                                        </MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={10}>Ten</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={20}>Twenty</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={30}>Thirty</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={3}></Grid>

                        <Grid item xs={12} md={3}>
                            <Box sx={{ fontWeight: 'bold' }}>From Date</Box>
                            <Grid item xs={12} md={11}>
                                <FormControl fullWidth>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoItem >
                                            <DatePicker />
                                        </DemoItem>
                                    </LocalizationProvider>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <Box sx={{ fontWeight: 'bold' }}>To Date</Box>
                            <Grid item xs={12} md={11}>
                                <FormControl fullWidth>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoItem >
                                            <DatePicker />
                                        </DemoItem>
                                    </LocalizationProvider>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Box sx={{ fontWeight: 'bold' }}>Filter</Box>
                            <Grid item xs={12} md={11}>
                                <FormControl fullWidth>
                                    <Select
                                        name="Filter"
                                        value={state.Filter}
                                        onChange={handleChange}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        <MenuItem sx={{ ml: 3 }} value="">
                                            <>-All-</>
                                        </MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={10}>Ten</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={20}>Twenty</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={30}>Thirty</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                value="end"
                                control={<Checkbox />}
                                label="Temporary Registration"
                                labelPlacement="end"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Stack spacing={2} direction="row" justifyContent='center'>
                                <Button variant="contained" sx={{ textTransform: 'none' }} startIcon={<SearchIcon />}>Search</Button>
                                <Button variant="outlined" sx={{ textTransform: 'none' }} startIcon={<HighlightOffIcon />}>Clear</Button>
                                <Button variant="outlined" sx={{ textTransform: 'none' }} startIcon={<NoteAddIcon />}>Export</Button>
                            </Stack>
                        </Grid>
                    </Grid>
                </Box>
                <Box sx={styles.firstForm}>
                    <TableContainer component={Paper}>
                        <Table aria-label="customized table">
                            <TableHead>
                                <TableRow sx={styles.TableRow}>
                                    <StyledTableCell>Temporary Code <UnfoldMoreOutlinedIcon /></StyledTableCell>
                                    <StyledTableCell>Employee Code <UnfoldMoreOutlinedIcon /></StyledTableCell>
                                    <StyledTableCell>Employee Name <UnfoldMoreOutlinedIcon /></StyledTableCell>
                                    <StyledTableCell>Department <UnfoldMoreOutlinedIcon /></StyledTableCell>
                                    <StyledTableCell>Status <UnfoldMoreOutlinedIcon /></StyledTableCell>
                                    <StyledTableCell>Fitness <UnfoldMoreOutlinedIcon /></StyledTableCell>
                                    <StyledTableCell>Reason <UnfoldMoreOutlinedIcon /></StyledTableCell>
                                    <StyledTableCell><UnfoldMoreOutlinedIcon /></StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map((row) => (
                                    <StyledTableRow key={row.temporaryCode} sx={styles.TableRow}>
                                        <StyledTableCell> {row.temporaryCode} </StyledTableCell>
                                        <StyledTableCell>{row.employeeCode}</StyledTableCell>
                                        <StyledTableCell>{row.name}</StyledTableCell>
                                        <StyledTableCell>{row.department}</StyledTableCell>
                                        <StyledTableCell>{row.status}</StyledTableCell>
                                        <StyledTableCell>{row.fitness}</StyledTableCell>
                                        <StyledTableCell>{row.reason}</StyledTableCell>
                                        <StyledTableCell><SaveAsOutlinedIcon color='primary' /></StyledTableCell>
                                    </StyledTableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Box>
        </>
    )
}