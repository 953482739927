import sharedImports from '../../../Comman/sharedImports';
const {
    React,
    Box,
    Grid,
    Select,
    Slide,
    Button,
    Toolbar,
    AppBar,
    PropTypes,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    MenuItem,
    SaveOutlinedIcon,
    TextField,
    Typography,
    FormControl,
    useScrollTrigger,
    HighlightOffIcon,
    DialogContentText,
    FormatListBulletedIcon,
} = sharedImports;

const drawerWidth = 300;
function HideOnScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
};

export default function EmployeeTransferOut(props) {
    const [state, setState] = React.useState({
        FromLocation: '',
        ToLocation: ''
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setState(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const styles = {
        firstForm: {
            padding: '16px 16px 34px 16px',
            backgroundColor: '#f1f1f1'
        },
        bgWhite: {
            backgroundColor: 'white',
            color: 'black'
        },
        wFull: {
            width: '100%'
        },
        marginBottom2: {
            marginBottom: 2,
        },
        TableRow: {
            th: {
                width: '12.5%',
                svg: {
                    float: 'right',
                    color: '#1976d2'
                },
            },
            td: {
                border: '1px solid lightgray',
            }
        },
        h: {
            minHeight: '40px !important'
        },
        p20: {
            padding: '20px'
        },
        bRadius: {
            borderRadius: '5px',
            marginTop: '45px'
        },
        dFlex: {
            display: 'flex'
        },
        justifySB: {
            justifyContent: 'space-between'
        },
        alignC: {
            alignItems: 'center'
        }
    }

    return (
        <Box sx={styles.root}>

            <HideOnScroll {...props}>
                <AppBar
                    sx={{
                        width: { sm: `calc(100% - ${drawerWidth}px)` },
                        ml: { sm: `${drawerWidth}px` },
                        mt: { xs: '53px', sm: '64px' },
                        zIndex: 1099
                    }}
                    elevation={1}
                >
                    <Toolbar sx={[styles.h, styles.bgWhite]}>
                        <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Grid item xs={12} md={5.5}>
                                <Typography variant="h6" noWrap component="div" sx={{ m: 1, fontWeight: 'bold' }}>
                                    Employee Transfer Out
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={5.5}>
                                <Button variant='contained' startIcon={<SaveOutlinedIcon />} sx={{ mr: 2, m: 1, textTransform: 'none' }}> Save </Button>
                                <Button variant='outlined' startIcon={<HighlightOffIcon />} sx={{ textTransform: 'none' }}> Clear </Button>
                            </Grid>
                            <Grid item xs={12} md={1}>
                                <React.Fragment>
                                    <Button
                                        variant="contained"
                                        startIcon={<FormatListBulletedIcon />}
                                        onClick={handleClickOpen}
                                        item xs={12} md={1} sx={{ textTransform: 'none', m: 1 }}>
                                        List
                                    </Button>
                                    <Dialog
                                        open={open}
                                        onClose={handleClose}
                                        PaperProps={{
                                            component: 'form',
                                            onSubmit: (event) => {
                                                event.preventDefault();
                                                const formData = new FormData(event.currentTarget);
                                                const formJson = Object.fromEntries(formData.entries());
                                                const email = formJson.email;
                                                console.log(email);
                                                handleClose();
                                            },
                                        }}
                                    >
                                        <DialogTitle>Subscribe</DialogTitle>
                                        <DialogContent>
                                            <DialogContentText>
                                                To subscribe to this website, please enter your email address here. We
                                                will send updates occasionally.
                                            </DialogContentText>
                                            <TextField
                                                autoFocus
                                                required
                                                margin="dense"
                                                id="name"
                                                name="email"
                                                label="Email Address"
                                                type="email"
                                                fullWidth
                                                variant="standard"
                                            />
                                        </DialogContent>
                                        <DialogActions>
                                            <Button sx={{ textTransform: 'none' }} onClick={handleClose}>Cancel</Button>
                                            <Button sx={{ textTransform: 'none' }} type="submit">Subscribe</Button>
                                        </DialogActions>
                                    </Dialog>
                                </React.Fragment>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
            </HideOnScroll>
            <Box sx={styles.firstForm}>
                <Box sx={[styles.bgWhite, styles.wFull, styles.p20, styles.bRadius]} className='MainPadding'>
                    <Grid container spacing={1}>

                        <Grid item xs={12} md={3}>
                            <Box sx={{ fontWeight: 'bold' }}>Employee Code <Box component='span' sx={{ color: 'red' }}>*</Box></Box>
                            <Grid item xs={12} md={11}>
                                <TextField
                                    required
                                    id="filled-search"
                                    fullWidth
                                    size='small'
                                    placeholder="Employee Code "
                                />
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <Box sx={{ fontWeight: 'bold' }}>Employee Name <Box component='span' sx={{ color: 'red' }}>*</Box></Box>
                            <Grid item xs={12} md={11}>
                                <TextField
                                    required
                                    id="filled-search"
                                    fullWidth
                                    size='small'
                                    placeholder="Employee Name"
                                />
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={3}></Grid>
                        <Grid item xs={12} md={3}></Grid>

                        <Grid item xs={12} md={3}>
                            <Box sx={{ fontWeight: 'bold' }}>From Location</Box>
                            <Grid item xs={12} md={11}>
                                <FormControl fullWidth>
                                    <Select
                                        name="FromLocation"
                                        value={state.FromLocation}
                                        onChange={handleChange}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        <MenuItem sx={{ ml: 3 }} value="">
                                            <>-Select-</>
                                        </MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={10}>Ten</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={20}>Twenty</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={30}>Thirty</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <Box sx={{ fontWeight: 'bold' }}>To Location</Box>
                            <Grid item xs={12} md={11}>
                                <FormControl fullWidth>
                                    <Select
                                        name="ToLocation"
                                        value={state.ToLocation}
                                        onChange={handleChange}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        <MenuItem sx={{ ml: 3 }} value="">
                                            <>-Select-</>
                                        </MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={10}>Ten</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={20}>Twenty</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={30}>Thirty</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>


                        <Grid item xs={12} md={3}></Grid>
                        <Grid item xs={12} md={3}></Grid>

                        <Grid container spacing={1} sx={{ mt: .5, ml: 1 }}>
                            <Grid item xs={12} md={6}>
                                <Box sx={{ fontWeight: 'bold' }}>Remark</Box>
                                <textarea
                                    name="postContent"
                                    style={{ height: '65px', width: '96%' }}
                                    placeholder='Remark'
                                />
                            </Grid>
                            <Grid item xs={12} md={6}></Grid>

                        </Grid>

                    </Grid>
                </Box>
            </Box>
        </Box>
    )
}