import sharedImports from '../../../Comman/sharedImports';
const {
    React,
    PropTypes,
    Box,
    Grid,
    Slide,
    AppBar,
    styled,
    DeleteOutlineIcon,
    Button,
    Toolbar,
    TableContainer,
    Paper,
    Table,
    Tooltip,
    IconButton,
    TableHead,
    TableBody,
    TextField,
    MenuItem,
    Select,
    DatePicker,
    Typography,
    AdapterDayjs,
    DemoItem,
    EditNoteOutlinedIcon,
    CssBaseline,
    FormControl,
    LocalizationProvider,
    useScrollTrigger,
    TableCell,
    tableCellClasses,
    TableRow,
    CancelOutlinedIcon,
    FormatListBulletedOutlinedIcon,
    SaveOutlinedIcon,
} = sharedImports;


const drawerWidth = 300;
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));
const reportListRows = [
    { srNo: 1, pantryItemName: 'Water Cooler' },
    { srNo: 2, pantryItemName: 'Fridge' },
    { srNo: 3, pantryItemName: 'Fridge' },
    { srNo: 4, pantryItemName: 'Fridge' },
];
function HideOnScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
};
const styles = {
    TableRow: {
        th: {
            padding: '5px',
            svg: {
                float: 'right',
                color: '#1976d2'
            },
        },
        td: {
            padding: '5px',
            border: '1px solid lightgray',
        }
    },
    h: {
        minHeight: '40px !important'
    },
    bgWhite: {
        bgcolor: 'white',
        color: 'black'
    }
}

export default function PantryInspectionSingleEntryUpdate(props) {

    const [age, setAge] = React.useState('');
    function handleChange(event) {
        setAge(event.target.value);
    }

    return (
        <>
            <React.Fragment>
                <Box>
                    <CssBaseline />
                    <HideOnScroll {...props}>
                        <AppBar
                            sx={{
                                width: { sm: `calc(100% - ${drawerWidth}px)` },
                                ml: { sm: `${drawerWidth}px` },
                                mt: { xs: '53px', sm: '64px' },
                                zIndex: 1099
                            }}
                            elevation={1}
                        >
                            <Toolbar sx={[styles.h, styles.bgWhite]}>
                                <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Grid item>
                                        <Typography variant="h6" noWrap component="div" sx={{ m: 1 }}>
                                            Pantry Inspection Single Entry
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Button variant='outlined' startIcon={<SaveOutlinedIcon />} sx={{ m: 1 }}> Save </Button>
                                        <Button variant='contained' startIcon={<EditNoteOutlinedIcon />} sx={{ m: 1 }}> Edit </Button>
                                        <Button variant='outlined' startIcon={<DeleteOutlineIcon />} sx={{ m: 1 }}> Delete </Button>
                                        <Button variant='outlined' startIcon={<CancelOutlinedIcon />} sx={{ m: 1 }}>Clear</Button>
                                    </Grid>
                                    <Grid item>
                                        <Button variant='contained' startIcon={<FormatListBulletedOutlinedIcon />} sx={{ m: 1 }}> List </Button>
                                    </Grid>
                                </Grid>
                            </Toolbar>
                        </AppBar>
                    </HideOnScroll>
                    <Box sx={{ mt: 5, mb: 2, mx: 2, bgcolor: 'white', p: 3, borderRadius: 1 }}>
                        <Box component="div" xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={3}>
                                    <Box>Pantry <Box component='span' sx={{ color: 'red' }}>*</Box></Box>
                                    <FormControl fullWidth>
                                        <Select
                                            value={age}
                                            onChange={handleChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem value="">
                                                <em>-Select-</em>
                                            </MenuItem>
                                            <MenuItem value={1}>BP Instrument- First-aid room</MenuItem>
                                            <MenuItem value={2}>ECG</MenuItem>
                                            <MenuItem value={3}>Oxygen Concentrator</MenuItem>
                                            <MenuItem value={3}>Concentrator</MenuItem>
                                            <MenuItem value={3}>Infusion pump</MenuItem>
                                            <MenuItem value={3}>Thermal Gun</MenuItem>
                                            <MenuItem value={3}>Glucometer</MenuItem>
                                            <MenuItem value={3}>Two para oximeter</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Box>Inspection Date <Box component='span' sx={{ color: 'red' }}>*</Box></Box>
                                    <FormControl fullWidth>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoItem>
                                                <DatePicker />
                                            </DemoItem>
                                        </LocalizationProvider>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Box>Pantry Inspection Status <Box component='span' sx={{ color: 'red' }}>*</Box></Box>
                                    <FormControl fullWidth>
                                        <Select
                                            value={age}
                                            onChange={handleChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem value="">-ALL-</MenuItem>
                                            <MenuItem value={1}>BP Instrument- First-aid room</MenuItem>
                                            <MenuItem value={2}>ECG</MenuItem>
                                            <MenuItem value={3}>Oxygen Concentrator</MenuItem>
                                            <MenuItem value={3}>Concentrator</MenuItem>
                                            <MenuItem value={3}>Infusion pump</MenuItem>
                                            <MenuItem value={3}>Thermal Gun</MenuItem>
                                            <MenuItem value={3}>Glucometer</MenuItem>
                                            <MenuItem value={3}>Two para oximeter</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} sx={{ mb: 2 }}>
                                <Grid item xs={12} md={3}>
                                    <Box>Inspection Remark</Box>
                                    <TextField
                                        id="filled-search"
                                        type="search"
                                        placeholder='Inspection Remark'
                                        multiline
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} sx={{ mb: 2 }}>
                                <Grid item xs={12} md={3}>
                                    <Box>Total Mark <Box component='span' sx={{ color: 'red' }}>*</Box></Box>
                                    <TextField
                                        id="filled-search"
                                        type="search"
                                        placeholder='Total Mark'
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Box>Obtain Mark <Box component='span' sx={{ color: 'red' }}>*</Box></Box>
                                    <TextField
                                        id="filled-search"
                                        type="search"
                                        placeholder='Obtain Mark'
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Box>Percentage <Box component='span' sx={{ color: 'red' }}>*</Box></Box>
                                    <TextField
                                        id="filled-search"
                                        type="search"
                                        placeholder='Percentage'
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                    <Box sx={{ mt: 2, mx: 2, bgcolor: 'white', p: 3, borderRadius: 1 }}>
                        <Box component="div" xs={12}>
                            <Box component='h2' sx={{ m: 0, mb: 3, p: 0 }}>Pantry Item List</Box>
                            <TableContainer component={Paper} sx={{ mb: 2 }}>
                                <Table aria-label="customized table">
                                    <TableHead>
                                        <TableRow sx={styles.TableRow}>
                                            <StyledTableCell>Sr No</StyledTableCell>
                                            <StyledTableCell>Pantry Item Name</StyledTableCell>
                                            <StyledTableCell>Marks</StyledTableCell>
                                            <StyledTableCell>Delete</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {reportListRows.map((row) => (
                                            <StyledTableRow key={row.srNo} sx={styles.TableRow}>
                                                <StyledTableCell>{row.srNo}</StyledTableCell>
                                                <StyledTableCell>{row.pantryItemName}</StyledTableCell>
                                                <StyledTableCell> <TextField id="filled-search" type="search" /> </StyledTableCell>
                                                <StyledTableCell align='center'>
                                                    <Tooltip title="Delete">
                                                        <IconButton>
                                                            <DeleteOutlineIcon sx={{ color: '#1785e3' }} />
                                                        </IconButton>
                                                    </Tooltip>
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Box>
                </Box>
            </React.Fragment>
        </>
    )
}