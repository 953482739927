import sharedImports from '../../../Comman/sharedImports';
const {
    React,
    Box,
    Tabs,
    Tab,
    Alert,
    Grid,
    Slide,
    Select,
    Button,
    Dialog,
    AppBar,
    Toolbar,
    Checkbox,
    SaveIcon,
    MenuItem,
    TextField,
    PropTypes,
    Typography,
    FormControl,
    DatePicker,
    DialogTitle,
    DemoItem,
    DialogContent,
    DialogActions,
    AdapterDayjs,
    useScrollTrigger,
    DialogContentText,
    DeleteOutlineIcon,
    CancelOutlinedIcon,
    FileCopyOutlinedIcon,
    FormatListBulletedIcon,
    FormControlLabel,
    SaveAsOutlinedIcon,
    LocalizationProvider,
} = sharedImports;

const drawerWidth = 300;
function HideOnScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
};

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

export default function EmployeeAllDetail(props) {
    const [state, setState] = React.useState({
        Gender: '',
        EmployeeStatus: '',
        WorkLocation: '',
        Department: '',
        EmployeeTypeGroup: '',
        EmployeeType: '',
        EmployeeSubType: '',
        Grade: '',
        Company: '',
        ContractorSubBusiness: '',
        TargetGroup: '',
        ProcessType: '',
        Skill: '',
        EmployeeGroup: '',
        LegalEntity: '',
        JobTitle: '',
        MadicalCoverage: '',
        DesignatedPosition: '',
        ColorVision: '',
        BloodGroup: '',
        MaritalStatus: '',
        Religion: '',
        Qualification: ''
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setState(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const [value, setValue] = React.useState(0);
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = (newValue) => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const handleChanges = (event, newValue) => {
        setValue(newValue);
    };
    const styles = {
        firstForm: {
            padding: '16px 16px 34px 16px',
            backgroundColor: '#f1f1f1'
        },
        bgWhite: {
            backgroundColor: 'white',
            color: 'black'
        },
        wFull: {
            width: '100%'
        },
        marginBottom2: {
            marginBottom: 2,
        },
        TableRow: {
            th: {
                width: '12.5%',
                svg: {
                    float: 'right',
                    color: '#1976d2'
                },
            },
            td: {
                border: '1px solid lightgray',
            }
        },
        h: {
            minHeight: '40px !important'
        },
        p20: {
            padding: '20px'
        },
        bRadius: {
            borderRadius: '5px',
            marginTop: '45px'
        },
        dFlex: {
            display: 'flex'
        },
        justifySB: {
            justifyContent: 'space-between'
        },
        alignC: {
            alignItems: 'center'
        },
        color: {
            color: 'red'
        },
        fwBold: {
            fontWeight: 'bold'
        }
    }

    return (
        <Box sx={styles.root}>
            <HideOnScroll {...props}>
                <AppBar
                    sx={{
                        width: { sm: `calc(100% - ${drawerWidth}px)` },
                        ml: { sm: `${drawerWidth}px` },
                        mt: { xs: '53px', sm: '64px' },
                        zIndex: 1099
                    }}
                    elevation={1}
                >
                    <Toolbar sx={[styles.h, styles.bgWhite]}>
                        <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Grid item xs={12} md={5}>
                                <Typography variant="h6" noWrap component="div" sx={{ m: 1, fontWeight: 'bold' }}>
                                    Employee All Detail
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Button variant="contained" sx={{ mr: 2, m: 1, textTransform: 'none' }} startIcon={<SaveIcon />}>Save</Button>
                                <Button variant="outlined" sx={{ mr: 2, m: 1, textTransform: 'none' }} disabled startIcon={<SaveAsOutlinedIcon />}>Edit</Button>
                                <Button variant="outlined" sx={{ mr: 2, m: 1, textTransform: 'none' }} disabled startIcon={<DeleteOutlineIcon />}>Delete</Button>
                                <Button variant="contained" sx={{ mr: 2, m: 1, textTransform: 'none' }} startIcon={<CancelOutlinedIcon />}>Clear</Button>
                            </Grid>
                            <Grid item xs={12} md={1}>
                                <React.Fragment>
                                    <Button
                                        variant="contained"
                                        startIcon={<FormatListBulletedIcon />}
                                        onClick={handleClickOpen}
                                        item xs={12} md={1} sx={{ textTransform: 'none', m: 1 }}>
                                        List
                                    </Button>
                                    <Dialog
                                        open={open}
                                        onClose={handleClose}
                                        PaperProps={{
                                            component: 'form',
                                            onSubmit: (event) => {
                                                event.preventDefault();
                                                const formData = new FormData(event.currentTarget);
                                                const formJson = Object.fromEntries(formData.entries());
                                                const email = formJson.email;
                                                console.log(email);
                                                handleClose();
                                            },
                                        }}
                                    >
                                        <DialogTitle>Subscribe</DialogTitle>
                                        <DialogContent>
                                            <DialogContentText>
                                                To subscribe to this website, please enter your email address here. We
                                                will send updates occasionally.
                                            </DialogContentText>
                                            <TextField
                                                autoFocus
                                                required
                                                margin="dense"
                                                id="name"
                                                name="email"
                                                label="Email Address"
                                                type="email"
                                                fullWidth
                                                variant="standard"
                                            />
                                        </DialogContent>
                                        <DialogActions>
                                            <Button sx={{ textTransform: 'none' }} onClick={handleClose}>Cancel</Button>
                                            <Button sx={{ textTransform: 'none' }} type="submit">Subscribe</Button>
                                        </DialogActions>
                                    </Dialog>
                                </React.Fragment>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
            </HideOnScroll>
            <Box sx={styles.firstForm}>
                <Box sx={[styles.bgWhite, styles.wFull, styles.p20, styles.bRadius]} className='MainPadding'>

                    <Box sx={{ fontWeight: 600 }}>Code : Name :</Box>
                    <Box sx={{ maxWidth: { xs: 1, sm: 1 }, bgcolor: 'background.paper', borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs
                            value={value}
                            onChange={handleChanges}
                            variant="scrollable"
                            scrollButtons
                            allowScrollButtonsMobile
                            aria-label="scrollable force tabs example"
                        >
                            <Tab sx={{ textTransform: 'none', color: '#1976d2' }} label="General" />
                            <Tab sx={{ textTransform: 'none', color: '#1976d2' }} label="Family" />
                            <Tab sx={{ textTransform: 'none', color: '#1976d2' }} label="Employment" />
                        </Tabs>
                    </Box>

                    <Box sx={[styles.bgWhite, styles.wFull]} className='MainPadding'>
                        <CustomTabPanel value={value} index={0}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={3}>
                                    <FormControlLabel
                                        value="end"
                                        control={<Checkbox />}
                                        label="Temporary Tegistration"
                                        labelPlacement="end"
                                    />
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Temporary Code</Box>
                                    <TextField
                                        required
                                        id="filled-search"
                                        fullWidth
                                        size='small'
                                        placeholder="Temp Code"
                                    />
                                </Grid>

                                <Grid item xs={12} md={3}></Grid>
                                <Grid item xs={12} md={3}></Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Code</Box>
                                    <TextField
                                        required
                                        id="filled-search"
                                        fullWidth
                                        size='small'
                                        placeholder="Code"
                                    />
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Reference Code</Box>
                                    <TextField
                                        required
                                        id="filled-search"
                                        fullWidth
                                        size='small'
                                        placeholder="Reference Code"
                                    />
                                </Grid>

                                <Grid item xs={12} md={3}></Grid>
                                <Grid item xs={12} md={3}></Grid>


                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>First Name</Box>
                                    <TextField
                                        required
                                        id="filled-search"
                                        fullWidth
                                        size='small'
                                        placeholder="First Name"
                                    />
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Middle Name</Box>
                                    <TextField
                                        required
                                        id="filled-search"
                                        fullWidth
                                        size='small'
                                        placeholder="Middle Name"
                                    />
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Last Name</Box>
                                    <TextField
                                        required
                                        id="filled-search"
                                        fullWidth
                                        size='small'
                                        placeholder="Last Name"
                                    />
                                </Grid>

                                <Grid item xs={12} md={3}></Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Date of Birth</Box>
                                    <FormControl fullWidth>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoItem >
                                                <DatePicker />
                                            </DemoItem>
                                        </LocalizationProvider>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Gender</Box>
                                    <FormControl fullWidth>
                                        <Select
                                             name="Gender"
                                             value={state.Gender}
                                             onChange={handleChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem sx={{ ml: 3 }} value="">
                                                <>-Select-</>
                                            </MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={10}>Ten</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={20}>Twenty</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={30}>Thirty</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Employee Status</Box>
                                    <FormControl fullWidth>
                                        <Select
                                             name="EmployeeStatus"
                                             value={state.EmployeeStatus}
                                             onChange={handleChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem sx={{ ml: 3 }} value="">
                                                <>-Select-</>
                                            </MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={10}>Ten</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={20}>Twenty</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={30}>Thirty</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} md={3}></Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Work Location</Box>
                                    <FormControl fullWidth>
                                        <Select
                                             name="WorkLocation"
                                             value={state.WorkLocation}
                                             onChange={handleChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem sx={{ ml: 3 }} value="">
                                                <>-Select-</>
                                            </MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={10}>Ten</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={20}>Twenty</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={30}>Thirty</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <FormControlLabel
                                        value="end"
                                        control={<Checkbox />}
                                        label="BirthDate Unknown"
                                        labelPlacement="end"
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}></Grid>
                                <Grid item xs={12} md={3}></Grid>

                                <Grid item xs={12}>
                                    <Alert variant="soft" sx={[styles.color, styles.fwBold, { backgroundColor: 'lightgray' }]}>Job Detail</Alert>
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Date of Joining</Box>
                                    <FormControl fullWidth>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoItem >
                                                <DatePicker />
                                            </DemoItem>
                                        </LocalizationProvider>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Department</Box>
                                    <FormControl fullWidth>
                                        <Select
                                             name="Department"
                                             value={state.Department}
                                             onChange={handleChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem sx={{ ml: 3 }} value="">
                                                <>-Select-</>
                                            </MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={10}>Ten</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={20}>Twenty</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={30}>Thirty</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Designation</Box>
                                    <TextField
                                        required
                                        id="filled-search"
                                        fullWidth
                                        size='small'
                                    />
                                </Grid>

                                <Grid item xs={12} md={3}></Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Employee Type Group</Box>
                                    <FormControl fullWidth>
                                        <Select
                                            name="EmployeeTypeGroup"
                                            value={state.EmployeeTypeGroup}
                                            onChange={handleChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem sx={{ ml: 3 }} value="">
                                                <>-Select-</>
                                            </MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={10}>Ten</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={20}>Twenty</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={30}>Thirty</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Employee Type</Box>
                                    <FormControl fullWidth>
                                        <Select
                                             name="EmployeeType"
                                             value={state.EmployeeType}
                                             onChange={handleChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem sx={{ ml: 3 }} value="">
                                                <>-Select-</>
                                            </MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={10}>Ten</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={20}>Twenty</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={30}>Thirty</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Employee Sub Type</Box>
                                    <FormControl fullWidth>
                                        <Select
                                             name="EmployeeSubType"
                                             value={state.EmployeeSubType}
                                             onChange={handleChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem sx={{ ml: 3 }} value="">
                                                <>-Select-</>
                                            </MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={10}>Ten</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={20}>Twenty</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={30}>Thirty</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} md={3}></Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Grade</Box>
                                    <FormControl fullWidth>
                                        <Select
                                             name="Grade"
                                             value={state.Grade}
                                             onChange={handleChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem sx={{ ml: 3 }} value="">
                                                <>-Select-</>
                                            </MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={10}>Ten</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={20}>Twenty</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={30}>Thirty</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Company</Box>
                                    <FormControl fullWidth>
                                        <Select
                                             name="Company"
                                             value={state.Company}
                                             onChange={handleChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem sx={{ ml: 3 }} value="">
                                                <>-Select-</>
                                            </MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={10}>Ten</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={20}>Twenty</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={30}>Thirty</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Contractor SubBusiness</Box>
                                    <FormControl fullWidth>
                                        <Select
                                             name="ContractorSubBusiness"
                                             value={state.ContractorSubBusiness}
                                             onChange={handleChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem sx={{ ml: 3 }} value="">
                                                <>-Select-</>
                                            </MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={10}>Ten</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={20}>Twenty</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={30}>Thirty</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} md={3}></Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Target Group</Box>
                                    <FormControl fullWidth>
                                        <Select
                                             name="TargetGroup"
                                             value={state.TargetGroup}
                                             onChange={handleChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem sx={{ ml: 3 }} value="">
                                                <>-Select-</>
                                            </MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={10}>Ten</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={20}>Twenty</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={30}>Thirty</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Process Type</Box>
                                    <FormControl fullWidth>
                                        <Select
                                             name="ProcessType"
                                             value={state.ProcessType}
                                             onChange={handleChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem sx={{ ml: 3 }} value="">
                                                <>-Select-</>
                                            </MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={10}>Ten</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={20}>Twenty</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={30}>Thirty</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Skill</Box>
                                    <FormControl fullWidth>
                                        <Select
                                             name="Skill"
                                             value={state.Skill}
                                             onChange={handleChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem sx={{ ml: 3 }} value="">
                                                <>-Select-</>
                                            </MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={10}>Ten</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={20}>Twenty</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={30}>Thirty</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} md={3}></Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Employee Group</Box>
                                    <FormControl fullWidth>
                                        <Select
                                            name="EmployeeGroup"
                                            value={state.EmployeeGroup}
                                            onChange={handleChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem sx={{ ml: 3 }} value="">
                                                <>-Select-</>
                                            </MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={10}>Ten</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={20}>Twenty</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={30}>Thirty</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Employee Group Code</Box>
                                    <TextField
                                        required
                                        id="filled-search"
                                        fullWidth
                                        size='small'
                                        placeholder='Code'
                                    />
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Legal Entity</Box>
                                    <FormControl fullWidth>
                                        <Select
                                            name="LegalEntity"
                                            value={state.LegalEntity}
                                            onChange={handleChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem sx={{ ml: 3 }} value="">
                                                <>-Select-</>
                                            </MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={10}>Ten</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={20}>Twenty</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={30}>Thirty</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} md={3}></Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Job Title</Box>
                                    <FormControl fullWidth>
                                        <Select
                                             name="JobTitle"
                                             value={state.JobTitle}
                                             onChange={handleChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem sx={{ ml: 3 }} value="">
                                                <>-Select-</>
                                            </MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={10}>Ten</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={20}>Twenty</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={30}>Thirty</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Madical Coverage</Box>
                                    <FormControl fullWidth>
                                        <Select
                                             name="MadicalCoverage"
                                             value={state.MadicalCoverage}
                                             onChange={handleChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem sx={{ ml: 3 }} value="">
                                                <>-Select-</>
                                            </MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={10}>Ten</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={20}>Twenty</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={30}>Thirty</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Row Material Used</Box>
                                    <TextField
                                        required
                                        id="filled-search"
                                        fullWidth
                                        size='small'
                                    />
                                </Grid>

                                <Grid item xs={12} md={3}></Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Hazardous Process</Box>
                                    <TextField
                                        required
                                        id="filled-search"
                                        fullWidth
                                        size='small'
                                    />
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Dangerous Process</Box>
                                    <TextField
                                        required
                                        id="filled-search"
                                        fullWidth
                                        size='small'
                                    />
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Contractor</Box>
                                    <TextField
                                        required
                                        id="filled-search"
                                        fullWidth
                                        size='small'
                                    />
                                </Grid>

                                <Grid item xs={12} md={3}></Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Active Upto</Box>
                                    <FormControl fullWidth>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoItem >
                                                <DatePicker />
                                            </DemoItem>
                                        </LocalizationProvider>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>EPF Number</Box>
                                    <TextField
                                        required
                                        id="filled-search"
                                        fullWidth
                                        size='small'
                                    />
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>ESI Number</Box>
                                    <TextField
                                        required
                                        id="filled-search"
                                        fullWidth
                                        size='small'
                                    />
                                </Grid>

                                <Grid item xs={12} md={3}></Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Reporting Manager</Box>
                                    <FormControl fullWidth>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoItem >
                                                <DatePicker />
                                            </DemoItem>
                                        </LocalizationProvider>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Job Description</Box>
                                    <TextField
                                        required
                                        id="filled-search"
                                        fullWidth
                                        size='small'
                                    />
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <FormControlLabel
                                        value="end"
                                        control={<Checkbox />}
                                        label="Working in Shift"
                                        labelPlacement="end"
                                    />
                                </Grid>

                                <Grid item xs={12} md={3}></Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Reporting Manager</Box>
                                    <TextField
                                        required
                                        id="filled-search"
                                        fullWidth
                                        size='small'
                                    />
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Job Description</Box>
                                    <TextField
                                        required
                                        id="filled-search"
                                        fullWidth
                                        size='small'
                                    />
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Medical Date</Box>
                                    <FormControl fullWidth>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoItem >
                                                <DatePicker />
                                            </DemoItem>
                                        </LocalizationProvider>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Designated Position</Box>
                                    <FormControl fullWidth>
                                        <Select
                                             name="DesignatedPosition"
                                             value={state.DesignatedPosition}
                                             onChange={handleChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem sx={{ ml: 3 }} value="">
                                                <>-Select-</>
                                            </MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={10}>Ten</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={20}>Twenty</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={30}>Thirty</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12}>
                                    <Alert variant="soft" sx={[styles.color, styles.fwBold, { backgroundColor: 'lightgray' }]}>Personal Detail</Alert>
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>UID Number</Box>
                                    <TextField
                                        required
                                        id="filled-search"
                                        fullWidth
                                        size='small'
                                    />
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Age</Box>
                                    <TextField
                                        required
                                        id="filled-search"
                                        fullWidth
                                        size='small'
                                    />
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Color Vision</Box>
                                    <FormControl fullWidth>
                                        <Select
                                             name="ColorVision"
                                             value={state.ColorVision}
                                             onChange={handleChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem sx={{ ml: 3 }} value="">
                                                <>-Select-</>
                                            </MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={10}>Ten</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={20}>Twenty</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={30}>Thirty</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} md={3}></Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Blood Group</Box>
                                    <FormControl fullWidth>
                                        <Select
                                            name="BloodGroup"
                                            value={state.BloodGroup}
                                            onChange={handleChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem sx={{ ml: 3 }} value="">
                                                <>-Select-</>
                                            </MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={10}>Ten</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={20}>Twenty</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={30}>Thirty</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Marital Status</Box>
                                    <FormControl fullWidth>
                                        <Select
                                             name="MaritalStatus"
                                             value={state.MaritalStatus}
                                             onChange={handleChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem sx={{ ml: 3 }} value="">
                                                <>-Select-</>
                                            </MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={10}>Ten</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={20}>Twenty</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={30}>Thirty</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Religion</Box>
                                    <FormControl fullWidth>
                                        <Select
                                            name="Religion"
                                            value={state.Religion}
                                            onChange={handleChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem sx={{ ml: 3 }} value="">
                                                <>-Select-</>
                                            </MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={10}>Ten</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={20}>Twenty</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={30}>Thirty</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} md={3}></Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Qualification</Box>
                                    <FormControl fullWidth>
                                        <Select
                                             name="Qualification"
                                             value={state.Qualification}
                                             onChange={handleChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem sx={{ ml: 3 }} value="">
                                                <>-Select-</>
                                            </MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={10}>Ten</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={20}>Twenty</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={30}>Thirty</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>ID Mark1</Box>
                                    <TextField
                                        required
                                        id="filled-search"
                                        fullWidth
                                        size='small'
                                    />
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>ID Mark2</Box>
                                    <TextField
                                        required
                                        id="filled-search"
                                        fullWidth
                                        size='small'
                                    />
                                </Grid>

                                <Grid item xs={12} md={3}></Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Family Doctor</Box>
                                    <TextField
                                        required
                                        id="filled-search"
                                        fullWidth
                                        size='small'
                                    />
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Family Doctor Contact</Box>
                                    <TextField
                                        required
                                        id="filled-search"
                                        fullWidth
                                        size='small'
                                    />
                                </Grid>

                                <Grid item xs={12} md={3}></Grid>
                                <Grid item xs={12} md={3}></Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Family Doctor Address 1</Box>
                                    <TextField
                                        required
                                        id="filled-search"
                                        fullWidth
                                        size='small'
                                    />
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Family Doctor Address 2</Box>
                                    <TextField
                                        required
                                        id="filled-search"
                                        fullWidth
                                        size='small'
                                    />
                                </Grid>


                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Family Doctor Address 3</Box>
                                    <TextField
                                        required
                                        id="filled-search"
                                        fullWidth
                                        size='small'
                                    />
                                </Grid>

                                <Grid item xs={12} md={3}></Grid>

                                <Grid item xs={12} md={3}>
                                    <FormControlLabel
                                        value="end"
                                        control={<Checkbox />}
                                        label="Disability"
                                        labelPlacement="end"
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}></Grid>

                                <Grid item xs={12} md={3}>
                                    <TextField
                                        required
                                        id="filled-search"
                                        fullWidth
                                        size='small'
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}></Grid>

                                <Grid item xs={12} md={3}>
                                    <FormControlLabel
                                        value="end"
                                        control={<Checkbox />}
                                        label="Disability Certified"
                                        labelPlacement="end"
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}></Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        required
                                        id="filled-search"
                                        fullWidth
                                        size='small'
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}></Grid>

                                <Grid item xs={12} md={3}>
                                    <FormControlLabel
                                        value="end"
                                        control={<Checkbox />}
                                        label="First Aid Training"
                                        labelPlacement="end"
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>First Aid Training Date</Box>
                                    <FormControl fullWidth>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoItem >
                                                <DatePicker />
                                            </DemoItem>
                                        </LocalizationProvider>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <FormControlLabel
                                        value="end"
                                        control={<Checkbox />}
                                        label="Fire Training"
                                        labelPlacement="end"
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Fire Training Date</Box>
                                    <FormControl fullWidth>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoItem >
                                                <DatePicker />
                                            </DemoItem>
                                        </LocalizationProvider>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Random First Aid Date</Box>
                                    <FormControl fullWidth>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoItem >
                                                <DatePicker />
                                            </DemoItem>
                                        </LocalizationProvider>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>First Aid Training Due Date</Box>
                                    <FormControl fullWidth>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoItem >
                                                <DatePicker />
                                            </DemoItem>
                                        </LocalizationProvider>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} md={3}></Grid>
                                <Grid item xs={12} md={3}></Grid>

                                <Grid item xs={12}>
                                    <Alert variant="soft" sx={[styles.color, styles.fwBold, { backgroundColor: 'lightgray' }]}>Communication Detail</Alert>
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Company Phone No</Box>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        required
                                        id="filled-search"
                                        fullWidth
                                        size='small'
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}></Grid>
                                <Grid item xs={12} md={3}></Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Extension No</Box>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        required
                                        id="filled-search"
                                        fullWidth
                                        size='small'
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}></Grid>
                                <Grid item xs={12} md={3}></Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Company Email</Box>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        required
                                        id="filled-search"
                                        fullWidth
                                        size='small'
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    (Used for getting Email Alert)
                                </Grid>
                                <Grid item sxs={12} md={3}></Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Personal Email</Box>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        required
                                        id="filled-search"
                                        fullWidth
                                        size='small'
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}></Grid>
                                <Grid item xs={12} md={3}></Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Mobile Number</Box>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        required
                                        id="filled-search"
                                        fullWidth
                                        size='small'
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Box>(Used for getting SMS Alert)</Box>
                                </Grid>
                                <Grid item xs={12} md={3}></Grid>

                                <Grid item xs={12}>
                                    <Box sx={{ backgroundColor: 'lightgray', p: 1.5, borderRadius: 1 }}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} md={3}></Grid>
                                            <Grid item xs={12} md={3}>
                                                <Box>Local Address (LA)</Box>
                                            </Grid>
                                            <Grid item xs={12} md={3}>
                                                <Box sx={{ display: 'flex', alignItems: 'center' }}>Permanent Address <FileCopyOutlinedIcon sx={{ ml: 2, fontSize: 18 }} /></Box>
                                            </Grid>
                                            <Grid item xs={12} md={3}>
                                                <Box sx={{ display: 'flex', alignItems: 'center' }}>Emergency Address <FileCopyOutlinedIcon sx={{ ml: 2, fontSize: 18 }} /></Box>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Address 1</Box>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        required
                                        id="filled-search"
                                        fullWidth
                                        size='small'
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        required
                                        id="filled-search"
                                        fullWidth
                                        size='small'
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        required
                                        id="filled-search"
                                        fullWidth
                                        size='small'
                                    />
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Address 2</Box>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        required
                                        id="filled-search"
                                        fullWidth
                                        size='small'
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        required
                                        id="filled-search"
                                        fullWidth
                                        size='small'
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        required
                                        id="filled-search"
                                        fullWidth
                                        size='small'
                                    />
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Address 3</Box>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        required
                                        id="filled-search"
                                        fullWidth
                                        size='small'
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        required
                                        id="filled-search"
                                        fullWidth
                                        size='small'
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        required
                                        id="filled-search"
                                        fullWidth
                                        size='small'
                                    />
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>City</Box>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        required
                                        id="filled-search"
                                        fullWidth
                                        size='small'
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        required
                                        id="filled-search"
                                        fullWidth
                                        size='small'
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        required
                                        id="filled-search"
                                        fullWidth
                                        size='small'
                                    />
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>State</Box>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        required
                                        id="filled-search"
                                        fullWidth
                                        size='small'
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        required
                                        id="filled-search"
                                        fullWidth
                                        size='small'
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        required
                                        id="filled-search"
                                        fullWidth
                                        size='small'
                                    />
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Country</Box>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        required
                                        id="filled-search"
                                        fullWidth
                                        size='small'
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        required
                                        id="filled-search"
                                        fullWidth
                                        size='small'
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        required
                                        id="filled-search"
                                        fullWidth
                                        size='small'
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Pin Code</Box>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        required
                                        id="filled-search"
                                        fullWidth
                                        size='small'
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        required
                                        id="filled-search"
                                        fullWidth
                                        size='small'
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        required
                                        id="filled-search"
                                        fullWidth
                                        size='small'
                                    />
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Contact No</Box>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        required
                                        id="filled-search"
                                        fullWidth
                                        size='small'
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        required
                                        id="filled-search"
                                        fullWidth
                                        size='small'
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        required
                                        id="filled-search"
                                        fullWidth
                                        size='small'
                                    />
                                </Grid>
                            </Grid>
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={1}>

                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={2}>

                        </CustomTabPanel>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}