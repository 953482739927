import sharedImports from '../../../Comman/sharedImports';
const {
    React,
    PropTypes,
    Box,
    Link,
    Grid,
    Slide,
    ArrowBackIcon,
    AppBar,
    Button,
    Tooltip,
    AddCircleOutlineOutlinedIcon,
    IconButton,
    Toolbar,
    EditNoteOutlinedIcon,
    Typography,
    Select,
    TextField,
    FileCopyOutlinedIcon,
    CssBaseline,
    FormControl,
    useScrollTrigger,
    MenuItem,
    styled,
    UnfoldMoreOutlinedIcon,
    TableBody,
    CancelOutlinedIcon,
    TableContainer,
    TableCell,
    Paper,
    tableCellClasses,
    Table,
    TableHead,
    TableRow,
} = sharedImports;


const drawerWidth = 300;
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));
const reportListRows = [
    { assetTag: 'AM22055', assetName: 'Glucometer', assetCategory: 'Glucometer', nextMaintenenceDate: '27/01/2022', renewNextDate: 'NA', usageStatus: 'Working', specification: null, accessories: null, modelNumber: null, purchaseCost: 0 },
    { assetTag: 'AM22055', assetName: 'Glucometer', assetCategory: 'Glucometer', nextMaintenenceDate: '27/01/2022', renewNextDate: 'NA', usageStatus: 'Working', specification: null, accessories: null, modelNumber: null, purchaseCost: 0 },
    { assetTag: 'AM22055', assetName: 'Glucometer', assetCategory: 'Glucometer', nextMaintenenceDate: '27/01/2022', renewNextDate: 'NA', usageStatus: 'Working', specification: null, accessories: null, modelNumber: null, purchaseCost: 0 },
    { assetTag: 'AM22055', assetName: 'Glucometer', assetCategory: 'Glucometer', nextMaintenenceDate: '27/01/2022', renewNextDate: 'NA', usageStatus: 'Working', specification: null, accessories: null, modelNumber: null, purchaseCost: 0 },
    { assetTag: 'AM22055', assetName: 'Glucometer', assetCategory: 'Glucometer', nextMaintenenceDate: '27/01/2022', renewNextDate: 'NA', usageStatus: 'Working', specification: null, accessories: null, modelNumber: null, purchaseCost: 0 },
    { assetTag: 'AM22055', assetName: 'Glucometer', assetCategory: 'Glucometer', nextMaintenenceDate: '27/01/2022', renewNextDate: 'NA', usageStatus: 'Working', specification: null, accessories: null, modelNumber: null, purchaseCost: 0 },
    { assetTag: 'AM22055', assetName: 'Glucometer', assetCategory: 'Glucometer', nextMaintenenceDate: '27/01/2022', renewNextDate: 'NA', usageStatus: 'Working', specification: null, accessories: null, modelNumber: null, purchaseCost: 0 },
    { assetTag: 'AM22055', assetName: 'Glucometer', assetCategory: 'Glucometer', nextMaintenenceDate: '27/01/2022', renewNextDate: 'NA', usageStatus: 'Working', specification: null, accessories: null, modelNumber: null, purchaseCost: 0 },
    { assetTag: 'AM22055', assetName: 'Glucometer', assetCategory: 'Glucometer', nextMaintenenceDate: '27/01/2022', renewNextDate: 'NA', usageStatus: 'Working', specification: null, accessories: null, modelNumber: null, purchaseCost: 0 },
    { assetTag: 'AM22055', assetName: 'Glucometer', assetCategory: 'Glucometer', nextMaintenenceDate: '27/01/2022', renewNextDate: 'NA', usageStatus: 'Working', specification: null, accessories: null, modelNumber: null, purchaseCost: 0 },
];
function HideOnScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
};
const styles = {
    TableRow: {
        th: {
            border: '1px solid #a3a3a3',
            padding: '5px',
            svg: {
                float: 'right',
                color: '#1976d2'
            },
        },
        td: {
            padding: '5px',
            border: '1px solid lightgray',
        }
    },
    h: {
        minHeight: '40px !important'
    },
    bgWhite: {
        bgcolor: 'white',
        color: 'black'
    }
}

export default function AssetMaster(props) {

    const [age, setAge] = React.useState('');
    function handleChange(event) {
        setAge(event.target.value);
    }

    return (
        <>
            <React.Fragment>
                <Box>
                    <CssBaseline />
                    <HideOnScroll {...props}>
                        <AppBar
                            sx={{
                                width: { sm: `calc(100% - ${drawerWidth}px)` },
                                ml: { sm: `${drawerWidth}px` },
                                mt: { xs: '53px', sm: '64px' },
                                zIndex: 1099
                            }}
                            elevation={1}
                        >
                            <Toolbar sx={[styles.h, styles.bgWhite]}>
                                <Grid container>
                                    <Grid item xs={12} md={10}>
                                        <Typography variant="h6" sx={{ fontWeight: 'bold' }} noWrap component="div">
                                            Asset Master
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                        <Link to='/dashboard/support/asset-management/asset-management-master-setup/asset-master/create'>
                                            <Button variant='contained' startIcon={<AddCircleOutlineOutlinedIcon />} sx={{ mr: 1, bgcolor: 'gray', textTransform: 'none' }}> Add </Button>
                                        </Link>
                                        <Button variant='outlined' sx={{ textTransform: 'none' }} startIcon={<ArrowBackIcon />}> Back </Button>
                                    </Grid>
                                </Grid>
                            </Toolbar>
                        </AppBar>
                    </HideOnScroll>
                    <Box sx={{ mt: 7, mb: 2, mx: 2, bgcolor: 'white', p: 3, borderRadius: 1 }}>
                        <Box component="div" xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Asset Code  <Box component='span' sx={{ color: 'red' }}>*</Box></Box>
                                    <Grid item xs={12} md={11}>
                                        <TextField
                                            required
                                            id="filled-search"
                                            fullWidth
                                            size='small'
                                            placeholder="Asset Code"
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Asset Name  <Box component='span' sx={{ color: 'red' }}>*</Box></Box>
                                    <Grid item xs={12} md={11}>
                                        <TextField
                                            required
                                            id="filled-search"
                                            fullWidth
                                            size='small'
                                            placeholder="Asset Name"
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Status</Box>
                                    <Grid item xs={12} md={11}>
                                        <FormControl fullWidth>
                                            <Select
                                                value={age}
                                                onChange={handleChange}
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Without label' }}
                                            >
                                                <MenuItem sx={{ ml: 3 }} value="">
                                                    <>-All-</>
                                                </MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={1}>BP Instrument- First-aid room</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={2}>ECG</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={3}>Oxygen Concentrator</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={3}>Concentrator</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={3}>Infusion pump</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={3}>Thermal Gun</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={3}>Glucometer</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={3}>Two para oximeter</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Source Type</Box>
                                    <Grid item xs={12} md={11}>
                                        <FormControl fullWidth>
                                            <Select
                                                value={age}
                                                onChange={handleChange}
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Without label' }}
                                            >
                                                <MenuItem sx={{ ml: 3 }} value="">
                                                    <>-All-</>
                                                </MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={1}>BP Instrument- First-aid room</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={2}>ECG</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={3}>Oxygen Concentrator</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={3}>Concentrator</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={3}>Infusion pump</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={3}>Thermal Gun</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={3}>Glucometer</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={3}>Two para oximeter</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} sx={{ mb: 3 }}>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Source Name</Box>
                                    <Grid item xs={12} md={11}>
                                        <FormControl fullWidth>
                                            <Select
                                                value={age}
                                                onChange={handleChange}
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Without label' }}
                                            >
                                                <MenuItem sx={{ ml: 3 }} value="">
                                                    <>-All-</>
                                                </MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={1}>BP Instrument- First-aid room</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={2}>ECG</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={3}>Oxygen Concentrator</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={3}>Concentrator</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={3}>Infusion pump</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={3}>Thermal Gun</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={3}>Glucometer</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={3}>Two para oximeter</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Asset Code <Box component='span' sx={{ color: 'red' }}>*</Box></Box>
                                    <Grid item xs={12} md={11}>
                                        <TextField
                                            required
                                            id="filled-search"
                                            fullWidth
                                            size='small'
                                            placeholder="Asset Code"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={4}></Grid>
                                <Grid item xs={12} md={6}>
                                    <Button variant='contained' startIcon={<AddCircleOutlineOutlinedIcon />} sx={{ mr: 2, textTransform: 'none' }}>Add</Button>
                                    <Button variant='outlined' startIcon={<CancelOutlinedIcon />} sx={{ mr: 2, textTransform: 'none' }}>Clear</Button>
                                    <Button variant='contained' sx={{ textTransform: 'none' }} startIcon={<FileCopyOutlinedIcon />}>Export</Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                    <Box sx={{ mt: 2, mb: 7, mx: 2, bgcolor: 'white', p: 3, borderRadius: 1 }}>
                        <Box component="div" xs={12}>
                            <Grid container spacing={2} direction='row' xm={12} sm={12} md={12} lg={12} xl={12} justifyContent='space-between' sx={{ mb: 2 }}>
                                <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Grid item sx={{ mr: 2, display: 'flex', alignItems: 'center' }}>
                                        <Box component='span' sx={{ fontWeight: 'bold' }}>Show </Box>
                                        <TextField
                                            id="outlined-number"
                                            type="number"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            defaultValue='10'
                                            sx={{ width: '9ch' }}
                                            size='small'
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Box component='span' sx={{ fontWeight: 'bold' }}>entries </Box>
                                        <Button variant='outlined' sx={{ textTransform: 'none' }} startIcon={<FileCopyOutlinedIcon />}>Export</Button>
                                    </Grid>
                                </Grid>
                                <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Box component='span' sx={{ fontWeight: 'bold' }}>Search: </Box>
                                    <TextField
                                        id="filled-search"
                                        type="search"
                                        size='small'
                                    />
                                </Grid>
                            </Grid>
                            <TableContainer component={Paper} sx={{ mb: 2 }}>
                                <Table aria-label="customized table">
                                    <TableHead>
                                        <TableRow sx={styles.TableRow}>
                                            <StyledTableCell><UnfoldMoreOutlinedIcon /></StyledTableCell>
                                            <StyledTableCell>Asset Tag<UnfoldMoreOutlinedIcon /></StyledTableCell>
                                            <StyledTableCell>Asset Name<UnfoldMoreOutlinedIcon /></StyledTableCell>
                                            <StyledTableCell>Asset Category<UnfoldMoreOutlinedIcon /></StyledTableCell>
                                            <StyledTableCell>Next Maintenence Date<UnfoldMoreOutlinedIcon /></StyledTableCell>
                                            <StyledTableCell>Renew Next Date<UnfoldMoreOutlinedIcon /></StyledTableCell>
                                            <StyledTableCell>Usage Status<UnfoldMoreOutlinedIcon /></StyledTableCell>
                                            <StyledTableCell>Specification<UnfoldMoreOutlinedIcon /></StyledTableCell>
                                            <StyledTableCell>Accessories<UnfoldMoreOutlinedIcon /></StyledTableCell>
                                            <StyledTableCell>Model Number<UnfoldMoreOutlinedIcon /></StyledTableCell>
                                            <StyledTableCell>Purchase Cost<UnfoldMoreOutlinedIcon /></StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {reportListRows.map((row) => (
                                            <StyledTableRow key={row.assetTag} sx={styles.TableRow}>
                                                <StyledTableCell>
                                                    <Tooltip title="Edit">
                                                        <IconButton sx={{ p: 0 }}>
                                                            <EditNoteOutlinedIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                </StyledTableCell>
                                                <StyledTableCell>{row.assetTag}</StyledTableCell>
                                                <StyledTableCell>{row.assetName}</StyledTableCell>
                                                <StyledTableCell>{row.assetCategory}</StyledTableCell>
                                                <StyledTableCell>{row.nextMaintenenceDate}</StyledTableCell>
                                                <StyledTableCell>{row.renewNextDate}</StyledTableCell>
                                                <StyledTableCell>{row.usageStatus}</StyledTableCell>
                                                <StyledTableCell>{row.specification}</StyledTableCell>
                                                <StyledTableCell>{row.accessories}</StyledTableCell>
                                                <StyledTableCell>{row.modelNumber}</StyledTableCell>
                                                <StyledTableCell>{row.purchaseCost}</StyledTableCell>
                                            </StyledTableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Grid container spacing={2} direction='row' xm={12} sm={12} md={12} lg={12} xl={12} justifyContent='space-between'>
                                <Grid item sx={{ fontWeight: 'bold' }}>
                                    Showing 0 to 0 of 0 entries
                                </Grid>
                                <Grid item>
                                    <Button variant='contained' sx={{ mr: 2, textTransform: 'none' }} disabled>Prev</Button>
                                    <Button variant='contained' sx={{ textTransform: 'none' }} disabled>Next</Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Box>
            </React.Fragment>
        </>
    )
}