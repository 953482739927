import sharedImports from '../../../component/Comman/sharedImports';
const {
    React,
    PropTypes,
    Box,
    Link,
    Grid,
    Slide,
    AppBar,
    Paper,
    Button,
    Toolbar,
    Typography,
    IosShareIcon,
    CssBaseline,
    useScrollTrigger,
} = sharedImports;


const drawerWidth = 300;
function HideOnScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
};
const styles = {
    TableRow: {
        th: {
            p: 1,
            svg: {
                float: 'right',
                color: '#1976d2'
            },
        },
        td: {
            border: '1px solid lightgray',
            p: 1,
        }
    },
    h: {
        minHeight: '40px !important'
    },
    bgWhite: {
        bgcolor: 'white',
        color: 'black'
    }
}


export default function AmbulanceMasterSetup(props) {

    return (
        <>
            <React.Fragment>
                <Box>
                    <CssBaseline />
                    <HideOnScroll {...props}>
                        <AppBar
                            sx={{
                                width: { sm: `calc(100% - ${drawerWidth}px)` },
                                ml: { sm: `${drawerWidth}px` },
                                mt: { xs: '53px', sm: '64px' },
                                zIndex: 1099
                            }}
                            elevation={1}
                        >
                            <Toolbar sx={[styles.h, styles.bgWhite]}>
                                <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Grid item>
                                        <Typography variant="h6" noWrap component="div" sx={{ m: 1, fontWeight: 'bold' }}>
                                            Appointment Master Setup
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Toolbar>
                        </AppBar>
                    </HideOnScroll>
                    <Box sx={{ mt: 8, mb: 2, mx: 2, p: 3, borderRadius: 1 }}>
                        <Box component="div" xs={12}>
                            <Grid container spacing={2} sx={{ mb: 2 }}>
                                <Grid item xs={12} md={3}>
                                    <Paper elevation={3} sx={{ p: 1 }}>
                                        <Link to='/dashboard/appointment-module/appointment-master-setup/patient-register-analysis-add'>
                                            <Button href="/" sx={{ fontWeight: 'bold', textTransform: 'none' }} startIcon={<IosShareIcon />}>Visiting Doctor Setup</Button>
                                        </Link>
                                    </Paper>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Paper elevation={3} sx={{ p: 1 }}>
                                        <Link to='/dashboard/appointment-module/appointment-master-setup/visiting-doctor-master'>
                                            <Button href="/" sx={{ fontWeight: 'bold', textTransform: 'none' }} startIcon={<IosShareIcon />}>Time Slot Master</Button>
                                        </Link>
                                    </Paper>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Paper elevation={3} sx={{ p: 1 }}>
                                        <Link to='/dashboard/appointment-module/appointment-master-setup/time-slot-setup'>
                                            <Button href="/" sx={{ fontWeight: 'bold', textTransform: 'none' }} startIcon={<IosShareIcon />}>Time Slot Setup</Button>
                                        </Link>
                                    </Paper>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Paper elevation={3} sx={{ p: 1 }}>
                                        <Link to='/dashboard/appointment-module/appointment-master-setup/appointment-setup'>
                                            <Button href="/" sx={{ fontWeight: 'bold', textTransform: 'none' }} startIcon={<IosShareIcon />}>Appointment Setup</Button>
                                        </Link>
                                    </Paper>
                                </Grid>
                            </Grid>

                        </Box>
                    </Box>
                </Box>
            </React.Fragment>
        </>
    )
}