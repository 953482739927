import sharedImports from '../../../component/Comman/sharedImports';
const {
    React,
    AppBar,
    Box,
    Paper,
    Stack,
    Grid,
    Card,
    Table,
    Select,
    styled,
    Button,
    Toolbar,
    DemoItem,
    TableRow,
    MenuItem,
    TableHead,
    DatePicker,
    TableCell,
    TextField,
    Typography,
    FormControl,
    SearchIcon,
    CardContent,
    TableContainer,
    HighlightOffIcon,
    tableCellClasses,
    useScrollTrigger,
    LocalizationProvider,
    Slide,
    AdapterDayjs,
    PropTypes,
    TableBody,

} = sharedImports;


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));
const reportListRows = [
    { Registration: 0, OPDFromRegistration: 0, MissingFromOPD: 0, MisMatchFromRegistration: 0, MissingFromRegistration: 3314, },
];

const drawerWidth = 300;
function HideOnScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
};
const styles = {
    TableRow: {
        th: {
            svg: {
                float: 'right',
                color: '#1976d2'
            },
        },
        td: {
            border: '1px solid lightgray',
        }
    },
    h: {
        minHeight: '40px !important'
    },
    bgWhite: {
        bgcolor: 'white',
        color: 'black'
    }
}

export default function DenseAppBar(props) {
    const [age, setAge] = React.useState('');

    const handleChange = (event) => {
        setAge(event.target.value);
    };
    return (
        <>
            <HideOnScroll {...props}>
                <AppBar
                    sx={{
                        width: { sm: `calc(100% - ${drawerWidth}px)` },
                        ml: { sm: `${drawerWidth}px` },
                        mt: { xs: '53px', sm: '64px' },
                        zIndex: 1099
                    }}
                    elevation={1}
                >
                    <Toolbar sx={[styles.h, styles.bgWhite]}>
                       
                    </Toolbar>
                </AppBar>
            </HideOnScroll>

            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    '& > :not(style)': {
                        m: 2,
                        ml: 4,
                        mr: 4,
                        marginTop: '55px',
                        width: "100%",
                    },
                }}
            >


                <Card variant="outlined" sx={{ padding: 2 }}>
                    <CardContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={3}>
                                <Typography variant="body1">
                                    <Box sx={{ fontWeight: 'bold' }} >Employee Code</Box>
                                </Typography>
                                <Grid item xs={12} md={11}>
                                    <TextField
                                        required
                                        id="filled-search"
                                        fullWidth
                                        size='small'
                                        placeholder='Employee Code'
                                    />
                                </Grid>

                            </Grid>


                            <Grid item xs={12} md={3}>
                                <Typography variant="body1">
                                    <Box sx={{ fontWeight: 'bold' }} >Employee Name</Box>
                                </Typography>
                                <Grid item xs={12} md={11}>
                                    <TextField
                                        required
                                        id="filled-search"
                                        fullWidth
                                        size='small'
                                        placeholder='Employee Name'
                                    />
                                </Grid>

                            </Grid>


                            <Grid item xs={12} md={3}>
                                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Doctor</Typography>
                                <Grid item xs={12} md={11}>
                                    <FormControl fullWidth>
                                        <Select
                                            value={age}
                                            onChange={handleChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem sx={{ ml: 3 }} value="">
                                                <>-Select-</>
                                            </MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={1}>Sukumar Biyardas Das</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={2}>Siddharth Ramanlal Darji</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={3}>Pranij Vora</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={4}>Vijay Ajay Dave</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={4}>Jay Kishorbhai Sheladia</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={4}>Urmik Krupalsinh Chauhan</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} md={3}></Grid>

                            <Grid item xs={12} md={3}>
                                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Examination Type</Typography>
                                <Grid item xs={12} md={11}>
                                    <FormControl fullWidth>
                                        <Select
                                            value={age}
                                            onChange={handleChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem sx={{ ml: 3 }} value="">
                                                <>OPD</>
                                            </MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={1}>-ALL-</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={2}>Periodic</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={3}>Pre Employment</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={4}>Pre Placement</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={4}>Pre Retirement</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={4}>Post Sickness</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={4}>Wellness</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={4}>OPD</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={4}>Post Accident</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>


                            <Grid item xs={12} md={3}>
                                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Round</Typography>
                                <Grid item xs={12} md={11}>
                                    <FormControl fullWidth>
                                        <Select
                                            value={age}
                                            onChange={handleChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem sx={{ ml: 3 }} value="">
                                                <>OPD</>
                                            </MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={1}>Councelling</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={2}>Diabetes Prevention Awareness</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={3}>First Aid Training</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={4}>OBESITY MANAGMENT</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} md={3}>
                                <Box sx={{ fontWeight: 'bold' }}>From Date</Box>
                                <Grid item xs={12} md={11}>
                                    <FormControl fullWidth>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoItem >
                                                <DatePicker />
                                            </DemoItem>
                                        </LocalizationProvider>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} md={3}>
                                <Box sx={{ fontWeight: 'bold' }}>To Date</Box>
                                <Grid item xs={12} md={11}>
                                    <FormControl fullWidth>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoItem >
                                                <DatePicker />
                                            </DemoItem>
                                        </LocalizationProvider>
                                    </FormControl>
                                </Grid>
                            </Grid>

                        </Grid>
                    </CardContent>
                    <Stack spacing={1} direction="row" sx={{ justifyContent: 'center', mb: 2 }}>

                        <Button variant="contained" sx={{ textTransform: 'none', backgroundColor: '#0e559d' }} startIcon={<SearchIcon />}>
                            Search
                        </Button>
                        <Button variant="outlined" sx={{ textTransform: 'none' }} startIcon={<HighlightOffIcon />}>
                            Clear
                        </Button>

                    </Stack>
                </Card>
            </Box>

            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    '& > :not(style)': {
                        m: 1.5,
                        mt: 0,
                        ml: 4,
                        mr: 4,
                        width: "100%",
                    },
                }}
            >


                <Card variant="outlined" sx={{ padding: 2 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12}>
                            <CardContent sx={{ pb: '0px !important', mb: 2 }}>
                                <TableContainer item xs={12} md={3} component={Paper}>
                                    <Table aria-label="customized table">
                                        <TableHead>
                                            <TableRow item xs={12} md={3} sx={styles.TableRow}>
                                                <StyledTableCell sx={{ fontWeight: 'bold' }}>Registration</StyledTableCell>
                                                <StyledTableCell sx={{ fontWeight: 'bold' }}>OPD From Registration</StyledTableCell>
                                                <StyledTableCell sx={{ fontWeight: 'bold' }}>Missing From OPD</StyledTableCell>
                                                <StyledTableCell sx={{ fontWeight: 'bold' }}>MisMatch From Registration</StyledTableCell>
                                                <StyledTableCell sx={{ fontWeight: 'bold' }}>Missing From Registration</StyledTableCell>
                                                <StyledTableCell></StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {reportListRows.map((row) => (
                                                <StyledTableRow item xs={12} md={3} key={row.Registration} sx={styles.TableRow}>
                                                    <StyledTableCell> {row.Registration} </StyledTableCell>
                                                    <StyledTableCell>{row.OPDFromRegistration}</StyledTableCell>
                                                    <StyledTableCell>{row.MissingFromOPD}</StyledTableCell>
                                                    <StyledTableCell>{row.MisMatchFromRegistration}</StyledTableCell>
                                                    <StyledTableCell>{row.MissingFromRegistration}</StyledTableCell>
                                                </StyledTableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </CardContent>
                        </Grid>
                    </Grid>
                </Card>
            </Box>


        </>
    )
}