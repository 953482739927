// sharedImports.js
import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import Radio from '@mui/material/Radio';
import { Link } from "react-router-dom";
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import Paper from '@mui/material/Paper';
import Slide from '@mui/material/Slide';
import Table from '@mui/material/Table';
import Select from '@mui/material/Select';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Divider from '@mui/material/Divider';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import { useMediaQuery } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import TableRow from '@mui/material/TableRow';
import AddIcon from '@mui/icons-material/Add';
import TextField from '@mui/material/TextField';
import FormLabel from '@mui/material/FormLabel';
import { Height } from '@mui/icons-material';
import PhoneIcon from '@mui/icons-material/Phone';
import SaveIcon from '@mui/icons-material/Save';
import {Card, CardContent,} from '@mui/material';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import EastIcon from '@mui/icons-material/East';
import WestIcon from '@mui/icons-material/West';
import styled from '@mui/material/styles/styled';
import LoginIcon from '@mui/icons-material/Login';
import RadioGroup from '@mui/material/RadioGroup';
import CheckIcon from '@mui/icons-material/Check';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import HotelIcon from '@mui/icons-material/Hotel';
import IconButton from '@mui/material/IconButton';
import RemoveIcon from '@mui/icons-material/Remove';
import LogoutIcon from '@mui/icons-material/Logout';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import PersonIcon from '@mui/icons-material/Person';
import CssBaseline from '@mui/material/CssBaseline';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import CachedIcon from '@mui/icons-material/Cached';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import SupportIcon from '@mui/icons-material/Support';
import TextsmsIcon from '@mui/icons-material/Textsms';
import { useTheme, alpha } from '@mui/material/styles';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import IosShareIcon from '@mui/icons-material/IosShare';
import ListItemButton from '@mui/material/ListItemButton';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import TableContainer from '@mui/material/TableContainer';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import InputSharpIcon from '@mui/icons-material/InputSharp';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import { DemoItem } from '@mui/x-date-pickers/internals/demo';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import FormControlLabel from '@mui/material/FormControlLabel';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { TreeItem2Icon } from '@mui/x-tree-view/TreeItem2Icon';
import ModeOutlinedIcon from '@mui/icons-material/ModeOutlined';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import DialogContentText from '@mui/material/DialogContentText';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import CachedOutlinedIcon from '@mui/icons-material/CachedOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import SaveAsOutlinedIcon from '@mui/icons-material/SaveAsOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ViewDayOutlinedIcon from '@mui/icons-material/ViewDayOutlined';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import FileUploadSharpIcon from '@mui/icons-material/FileUploadSharp';
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';
import ScienceOutlinedIcon from '@mui/icons-material/ScienceOutlined';
import { TreeItem2Provider } from '@mui/x-tree-view/TreeItem2Provider';
import ScheduleOutlinedIcon from '@mui/icons-material/ScheduleOutlined';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import BackspaceOutlinedIcon from '@mui/icons-material/BackspaceOutlined';
import EqualizerOutlinedIcon from '@mui/icons-material/EqualizerOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import DomainAddOutlinedIcon from '@mui/icons-material/DomainAddOutlined';
import EditCalendarSharpIcon from '@mui/icons-material/EditCalendarSharp';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import UnfoldMoreOutlinedIcon from '@mui/icons-material/UnfoldMoreOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import AutoFixHighOutlinedIcon from '@mui/icons-material/AutoFixHighOutlined';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import PermScanWifiOutlinedIcon from '@mui/icons-material/PermScanWifiOutlined';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import ControlPointOutlinedIcon from '@mui/icons-material/ControlPointOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import ArrowRightAltOutlinedIcon from '@mui/icons-material/ArrowRightAltOutlined';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import { unstable_useTreeItem2 as useTreeItem } from '@mui/x-tree-view/useTreeItem2';
import MedicalServicesOutlinedIcon from '@mui/icons-material/MedicalServicesOutlined';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import InsertChartOutlinedTwoToneIcon from '@mui/icons-material/InsertChartOutlinedTwoTone';
import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined';
import { TreeItem2Content, TreeItem2IconContainer, TreeItem2Root, TreeItem2GroupTransition, } from '@mui/x-tree-view/TreeItem2';




export default {
  Box,
  Tab,
  Tabs,
  Card,
  List,
  clsx,
  Grid,
  Link,
  Stack,
  Radio,
  Alert,
  Radio ,
  Slide,
  Paper,
  Stack,
  alpha,
  Table,
  React,
  Height,
  Divider,
  AppBar,
  Dialog,
  Select,
  styled,
  Button,
  AddIcon,
  Tooltip,
  Toolbar,
  useTheme,
  WestIcon,
  EastIcon,
  SaveIcon,
  TableRow,
  TreeItem,
  DemoItem,
  Checkbox,
  MenuItem,
  PhoneIcon,
  HotelIcon,
  LoginIcon,
  CheckIcon,
  TableBody,
  TableCell,
  FormLabel,
  TextField,
  PropTypes,
  TableHead,
  LogoutIcon,
  RemoveIcon,
  RadioGroup,
  CachedIcon,
  SearchIcon,
  PersonIcon,
  IconButton,
  Typography,
  DatePicker,
  DeleteIcon,
  InputLabel,
  DialogTitle,
  CardContent,
  SupportIcon,
  TextsmsIcon,
  CssBaseline,
  ListItemText,
  FormControl,
  useTreeItem,
  NoteAddIcon,
  IosShareIcon,
  AdapterDayjs,
  FilterAltIcon,
  ListItemIcon,
  DialogActions,
  useMediaQuery,
  UnfoldMoreIcon,
  DialogContent,
  ArrowBackIcon,
  TreeItem2Icon,
  TreeItem2Root,
  ArrowRightIcon,
  TableContainer,
  SimpleTreeView,
  InputSharpIcon,
  RestartAltIcon,
  LocalPhoneIcon,
  ListItemButton,
  CloudUploadIcon,
  ArrowForwardIcon,
  DialogContentText,
  CheckOutlinedIcon,
  CachedOutlinedIcon,
  ModeOutlinedIcon,
  SaveOutlinedIcon,
  useScrollTrigger,
  HighlightOffIcon,
  FormControlLabel,
  TreeItem2Content,
  tableCellClasses,
  PrintOutlinedIcon,
  DeleteOutlinedIcon,
  EmailOutlinedIcon,
  ImageOutlinedIcon,
  ArrowDropDownIcon,
  DeleteOutlineIcon,
  TreeItem2Provider,
  SaveAsOutlinedIcon,
  CancelOutlinedIcon,
  SearchOutlinedIcon,
  ViewDayOutlinedIcon,
  ScienceOutlinedIcon,
  NoteAddOutlinedIcon,
  CheckBoxOutlinedIcon,
  ScheduleOutlinedIcon,
  KeyboardArrowUpIcon,
  FileUploadSharpIcon,
  AddCircleOutlineIcon,
  FileCopyOutlinedIcon,
  EditNoteOutlinedIcon,
  LocalizationProvider,
  BackspaceOutlinedIcon,
  EqualizerOutlinedIcon,
  DomainAddOutlinedIcon,
  KeyboardArrowDownIcon,
  FileUploadOutlinedIcon,
  EditCalendarSharpIcon,
  UnfoldMoreOutlinedIcon,
  VisibilityOutlinedIcon,
  TreeItem2IconContainer,
  FormatListBulletedIcon,
  AutoFixHighOutlinedIcon,
  WorkOutlineOutlinedIcon,
  RemoveRedEyeOutlinedIcon,
  PermScanWifiOutlinedIcon,
  TreeItem2GroupTransition,
  PersonAddAltOutlinedIcon,
  ControlPointOutlinedIcon,
  ArrowRightAltOutlinedIcon,
  ReportProblemOutlinedIcon,
  CalendarTodayOutlinedIcon,
  MedicalServicesOutlinedIcon,
  InsertDriveFileOutlinedIcon,
  AddCircleOutlineOutlinedIcon,
  ArrowCircleRightOutlinedIcon,
  InsertChartOutlinedTwoToneIcon,
  FormatListBulletedOutlinedIcon,
};
