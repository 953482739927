import sharedImports from '../../../Comman/sharedImports';
const {
    React,
    PropTypes,
    Box,
    Grid,
    Slide,
    AppBar,
    Button,
    Toolbar,
    TextField,
    styled,
    Paper,
    TableRow,
    TableContainer,
    TableBody,
    TableHead,
    Table,
    Checkbox,
    Typography,
    CssBaseline,
    useScrollTrigger,
    CancelOutlinedIcon,
    TableCell,
    tableCellClasses,
    FormControlLabel,
    NoteAddOutlinedIcon,
    VisibilityOutlinedIcon,
    UnfoldMoreOutlinedIcon,
} = sharedImports;


const drawerWidth = 300;
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));
const reportListRows = [
    { examinationType: 'Examination Date', parameter: 'Examination Date', history: '', annualHEALTHCHECKUP20222023: '14/11/2022', preEMPLOYMENT: '06/05/2021' },
    { examinationType: 'Diagnosis', parameter: 'Diagnosis', history: '', annualHEALTHCHECKUP20222023: 'Obese Pre Diabetic Elevated SGPT Low HDL Vitamin D3 deficiency', preEMPLOYMENT: 'Overweight Pre Diabetic Low HDL Elevated SGPT' },
    { examinationType: '', parameter: 'Recommendation', history: '', annualHEALTHCHECKUP20222023: 'Consult doctor for vitamin D3 deficiency Reduce Weight', preEMPLOYMENT: 'Reduce Weight' },
    { examinationType: '', parameter: 'HealthindexPercentage', history: '', annualHEALTHCHECKUP20222023: 84.17, preEMPLOYMENT: 88.67 },
    { examinationType: '', parameter: 'Counselling Remark', history: '', annualHEALTHCHECKUP20222023: '', preEMPLOYMENT: '' },
    { examinationType: 'Vital', parameter: 'Height', history: 'View History', annualHEALTHCHECKUP20222023: 165.00, preEMPLOYMENT: 166.00 },
    { examinationType: '', parameter: 'Weight', history: 'View History', annualHEALTHCHECKUP20222023: 84.00, preEMPLOYMENT: 82.00 },
];
function HideOnScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
};
const styles = {
    TableRow: {
        th: {
            width: '12.5%',
            svg: {
                float: 'right',
                color: '#1976d2'
            },
        },
        td: {
            border: '1px solid lightgray',
        }
    },
    h: {
        minHeight: '40px !important'
    },
    bgWhite: {
        bgcolor: 'white',
        color: 'black'
    }
}

export default function MedicalHistory(props) {
    return (
        <>
            <React.Fragment>
                <Box>
                    <CssBaseline />
                    <HideOnScroll {...props}>
                        <AppBar
                            sx={{
                                width: { sm: `calc(100% - ${drawerWidth}px)` },
                                ml: { sm: `${drawerWidth}px` },
                                mt: { xs: '53px', sm: '64px' },
                                zIndex: 1099
                            }}
                            elevation={1}
                        >
                            <Toolbar sx={[styles.h, styles.bgWhite]}>
                                <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Grid item>
                                        <Typography variant="h6" noWrap component="div" sx={{ m: 1, fontWeight: 'bold' }}>
                                            Medical History
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Toolbar>
                        </AppBar>
                    </HideOnScroll>
                    <Box sx={{ mt: 7, mb: 2, mx: 2, bgcolor: 'white', p: 3, borderRadius: 1 }}>
                        <Box component="div" xs={12}>
                            <Grid container spacing={1} sx={{ mb: 1 }}>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Employee Search <Box component='span' sx={{ color: 'red' }}>*</Box></Box>
                                    <Grid item xs={12} md={11}>
                                        <TextField
                                            required
                                            id="filled-search"
                                            fullWidth
                                            size='small'
                                            placeholder='Name'
                                        />
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Employee Name <Box component='span' sx={{ color: 'red' }}>*</Box></Box>
                                    <Grid item xs={12} md={11}>
                                        <TextField
                                            required
                                            id="filled-search"
                                            fullWidth
                                            size='small'
                                            placeholder='Name'
                                        />
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} md={3}></Grid>
                                <Grid item xs={12} md={3}></Grid>
                            </Grid>

                            <Grid container spacing={1} sx={{ mb: 3 }}>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Department</Box>
                                    <Grid item xs={12} md={11}>
                                        <TextField
                                            required
                                            id="filled-search"
                                            fullWidth
                                            size='small'
                                            placeholder='Name'
                                        />
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} md={3} sx={{ display: 'flex', alignItems: 'end' }}>
                                    <FormControlLabel
                                        value="end"
                                        control={<Checkbox />}
                                        label="All Test and General Results"
                                        labelPlacement="end"
                                    />
                                </Grid>
                            </Grid>

                            <Grid container spacing={2} direction='row' xm={12} sm={12} md={12} lg={12} xl={12} justifyContent='center'>
                                <Grid item>
                                    <Button variant='outlined' sx={{ textTransform: 'none' }} startIcon={<CancelOutlinedIcon />}>Clear</Button>
                                </Grid>
                                <Grid item>
                                    <Button variant='outlined' sx={{ textTransform: 'none' }} startIcon={<NoteAddOutlinedIcon />}>Export</Button>
                                </Grid>
                                <Grid item>
                                    <Button variant='outlined' sx={{ textTransform: 'none' }} startIcon={<VisibilityOutlinedIcon />} disabled>360 View</Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                    <Box sx={{ mt: 2, mb: 7, mx: 2, bgcolor: 'white', p: 3, borderRadius: 1 }}>
                        <Box component="div" xs={12}>
                            <TableContainer component={Paper}>
                                <Table aria-label="customized table">
                                    <TableHead>
                                        <TableRow sx={styles.TableRow}>
                                            <StyledTableCell>Examination Type<UnfoldMoreOutlinedIcon /></StyledTableCell>
                                            <StyledTableCell>Parameter<UnfoldMoreOutlinedIcon /></StyledTableCell>
                                            <StyledTableCell>History<UnfoldMoreOutlinedIcon /></StyledTableCell>
                                            <StyledTableCell>ANNUAL HEALTH CHECKUP 2022-2023<UnfoldMoreOutlinedIcon /></StyledTableCell>
                                            <StyledTableCell>PRE EMPLOYMENT<UnfoldMoreOutlinedIcon /></StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {reportListRows.map((row) => (
                                            <StyledTableRow key={row.examinationType} sx={styles.TableRow}>
                                                <StyledTableCell> {row.examinationType} </StyledTableCell>
                                                <StyledTableCell>{row.parameter}</StyledTableCell>
                                                <StyledTableCell>{row.history}</StyledTableCell>
                                                <StyledTableCell>{row.annualHEALTHCHECKUP20222023}</StyledTableCell>
                                                <StyledTableCell>{row.preEMPLOYMENT}</StyledTableCell>
                                            </StyledTableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Box>
                </Box>
            </React.Fragment>
        </>
    )
}