import sharedImports from '../../../Comman/sharedImports';
const {
    React,
    PropTypes,
    Box,
    Grid,
    Alert,
    Radio,
    Slide,
    Select,
    AppBar,
    Button,
    MenuItem,
    Toolbar,
    TextField,
    RadioGroup,
    Typography,
    CssBaseline,
    FormControl,
    FormControlLabel,
    useScrollTrigger,
    NoteAddOutlinedIcon,
    CheckOutlinedIcon,
} = sharedImports;

const drawerWidth = 300;
function HideOnScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
};

const styles = {
    h: {
        minHeight: '40px !important'
    },
    bgWhite: {
        bgcolor: 'white',
        color: 'black'
    }
}

export default function ImportStockItem(props) {
    const [age, setAge] = React.useState('');
    function handleChange(event) {
        setAge(event.target.value);
    }
    return (
        <>
            <React.Fragment>
                <Box>
                    <CssBaseline />
                    <HideOnScroll {...props}>
                        <AppBar
                            sx={{
                                width: { sm: `calc(100% - ${drawerWidth}px)` },
                                ml: { sm: `${drawerWidth}px` },
                                mt: { xs: '53px', sm: '64px' },
                                zIndex: 1099
                            }}
                            elevation={1}
                        >
                            <Toolbar sx={[styles.h, styles.bgWhite]}>
                                <Typography variant="h6" sx={{ fontWeight: 'bold' }} noWrap component="div">
                                    Import Stock Item
                                </Typography>
                            </Toolbar>
                        </AppBar>
                    </HideOnScroll>
                    <Box sx={{ mt: 7, mb: 2, mx: 2, bgcolor: 'white', p: 3, borderRadius: 1 }}>
                        <Alert severity="info" sx={{ color: 'red', fontWeight: 'bold', fontSize: '16px', bgcolor: 'lightgray' }}>
                            Import Stock Item In Location : Uttar Pradesh
                            <br />
                            Notes : Sheet Name Must be StockItem
                            <br />
                            Notes : Item Sub Group, Item Category, UOM, Drug Instuction will be auto added to master if matching record not found in respective master
                            <br />
                            Notes : Text data to be Separated
                            <br />
                            Notes : You can import 200 records at a time
                        </Alert>
                        <Box component="div" xs={12}>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                            >
                                <FormControlLabel
                                    value='New Employee'
                                    control={<Radio />}
                                    label='Copy'
                                />
                                <FormControlLabel
                                    value='Update Employee'
                                    control={<Radio />}
                                    label='Upload'
                                />
                            </RadioGroup>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Main Group <Box component='span' sx={{ color: 'red' }}>*</Box></Box>
                                    <Grid item xs={12} md={11}>
                                        <FormControl fullWidth>
                                            <Select
                                                value={age}
                                                onChange={handleChange}
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Without label' }}
                                            >
                                                <MenuItem sx={{ ml: 3 }} value="">
                                                    <>-All-</>
                                                </MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={1}>Consumable</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={2}>Laboratory</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={3}>Medicine</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={4}>SETRID-COLD</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                            >
                                <FormControlLabel
                                    value='Excel'
                                    control={<Radio />}
                                    label='Excel'
                                />
                            </RadioGroup>
                            <Box component='div'>
                                <Button sx={{textTransform: 'none'}} href='#text-button'>Download Template</Button>
                            </Box>
                            <Grid container spacing={2} sx={{ mb: 2 }}>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        id="outlined-multiline-static"
                                        placeholder='Copy and paste data here'
                                        multiline
                                        fullWidth
                                        rows={4}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} direction='row' xm={12} sm={12} md={12} lg={12} xl={12} justifyContent='center'>
                                <Grid item>
                                    <Button variant='contained' sx={{textTransform: 'none'}} startIcon={<CheckOutlinedIcon />}>Validate</Button>
                                </Grid>
                                <Grid item>
                                    <Button variant='outlined' sx={{textTransform: 'none'}} startIcon={<NoteAddOutlinedIcon />}>Export Stock Item Category</Button>
                                </Grid>
                                <Grid item>
                                    <Button variant='outlined' sx={{textTransform: 'none'}} startIcon={<NoteAddOutlinedIcon />}>Export Stock Item SubGroup</Button>
                                </Grid>
                                <Grid item>
                                    <Button variant='outlined' sx={{textTransform: 'none'}} startIcon={<NoteAddOutlinedIcon />}>Export Issue UOM</Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Box>
            </React.Fragment>
        </>
    )
}