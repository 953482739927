import sharedImports from '../../../Comman/sharedImports';
const {
    React,
    PropTypes,
    Box,
    Grid,
    Alert,
    styled,
    Slide,
    AppBar,
    FormControl,
    Select,
    MenuItem,
    DeleteOutlineIcon,
    Button,
    Toolbar,
    TextField,
    Typography,
    EditNoteOutlinedIcon,
    CssBaseline,
    useScrollTrigger,
    CancelOutlinedIcon,
    FormatListBulletedOutlinedIcon,
    SaveOutlinedIcon,
} = sharedImports;


const drawerWidth = 300;
const VisuallyHiddenInput = styled('input')({});
function HideOnScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
};
const styles = {
    TableRow: {
        th: {
            padding: '5px',
            svg: {
                float: 'right',
                color: '#1976d2'
            },
        },
        td: {
            padding: '5px',
            border: '1px solid lightgray',
        }
    },
    h: {
        minHeight: '40px !important'
    },
    bgWhite: {
        bgcolor: 'white',
        color: 'black'
    }
}

export default function HealthDocumentUploadUpdate(props) {

    const [age, setAge] = React.useState('');
    function handleChange(event) {
        setAge(event.target.value);
    }

    return (
        <>
            <React.Fragment>
                <Box>
                    <CssBaseline />
                    <HideOnScroll {...props}>
                        <AppBar
                            sx={{
                                width: { sm: `calc(100% - ${drawerWidth}px)` },
                                ml: { sm: `${drawerWidth}px` },
                                mt: { xs: '53px', sm: '64px' },
                                zIndex: 1099
                            }}
                            elevation={1}
                        >
                            <Toolbar sx={[styles.h, styles.bgWhite]}>
                                <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Grid item>
                                        <Typography variant="h6" noWrap component="div" sx={{ m: 1 }}>
                                            Health Document Upload
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Button variant='outlined' startIcon={<SaveOutlinedIcon />} sx={{ m: 1 }}> Save </Button>
                                        <Button variant='contained' startIcon={<EditNoteOutlinedIcon />} sx={{ m: 1 }}> Edit </Button>
                                        <Button variant='outlined' startIcon={<DeleteOutlineIcon />} sx={{ m: 1 }}> Delete </Button>
                                        <Button variant='outlined' startIcon={<CancelOutlinedIcon />} sx={{ m: 1 }}>Clear</Button>
                                    </Grid>
                                    <Grid item>
                                        <Button variant='contained' startIcon={<FormatListBulletedOutlinedIcon />} sx={{ m: 1 }}> List </Button>
                                    </Grid>
                                </Grid>
                            </Toolbar>
                        </AppBar>
                    </HideOnScroll>
                    <Box sx={{ mt: 5, mb: 2, mx: 2, bgcolor: 'white', p: 3, borderRadius: 1 }}>
                        <Alert severity="info" sx={{ color: 'red', fontWeight: 'bold', fontSize: '16px', bgcolor: 'lightgray', mb: 2 }}>
                            Note: File Types like png, jpg, jpeg, doc, docx, xls, xlsx, pdf, MP3 as Document are allowed.
                            <br />
                            Note: For URL, Only Youtube video links are allowed.
                        </Alert>
                        <Box component="div" xs={12}>
                            <Grid container spacing={2} sx={{ mb: 2 }}>
                                <Grid item xs={12} md={3}>
                                    <Box>Title <Box component='span' sx={{ color: 'red' }}>*</Box></Box>
                                    <TextField
                                        id="filled-search"
                                        type="search"
                                        placeholder='Title'
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Box>Description</Box>
                                    <TextField
                                        id="filled-search"
                                        type="search"
                                        placeholder='Title'
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Box>Category <Box component='span' sx={{ color: 'red' }}>*</Box></Box>
                                    <FormControl fullWidth>
                                        <Select
                                            value={age}
                                            onChange={handleChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem value="">
                                                <em>-All-</em>
                                            </MenuItem>
                                            <MenuItem value={1}>Councelling</MenuItem>
                                            <MenuItem value={2}>Diabetes Prevention Awareness</MenuItem>
                                            <MenuItem value={3}>First Aid Training</MenuItem>
                                            <MenuItem value={4}>OBESITY MANAGMENT</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Box>Sub Category <Box component='span' sx={{ color: 'red' }}>*</Box></Box>
                                    <FormControl fullWidth>
                                        <Select
                                            value={age}
                                            onChange={handleChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem value="">
                                                <em>-All-</em>
                                            </MenuItem>
                                            <MenuItem value={1}>Councelling</MenuItem>
                                            <MenuItem value={2}>Diabetes Prevention Awareness</MenuItem>
                                            <MenuItem value={3}>First Aid Training</MenuItem>
                                            <MenuItem value={4}>OBESITY MANAGMENT</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} sx={{ mb: 2 }}>
                                <Grid item xs={12} md={3}>
                                    <Box>Type <Box component='span' sx={{ color: 'red' }}>*</Box></Box>
                                    <FormControl fullWidth>
                                        <Select
                                            value={age}
                                            onChange={handleChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem value="">
                                                <em>-All-</em>
                                            </MenuItem>
                                            <MenuItem value={1}>Councelling</MenuItem>
                                            <MenuItem value={2}>Diabetes Prevention Awareness</MenuItem>
                                            <MenuItem value={3}>First Aid Training</MenuItem>
                                            <MenuItem value={4}>OBESITY MANAGMENT</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Box>Tags</Box>
                                    <TextField
                                        id="filled-search"
                                        type="search"
                                        placeholder='Click here to select'
                                        multiline
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Box>File Type <Box component='span' sx={{ color: 'red' }}>*</Box></Box>
                                    <FormControl fullWidth>
                                        <Select
                                            value={age}
                                            onChange={handleChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem value="">
                                                <em>-All-</em>
                                            </MenuItem>
                                            <MenuItem value={1}>Councelling</MenuItem>
                                            <MenuItem value={2}>Diabetes Prevention Awareness</MenuItem>
                                            <MenuItem value={3}>First Aid Training</MenuItem>
                                            <MenuItem value={4}>OBESITY MANAGMENT</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <VisuallyHiddenInput type='file' /><br />
                                    <Button sx={{ textTransform: 'none' }}>View</Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Box>
            </React.Fragment>
        </>
    )
}