import sharedImports from '../../../Comman/sharedImports';
const {
    React,
    Box,
    Grid,
    Slide,
    Alert,
    Button,
    styled,
    AppBar,
    Toolbar,
    PropTypes,
    Typography,
    useScrollTrigger,
    CloudUploadIcon,
    InsertDriveFileOutlinedIcon,
} = sharedImports;

const drawerWidth = 300;
function HideOnScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
};

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

export default function ImportAsset(props) {

    const styles = {
        root: {
            backgroundColor: '#f1efef',
        },
        firstForm: {
            padding: '16px 16px 16px 16px',
        },
        formControl: {
            width: 1
        },
        TableRow: {
            th: {
                width: '50%',
            },
            td: {
                border: '1px solid lightgray',
            }
        },
        h: {
            minHeight: '40px !important'
        },
        mb2: {
            marginBottom: 2,
        },
        bgWhite: {
            backgroundColor: 'white !important',
            color: 'black'
        },
        bRadius: {
            borderRadius: 1,
            marginTop: '65px'
        },
        wFull: {
            width: '100%'
        },
        dFlex: {
            display: 'flex'
        },
        justifySB: {
            justifyContent: 'space-between',
        },
        justifyEnd: {
            justifyContent: 'end',
        },
        align: {
            alignItems: 'center'
        },
        color: {
            color: 'red',
        },
        bgGray: {
            backgroundColor: 'lightgray',
        },
        fwBold: {
            fontWeight: 'bold'
        },
        padding: {
            padding: 1
        },
        pAlart: {
            padding: '0px 30px'
        }
    }


    return (
        <Box sx={[styles.root, styles.wFull]}>
            <HideOnScroll {...props}>
                <AppBar
                    sx={{
                        width: { sm: `calc(100% - ${drawerWidth}px)` },
                        ml: { sm: `${drawerWidth}px` },
                        mt: { xs: '53px', sm: '64px' },
                        zIndex: 1099
                    }}
                    elevation={1}
                >
                    <Toolbar sx={[styles.h, styles.bgWhite]}>
                        <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Grid item>
                                <Typography variant="h6" noWrap component="div" sx={{ m: 1, fontWeight: 'bold' }}>
                                    Import Asset
                                </Typography>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
            </HideOnScroll>
            <Box sx={styles.firstForm}>
                <Box sx={[styles.bgWhite, styles.wFull, styles.p20, styles.bRadius]} className='MainPadding'>
                    <Grid container spacing={2} sx={styles.pAlart}>
                        <Grid item xs={12}>
                            <Alert variant="soft" sx={styles.color}>
                                Notes: Text data to be Separated
                            </Alert>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} sx={[styles.pAlart, { padding: '13px 30px 30px 30px' }]}>
                        <Grid item xs={12} md={3}>
                            <Button variant='outlined' sx={{ textTransform: 'none' }}>Download Template</Button>

                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Button
                                sx={{ textTransform: 'none' }}
                                component="label"
                                role={undefined}
                                variant="contained"
                                tabIndex={-1}
                                startIcon={<CloudUploadIcon />}
                                xs={12} md={6}
                            >
                                Choose file
                                <VisuallyHiddenInput type="file" />
                            </Button>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Button variant='outlined' sx={{ textTransform: 'none' }} startIcon={<CloudUploadIcon />}>Import</Button>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Button variant='outlined' sx={{ textTransform: 'none' }} startIcon={<InsertDriveFileOutlinedIcon />}>Export Asset Category</Button>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Button variant='outlined' sx={{ textTransform: 'none' }} startIcon={<InsertDriveFileOutlinedIcon />}>Export Asset Location</Button>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Box>
    )
}