import sharedImports from '../../Comman/sharedImports';
const {
    React,
    Box,
    Link,
    clsx,
    alpha,
    styled,
    useTheme,
    Typography,
    useTreeItem,
    TreeItem2Icon,
    TreeItem2Root,
    ArrowRightIcon,
    SimpleTreeView,
    TreeItem2Content,
    ArrowDropDownIcon,
    TreeItem2Provider,
    TreeItem2IconContainer,
    TreeItem2GroupTransition,
    PermScanWifiOutlinedIcon,
    ArrowRightAltOutlinedIcon,
} = sharedImports;

const safety = [
    { id: 2, label: 'Form Accident With Injury', to: 'safety/form-accident-with-injury' },
    { id: 3, label: 'Form Accident Without Injury', to: 'safety/form-accident-without-injury' },
    { id: 4, label: 'Incident Listing', to: 'safety/incident-listing' },
    { id: 5, label: 'Incident Log', to: 'safety/incident-log' },
    { id: 6, label: 'Injuries Report', to: 'safety/injuries-report' },
]
const CustomTreeItemRoot = styled(TreeItem2Root)(({ theme }) => ({
    color: theme.palette.text.secondary,
}));
const CustomTreeItemContent = styled(TreeItem2Content)(({ theme }) => ({
    marginBottom: theme.spacing(0.3),
    color: theme.palette.text.secondary,
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    '&.expanded': {
        fontWeight: theme.typography.fontWeightRegular,
    },
    '&:hover': {
        backgroundColor: theme.palette.action.hover,
    },
    '&.focused, &.selected, &.selected.focused': {
        backgroundColor: `var(--tree-view-bg-color, ${theme.palette.action.selected})`,
        color: '#000000',
    },
}));
const CustomTreeItemIconContainer = styled(TreeItem2IconContainer)(({ theme }) => ({
    marginRight: theme.spacing(1),
}));
const CustomTreeItemGroupTransition = styled(TreeItem2GroupTransition)(
    ({ theme }) => ({
        marginLeft: 0,
        [`& .content`]: {
            paddingLeft: theme.spacing(2),
        },
    }),
);
const CustomTreeItem = React.forwardRef(function CustomTreeItem(props, ref) {
    const theme = useTheme();
    const {
        id,
        itemId,
        label,
        disabled,
        children,
        bgColor,
        color,
        labelIcon: LabelIcon,
        labelInfo,
        colorForDarkMode,
        bgColorForDarkMode,
        ...other
    } = props;
    const {
        getRootProps,
        getContentProps,
        getIconContainerProps,
        getLabelProps,
        getGroupTransitionProps,
        status,
    } = useTreeItem({ id, itemId, children, label, disabled, rootRef: ref });
    const style = {
        '--tree-view-color': theme.palette.mode !== 'dark' ? color : colorForDarkMode,
        '--tree-view-bg-color':
            theme.palette.mode !== 'dark' ? bgColor : bgColorForDarkMode,
    };

    return (
        <TreeItem2Provider itemId={itemId}>
            <CustomTreeItemRoot {...getRootProps({ ...other, style })}>
                <CustomTreeItemContent
                    {...getContentProps({
                        className: clsx('content', {
                            expanded: status.expanded,
                            selected: status.selected,
                            focused: status.focused,
                        }),
                    })}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexGrow: 1,
                            alignItems: 'center',
                            p: 0,
                            pr: 0,
                        }}
                    >
                        <Box component={LabelIcon} color="inherit" sx={{ mr: 1 }} />
                        <Typography
                            {...getLabelProps({
                                variant: 'body2',
                                sx: { display: 'flex', fontWeight: 'inherit', flexGrow: 1 },
                            })}
                        />
                        <Typography variant="caption" color="inherit">
                            {labelInfo}
                        </Typography>
                    </Box>
                    <CustomTreeItemIconContainer {...getIconContainerProps()}>
                        <TreeItem2Icon status={status} />
                    </CustomTreeItemIconContainer>
                </CustomTreeItemContent>
                {children && (
                    <CustomTreeItemGroupTransition {...getGroupTransitionProps()} />
                )}
            </CustomTreeItemRoot>
        </TreeItem2Provider>
    );
});

function EndIcon() {
    return <div style={{ width: 24 }} />;
}

export default function Safety() {
    return (
        <SimpleTreeView
            aria-label="Safety"
            defaultExpandedItems={['3']}
            defaultSelectedItems="5"
            slots={{
                expandIcon: ArrowRightIcon,
                collapseIcon: ArrowDropDownIcon,
                endIcon: EndIcon,
            }}
            sx={{ flexGrow: 1, maxWidth: 400 }}
        >
            <CustomTreeItem itemId="1" label="Safety" labelIcon={PermScanWifiOutlinedIcon}>
                {safety.map((item) => (
                    <Link to={item.to}>
                        <CustomTreeItem
                            itemId={item.id}
                            label={item.label}
                            labelIcon={ArrowRightAltOutlinedIcon}
                            color="#000000"
                            bgColor="#e8f0fe"
                            colorForDarkMode="#B8E7FB"
                            bgColorForDarkMode={alpha('#00b4ff', 0.2)}
                        />
                    </Link>
                ))}
            </CustomTreeItem>
        </SimpleTreeView>
    );
}