import sharedImports from '../../../Comman/sharedImports';
const {
    React,
    PropTypes,
    Box,
    CheckIcon,
    Grid,
    Slide,
    AppBar,
    Button,
    Toolbar,
    TextField,
    FileUploadOutlinedIcon,
    Typography,
    CssBaseline,
    useScrollTrigger,
    CancelOutlinedIcon,
} = sharedImports;

const drawerWidth = 300;
function HideOnScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });
    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}
HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
};
const styles = {
    h: {
        minHeight: '40px !important'
    },
    bgWhite: {
        bgcolor: 'white',
        color: 'black'
    }
}

export default function ImportPantry(props) {
    return (
        <>
            <React.Fragment>
                <Box>
                    <CssBaseline />
                    <HideOnScroll {...props}>
                        <AppBar
                            sx={{
                                width: { sm: `calc(100% - ${drawerWidth}px)` },
                                ml: { sm: `${drawerWidth}px` },
                                mt: { xs: '53px', sm: '64px' },
                                zIndex: 1099
                            }}
                            elevation={1}
                        >
                            <Toolbar sx={[styles.h, styles.bgWhite]}>
                                <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Grid item>
                                        <Typography variant="h6" noWrap component="div" sx={{ m: 1, fontWeight: 'bold' }}>
                                            Import Pantry
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Button variant='contained' startIcon={<FileUploadOutlinedIcon />} sx={{ m: 1, textTransform: 'none' }}> Import </Button>
                                        <Button variant='outlined' startIcon={<CancelOutlinedIcon />} sx={{ m: 1, textTransform: 'none' }}>Clear</Button>
                                    </Grid>
                                    <Grid item></Grid>
                                </Grid>
                            </Toolbar>
                        </AppBar>
                    </HideOnScroll>
                    <Box sx={{ mt: 4, mb: 2, mx: 2, bgcolor: 'white', p: 3, borderRadius: 1 }}>
                        <Box component='div'>
                            <Button href='#text-button' sx={{ textTransform: 'none' }}>Download Template <Box component='span' sx={{ color: 'red' }}>*</Box></Button>
                        </Box>
                        <Grid container spacing={2} sx={{ mb: 2 }}>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    id="outlined-multiline-static"
                                    placeholder='Copy and paste data here'
                                    multiline
                                    fullWidth
                                    rows={4}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} direction='row' xm={12} sm={12} md={12} lg={12} xl={12} justifyContent='end'>
                            <Grid item>
                                <Button variant='outlined' startIcon={<CheckIcon />} sx={{ textTransform: 'none' }}>Validate</Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </React.Fragment >
        </>
    )
}