import sharedImports from '../../../Comman/sharedImports';
const {
    React,
    PropTypes,
    Box,
    Grid,
    Slide,
    AppBar,
    Button,
    Toolbar,
    TextField,
    MenuItem,
    Select,
    FormControlLabel,
    DatePicker,
    Typography,
    AdapterDayjs,
    DemoItem,
    CssBaseline,
    FormControl,
    LocalizationProvider,
    useScrollTrigger,
    Checkbox,
    CancelOutlinedIcon,
    FormatListBulletedOutlinedIcon,
    SaveOutlinedIcon,
} = sharedImports;


const drawerWidth = 300;
function HideOnScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
};
const styles = {
    TableRow: {
        th: {
            width: '12.5%',
            svg: {
                float: 'right',
                color: '#1976d2'
            },
        },
        td: {
            border: '1px solid lightgray',
        }
    },
    h: {
        minHeight: '40px !important'
    },
    bgWhite: {
        bgcolor: 'white',
        color: 'black'
    }
}

export default function AssetMasterCreate(props) {

    const [age, setAge] = React.useState('');
    function handleChange(event) {
        setAge(event.target.value);
    }

    return (
        <>
            <React.Fragment>
                <Box>
                    <CssBaseline />
                    <HideOnScroll {...props}>
                        <AppBar
                            sx={{
                                width: { sm: `calc(100% - ${drawerWidth}px)` },
                                ml: { sm: `${drawerWidth}px` },
                                mt: { xs: '53px', sm: '64px' },
                                zIndex: 1099
                            }}
                            elevation={1}
                        >
                            <Toolbar sx={[styles.h, styles.bgWhite]}>
                                <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Grid item>
                                        <Typography variant="h6" noWrap component="div" sx={{ m: 1, fontWeight: 'bold' }}>
                                            Asset Master Create
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Button variant='contained' startIcon={<SaveOutlinedIcon />} sx={{ m: 1, textTransform: 'none' }}> Save </Button>
                                        <Button variant='outlined' startIcon={<CancelOutlinedIcon />} sx={{ m: 1, textTransform: 'none' }}>Clear</Button>
                                    </Grid>
                                    <Grid item>
                                        <Button variant='contained' startIcon={<FormatListBulletedOutlinedIcon />} sx={{ m: 1, textTransform: 'none' }}> List </Button>
                                    </Grid>
                                </Grid>
                            </Toolbar>
                        </AppBar>
                    </HideOnScroll>
                    <Box sx={{ mt: 5, mb: 2, mx: 2, bgcolor: 'white', p: 3, borderRadius: 1 }}>
                        <Box component="div" xs={12}>
                            <Box component='h2' sx={{ m: 0, mb: 1, p: 0 }}>Asset Details</Box>
                            <Grid container spacing={2} sx={{ mb: 2 }}>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Asset Tag</Box>
                                    <Grid item xs={12} md={11}>
                                        <TextField
                                            required
                                            id="filled-search"
                                            fullWidth
                                            size='small'
                                            placeholder="Asset Tag"
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Asset Name <Box component='span' sx={{ color: 'red' }}>*</Box></Box>
                                    <Grid item xs={12} md={11}>
                                        <TextField
                                            required
                                            id="filled-search"
                                            fullWidth
                                            size='small'
                                            placeholder="Asset Name"
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Asset Category <Box component='span' sx={{ color: 'red' }}>*</Box></Box>
                                    <Grid item xs={12} md={11}>
                                        <FormControl fullWidth>
                                            <Select
                                                value={age}
                                                onChange={handleChange}
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Without label' }}
                                            >
                                                <MenuItem sx={{ ml: 3 }} value="">
                                                    <>-All-</>
                                                </MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={1}>BP Instrument- First-aid room</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={2}>ECG</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={3}>Oxygen Concentrator</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={3}>Concentrator</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={3}>Infusion pump</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={3}>Thermal Gun</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={3}>Glucometer</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={3}>Two para oximeter</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} sx={{ mb: 2 }}>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Model Number</Box>
                                    <Grid item xs={12} md={11}>
                                        <TextField
                                            required
                                            id="filled-search"
                                            fullWidth
                                            size='small'
                                            placeholder="Model Number"
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Purchase Cost <Box component='span' sx={{ color: 'red' }}>*</Box></Box>
                                    <Grid item xs={12} md={11}>
                                        <TextField
                                            required
                                            id="filled-search"
                                            fullWidth
                                            size='small'
                                            placeholder="Purchase Cost"
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Purchase Cost</Box>
                                    <Grid item xs={12} md={11}>
                                        <TextField
                                            required
                                            id="filled-search"
                                            fullWidth
                                            size='small'
                                            placeholder="Purchase Cost"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} sx={{ mb: 2 }}>
                                <Grid item xs={12} md={6}>
                                    <Box sx={{ fontWeight: 'bold' }}>Specification</Box>
                                    <TextField
                                        id="filled-search"
                                        type="search"
                                        placeholder='Specification'
                                        multiline
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} sx={{ mb: 2 }}>
                                <Grid item xs={12} md={6}>
                                    <Box sx={{ fontWeight: 'bold' }}>Accessories</Box>
                                    <TextField
                                        id="filled-search"
                                        type="search"
                                        placeholder='Accessories'
                                        multiline
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                    <Box sx={{ mt: 2, mx: 2, bgcolor: 'white', p: 3, borderRadius: 1 }}>
                        <Box component="div" xs={12}>
                            <Box component='h2' sx={{ m: 0, p: 0 }}>Maintenance Details</Box>
                            <Box sx={{ mb: 2 }}>
                                <FormControlLabel
                                    value="end"
                                    control={<Checkbox />}
                                    label="Maintenance Required"
                                    labelPlacement="end"
                                />
                            </Box>
                            <Grid container spacing={2} sx={{ mb: 2 }}>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Service Entry <Box component='span' sx={{ color: 'red' }}>*</Box></Box>
                                    <Grid item xs={12} md={11}>
                                        <FormControl fullWidth>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DemoItem >
                                                    <DatePicker />
                                                </DemoItem>
                                            </LocalizationProvider>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Maintenance Period (Days) <Box component='span' sx={{ color: 'red' }}>*</Box></Box>
                                    <Grid item xs={12} md={11}>
                                        <TextField
                                            required
                                            id="filled-search"
                                            fullWidth
                                            size='small'
                                            placeholder="Maintenance Period (Days)"
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Alert Period (Days) <Box component='span' sx={{ color: 'red' }}>*</Box></Box>
                                    <Grid item xs={12} md={11}>
                                        <TextField
                                            required
                                            id="filled-search"
                                            fullWidth
                                            size='small'
                                            placeholder="Alert Period (Days)"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Current Location <Box component='span' sx={{ color: 'red' }}>*</Box></Box>
                                    <Grid item xs={12} md={11}>
                                        <FormControl fullWidth>
                                            <Select
                                                value={age}
                                                onChange={handleChange}
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Without label' }}
                                            >
                                                <MenuItem sx={{ ml: 3 }} value="">
                                                    <>-All-</>
                                                </MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={1}>BP Instrument- First-aid room</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={2}>ECG</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={3}>Oxygen Concentrator</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={3}>Concentrator</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={3}>Infusion pump</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={3}>Thermal Gun</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={3}>Glucometer</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={3}>Two para oximeter</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Usage Status <Box component='span' sx={{ color: 'red' }}>*</Box></Box>
                                    <Grid item xs={12} md={11}>
                                        <FormControl fullWidth>
                                            <Select
                                                value={age}
                                                onChange={handleChange}
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Without label' }}
                                            >
                                                <MenuItem sx={{ ml: 3 }} value="">
                                                    <>-All-</>
                                                </MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={1}>BP Instrument- First-aid room</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={2}>ECG</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={3}>Oxygen Concentrator</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={3}>Concentrator</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={3}>Infusion pump</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={3}>Thermal Gun</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={3}>Glucometer</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={3}>Two para oximeter</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} sx={{ mb: 2 }}>
                                <Grid item xs={12} md={3}>
                                    <FormControlLabel
                                        value="end"
                                        control={<Checkbox />}
                                        label="Critical"
                                        labelPlacement="end"
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <FormControlLabel
                                        value="end"
                                        control={<Checkbox />}
                                        label="Is Amc"
                                        labelPlacement="end"
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} sx={{ mb: 2 }}>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Last Service Cost</Box>
                                    <Grid item xs={12} md={11}>
                                        <TextField
                                            required
                                            id="filled-search"
                                            fullWidth
                                            size='small'
                                            placeholder="Last Service Cost"
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Total Service Cost</Box>
                                    <Grid item xs={12} md={11}>
                                        <TextField
                                            required
                                            id="filled-search"
                                            fullWidth
                                            size='small'
                                            placeholder="Total Service Cost"
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Last Maintenance Date <Box component='span' sx={{ color: 'red' }}>*</Box></Box>
                                    <Grid item xs={12} md={11}>
                                        <FormControl fullWidth>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DemoItem >
                                                    <DatePicker />
                                                </DemoItem>
                                            </LocalizationProvider>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Next Maintenance Date <Box component='span' sx={{ color: 'red' }}>*</Box></Box>
                                    <Grid item xs={12} md={11}>
                                        <FormControl fullWidth>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DemoItem >
                                                    <DatePicker />
                                                </DemoItem>
                                            </LocalizationProvider>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                    <Box sx={{ mt: 2, mb: 7, mx: 2, bgcolor: 'white', p: 3, borderRadius: 1 }}>
                        <Box component="div" xs={12}>
                            <Box component='h2' sx={{ m: 0, p: 0 }}>Check Details</Box>
                            <Grid container spacing={2} sx={{ mb: 2 }}>
                                <Grid item xs={12} md={3}>
                                    <FormControlLabel
                                        value="end"
                                        control={<Checkbox />}
                                        label="Check Required"
                                        labelPlacement="end"
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Check Frequency <Box component='span' sx={{ color: 'red' }}>*</Box></Box>
                                    <Grid item xs={12} md={11}>
                                        <FormControl fullWidth>
                                            <Select
                                                value={age}
                                                onChange={handleChange}
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Without label' }}
                                            >
                                                <MenuItem sx={{ ml: 3 }} value="">
                                                    <>-All-</>
                                                </MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={1}>BP Instrument- First-aid room</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={2}>ECG</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={3}>Oxygen Concentrator</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={3}>Concentrator</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={3}>Infusion pump</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={3}>Thermal Gun</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={3}>Glucometer</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={3}>Two para oximeter</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Check List</Box>
                                    <Grid item xs={12} md={11}>
                                        <TextField
                                            required
                                            id="filled-search"
                                            fullWidth
                                            size='small'
                                            placeholder="Check List"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} sx={{ mb: 2 }}>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Checked LastDate</Box>
                                    <Grid item xs={12} md={11}>
                                        <FormControl fullWidth>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DemoItem >
                                                    <DatePicker />
                                                </DemoItem>
                                            </LocalizationProvider>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Checked By UserId</Box>
                                    <Grid item xs={12} md={11}>
                                        <TextField
                                            required
                                            id="filled-search"
                                            fullWidth
                                            size='small'
                                            placeholder="Checked By UserId"
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Checked Status <Box component='span' sx={{ color: 'red' }}>*</Box></Box>
                                    <Grid item xs={12} md={11}>
                                        <FormControl fullWidth>
                                            <Select
                                                value={age}
                                                onChange={handleChange}
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Without label' }}
                                            >
                                                <MenuItem sx={{ ml: 3 }} value="">
                                                    <>-All-</>
                                                </MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={1}>BP Instrument- First-aid room</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={2}>ECG</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={3}>Oxygen Concentrator</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={3}>Concentrator</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={3}>Infusion pump</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={3}>Thermal Gun</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={3}>Glucometer</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={3}>Two para oximeter</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} sx={{ mb: 2 }}>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Checked By UserId</Box>
                                    <Grid item xs={12} md={11}>
                                        <TextField
                                            required
                                            id="filled-search"
                                            fullWidth
                                            size='small'
                                            placeholder="Checked By UserId"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                    <Box sx={{ mt: 2, mb: 7, mx: 2, bgcolor: 'white', p: 3, borderRadius: 1 }}>
                        <Box component="div" xs={12}>
                            <Box component='h2' sx={{ m: 0, p: 0 }}>Renew Details</Box>
                            <Grid container spacing={2} sx={{ mb: 2 }}>
                                <Grid item xs={12} md={3}>
                                    <FormControlLabel
                                        value="end"
                                        control={<Checkbox />}
                                        label="Renew Required"
                                        labelPlacement="end"
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Renew LastDate <Box component='span' sx={{ color: 'red' }}>*</Box></Box>
                                    <Grid item xs={12} md={11}>
                                        <FormControl fullWidth>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DemoItem >
                                                    <DatePicker />
                                                </DemoItem>
                                            </LocalizationProvider>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Renew NextDate <Box component='span' sx={{ color: 'red' }}>*</Box></Box>
                                    <Grid item xs={12} md={11}>
                                        <FormControl fullWidth>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DemoItem >
                                                    <DatePicker />
                                                </DemoItem>
                                            </LocalizationProvider>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} sx={{ mb: 2 }}>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Renew Remark</Box>
                                    <TextField
                                        id="filled-search"
                                        type="search"
                                        placeholder='Renew Remark'
                                        multiline
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Renew CheckList</Box>
                                    <TextField
                                        id="filled-search"
                                        type="search"
                                        placeholder='Renew CheckList'
                                        multiline
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Renew Alert BeforeDays <Box component='span' sx={{ color: 'red' }}>*</Box></Box>
                                    <Grid item xs={12} md={11}>
                                        <FormControl fullWidth>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DemoItem >
                                                    <DatePicker />
                                                </DemoItem>
                                            </LocalizationProvider>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                    <Box sx={{ mt: 2, mb: 7, mx: 2, bgcolor: 'white', p: 3, borderRadius: 1 }}>
                        <Box component="div" xs={12}>
                            <Box component='h2' sx={{ m: 0, p: 0 }}>Calibration Details</Box>
                            <Box sx={{ mb: 2 }}>
                                <FormControlLabel
                                    value="end"
                                    control={<Checkbox />}
                                    label="Calibration Required"
                                    labelPlacement="end"
                                />
                            </Box>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Calibration Done Date <Box component='span' sx={{ color: 'red' }}>*</Box></Box>
                                    <Grid item xs={12} md={11}>
                                        <FormControl fullWidth>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DemoItem >
                                                    <DatePicker />
                                                </DemoItem>
                                            </LocalizationProvider>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Checked By UserId</Box>
                                    <Grid item xs={12} md={11}>
                                        <TextField
                                            required
                                            id="filled-search"
                                            fullWidth
                                            size='small'
                                            placeholder="Checked By UserId"
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Calibration Remark</Box>
                                    <Grid item xs={12} md={11}>
                                        <TextField
                                            required
                                            id="filled-search"
                                            fullWidth
                                            size='small'
                                            placeholder="Calibration Remark"
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Calibration Due Date</Box>
                                    <Grid item xs={12} md={11}>
                                        <FormControl fullWidth>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DemoItem >
                                                    <DatePicker />
                                                </DemoItem>
                                            </LocalizationProvider>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Box>
            </React.Fragment>
        </>
    )
}