import sharedImports from '../../../Comman/sharedImports';
const {
    React,
    PropTypes,
    Box,
    Link,
    Grid,
    Slide,
    AppBar,
    FormControlLabel,
    Button,
    Toolbar,
    FormControl,
    Select,
    MenuItem,
    Checkbox,
    TableBody,
    Typography,
    TextField,
    CssBaseline,
    SearchOutlinedIcon,
    useScrollTrigger,
    styled,
    FileCopyOutlinedIcon,
    UnfoldMoreOutlinedIcon,
    CancelOutlinedIcon,
    TableContainer,
    TableCell,
    Paper,
    tableCellClasses,
    Table,
    AddCircleOutlineOutlinedIcon,
    TableHead,
    TableRow,
} = sharedImports;


const drawerWidth = 300;
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));
const reportListRows = [
    { code: null, name: null, age: null, department: null, targetGroup: null, criteriaGroup: null, lastExamination: null, frequency: null, dueDate: null, dueInMonth: null, }
];
function HideOnScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
};
const styles = {
    TableRow: {
        th: {
            padding: '5px',
            svg: {
                float: 'right',
                color: '#1976d2'
            },
        },
        td: {
            padding: '5px',
            border: '1px solid lightgray',
        }
    },
    h: {
        minHeight: '40px !important'
    },
    bgWhite: {
        bgcolor: 'white',
        color: 'black'
    }
}

export default function SearchCriteriaGroup(props) {

    const [age, setAge] = React.useState('');
    function handleChange(event) {
        setAge(event.target.value);
    }

    return (
        <>
            <React.Fragment>
                <Box>
                    <CssBaseline />
                    <HideOnScroll {...props}>
                        <AppBar
                            sx={{
                                width: { sm: `calc(100% - ${drawerWidth}px)` },
                                ml: { sm: `${drawerWidth}px` },
                                mt: { xs: '53px', sm: '64px' },
                                zIndex: 1099
                            }}
                            elevation={1}
                        >
                            <Toolbar sx={[styles.h, styles.bgWhite]}>
                                <Grid container>
                                    <Grid item xs={12} md={10}>
                                        <Typography variant="h6" sx={{ fontWeight: 'bold' }} noWrap component="div">
                                            Search Criteria Group
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                        <Link to='/dashboard/support/communication/ticket-master/create'>
                                            <Button variant='contained' startIcon={<AddCircleOutlineOutlinedIcon />} sx={{ bgcolor: 'gray', textTransform: 'none' }}> Add </Button>
                                        </Link>
                                    </Grid>
                                </Grid>
                            </Toolbar>
                        </AppBar>
                    </HideOnScroll>
                    <Box sx={{ mt: 7, mb: 2, mx: 2, bgcolor: 'white', p: 3, borderRadius: 1 }}>
                        <Box component="div" xs={12}>
                            <Grid container spacing={2} sx={{ mb: 3 }}>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Criteria <Box component='span' sx={{ color: 'red' }}>*</Box></Box>
                                    <Grid item xs={12} md={11}>
                                        <FormControl fullWidth>
                                            <Select
                                                value={age}
                                                onChange={handleChange}
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Without label' }}
                                            >
                                                <MenuItem sx={{ ml: 3 }} value="">
                                                    <>-Select-</>
                                                </MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={1}>Security</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={2}>Alert</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={2}>Configuration</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={2}>Globalization</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={2}>Utilities</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={2}>Admin Tool Master</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={2}>Doctor</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={2}>Appointment</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={2}>Inventory Management</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={2}>Order Entry</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={2}>History</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} direction='row' xm={12} sm={12} md={12} lg={12} xl={12} justifyContent='center'>
                                <Grid item>
                                    <Button variant='contained' sx={{ textTransform: 'none' }} startIcon={<SearchOutlinedIcon />}>Search</Button>
                                </Grid>
                                <Grid item>
                                    <Button variant='outlined' sx={{ textTransform: 'none' }} startIcon={<CancelOutlinedIcon />}>Clear</Button>
                                </Grid>
                                <Grid item>
                                    <Button variant='outlined' sx={{ textTransform: 'none' }} startIcon={<CancelOutlinedIcon />}>Export</Button>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} sx={{ mb: 3 }}>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Round <Box component='span' sx={{ color: 'red' }}>*</Box></Box>
                                    <Grid item xs={12} md={11}>
                                        <FormControl fullWidth>
                                            <Select
                                                value={age}
                                                onChange={handleChange}
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Without label' }}
                                            >
                                                <MenuItem sx={{ ml: 3 }} value="">
                                                    <>-All-</>
                                                </MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={1}>Security</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={2}>Alert</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={2}>Configuration</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={2}>Globalization</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={2}>Utilities</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={2}>Admin Tool Master</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={2}>Doctor</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={2}>Appointment</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={2}>Inventory Management</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={2}>Order Entry</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={2}>History</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Button variant='outlined' sx={{ mt: 3, textTransform: 'none' }}>Add To Freeze List</Button>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <FormControlLabel
                                    value="end"
                                    control={<Checkbox />}
                                    label="Send Alert"
                                    labelPlacement="end"
                                />
                            </Grid>
                        </Box>
                    </Box>
                    <Box sx={{ mt: 2, mx: 2, bgcolor: 'white', p: 3, borderRadius: 1 }}>
                        <Box component="div" xs={12}>
                            <Grid container spacing={2} direction='row' xm={12} sm={12} md={12} lg={12} xl={12} justifyContent='space-between' sx={{ mb: 2 }}>
                                <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Grid item sx={{ mr: 2, display: 'flex', alignItems: 'center' }}>
                                        <Box component='span' sx={{ fontWeight: 'bold' }}>Show </Box>
                                        <TextField
                                            id="outlined-number"
                                            type="number"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            defaultValue='10'
                                            sx={{ width: '9ch' }}
                                            size='small'
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Box component='span' sx={{ fontWeight: 'bold' }}>entries </Box>
                                        <Button variant='outlined' sx={{ textTransform: 'none' }} startIcon={<FileCopyOutlinedIcon />}>Export</Button>
                                    </Grid>
                                </Grid>
                                <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Box component='span' sx={{ fontWeight: 'bold' }}>Search: </Box>
                                    <TextField
                                        id="filled-search"
                                        type="search"
                                        size='small'
                                    />
                                </Grid>
                            </Grid>
                            <TableContainer component={Paper} sx={{ mb: 2 }}>
                                <Table aria-label="customized table">
                                    <TableHead>
                                        <TableRow sx={styles.TableRow}>
                                            <StyledTableCell><Checkbox /></StyledTableCell>
                                            <StyledTableCell>Code<UnfoldMoreOutlinedIcon /></StyledTableCell>
                                            <StyledTableCell>Name<UnfoldMoreOutlinedIcon /></StyledTableCell>
                                            <StyledTableCell>Age<UnfoldMoreOutlinedIcon /></StyledTableCell>
                                            <StyledTableCell>Department<UnfoldMoreOutlinedIcon /></StyledTableCell>
                                            <StyledTableCell>Target Group<UnfoldMoreOutlinedIcon /></StyledTableCell>
                                            <StyledTableCell>Criteria Group<UnfoldMoreOutlinedIcon /></StyledTableCell>
                                            <StyledTableCell>Last Examination<UnfoldMoreOutlinedIcon /></StyledTableCell>
                                            <StyledTableCell>Frequency<UnfoldMoreOutlinedIcon /></StyledTableCell>
                                            <StyledTableCell>Due Date<UnfoldMoreOutlinedIcon /></StyledTableCell>
                                            <StyledTableCell>Due In Month<UnfoldMoreOutlinedIcon /></StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {reportListRows.map((row) => (
                                            <StyledTableRow key={row.code} sx={styles.TableRow}>
                                                <StyledTableCell><Checkbox /></StyledTableCell>
                                                <StyledTableCell>{row.code}</StyledTableCell>
                                                <StyledTableCell>{row.name}</StyledTableCell>
                                                <StyledTableCell>{row.age}</StyledTableCell>
                                                <StyledTableCell>{row.department}</StyledTableCell>
                                                <StyledTableCell>{row.targetGroup}</StyledTableCell>
                                                <StyledTableCell>{row.criteriaGroup}</StyledTableCell>
                                                <StyledTableCell>{row.lastExamination}</StyledTableCell>
                                                <StyledTableCell>{row.frequency}</StyledTableCell>
                                                <StyledTableCell>{row.dueDate}</StyledTableCell>
                                                <StyledTableCell>{row.dueInMonth}</StyledTableCell>
                                            </StyledTableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Grid container spacing={2} direction='row' xm={12} sm={12} md={12} lg={12} xl={12} justifyContent='space-between'>
                                <Grid item sx={{ fontWeight: 'bold' }}>
                                    Showing 0 to 0 of 0 entries
                                </Grid>
                                <Grid item>
                                    <Button variant='contained' sx={{ mr: 2, textTransform: 'none' }} disabled>Previous</Button>
                                    <Button variant='contained' sx={{ textTransform: 'none' }} disabled>Next</Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Box>
            </React.Fragment >
        </>
    )
}