import sharedImports from '../../Comman/sharedImports';
const {
    React,
    Box,
    Grid,
    Paper,
    Slide,
    Table,
    AppBar,
    Button,
    styled,
    Select,
    Dialog,
    Toolbar,
    Checkbox,
    MenuItem,
    DemoItem,
    TableRow,
    TableCell,
    TextField,
    TableHead,
    TableBody,
    PropTypes,
    DatePicker,
    Typography,
    DialogTitle,
    CssBaseline,
    FormControl,
    AdapterDayjs,
    DialogActions,
    TableContainer,
    DialogContent,
    useScrollTrigger,
    ModeOutlinedIcon,
    tableCellClasses,
    FormControlLabel,
    DialogContentText,
    CancelOutlinedIcon,
    SearchOutlinedIcon,
    LocalizationProvider,
    FileCopyOutlinedIcon,
    UnfoldMoreOutlinedIcon,
    AddCircleOutlineOutlinedIcon,
} = sharedImports;

const drawerWidth = 300;
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));
const reportListRows = [
    { employeeCode: 9, employeeName: 'CONTRACT WORKER (SELF)-', incidentTime: '', incidentDate: '13/12/2017', placeofIncident: '', incidentType: '', furtherAction: '' },
    { employeeCode: 9, employeeName: 'CONTRACT WORKER (SELF)-', incidentTime: '', incidentDate: '13/12/2017', placeofIncident: '', incidentType: '', furtherAction: '' },
    { employeeCode: 9, employeeName: 'CONTRACT WORKER (SELF)-', incidentTime: '', incidentDate: '13/12/2017', placeofIncident: '', incidentType: '', furtherAction: '' },
    { employeeCode: 9, employeeName: 'CONTRACT WORKER (SELF)-', incidentTime: '', incidentDate: '13/12/2017', placeofIncident: '', incidentType: '', furtherAction: '' },
    { employeeCode: 9, employeeName: 'CONTRACT WORKER (SELF)-', incidentTime: '', incidentDate: '13/12/2017', placeofIncident: '', incidentType: '', furtherAction: '' },
    { employeeCode: 9, employeeName: 'CONTRACT WORKER (SELF)-', incidentTime: '', incidentDate: '13/12/2017', placeofIncident: '', incidentType: '', furtherAction: '' },
    { employeeCode: 9, employeeName: 'CONTRACT WORKER (SELF)-', incidentTime: '', incidentDate: '13/12/2017', placeofIncident: '', incidentType: '', furtherAction: '' },
    { employeeCode: 9, employeeName: 'CONTRACT WORKER (SELF)-', incidentTime: '', incidentDate: '13/12/2017', placeofIncident: '', incidentType: '', furtherAction: '' },
    { employeeCode: 9, employeeName: 'CONTRACT WORKER (SELF)-', incidentTime: '', incidentDate: '13/12/2017', placeofIncident: '', incidentType: '', furtherAction: '' },
    { employeeCode: 9, employeeName: 'CONTRACT WORKER (SELF)-', incidentTime: '', incidentDate: '13/12/2017', placeofIncident: '', incidentType: '', furtherAction: '' },
    { employeeCode: 9, employeeName: 'CONTRACT WORKER (SELF)-', incidentTime: '', incidentDate: '13/12/2017', placeofIncident: '', incidentType: '', furtherAction: '' },
    { employeeCode: 9, employeeName: 'CONTRACT WORKER (SELF)-', incidentTime: '', incidentDate: '13/12/2017', placeofIncident: '', incidentType: '', furtherAction: '' },
    { employeeCode: 9, employeeName: 'CONTRACT WORKER (SELF)-', incidentTime: '', incidentDate: '13/12/2017', placeofIncident: '', incidentType: '', furtherAction: '' },
    { employeeCode: 9, employeeName: 'CONTRACT WORKER (SELF)-', incidentTime: '', incidentDate: '13/12/2017', placeofIncident: '', incidentType: '', furtherAction: '' },
    { employeeCode: 9, employeeName: 'CONTRACT WORKER (SELF)-', incidentTime: '', incidentDate: '13/12/2017', placeofIncident: '', incidentType: '', furtherAction: '' },
];
function HideOnScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
};

const styles = {
    TableRow: {
        th: {
            svg: {
                float: 'right',
                color: '#1976d2'
            },
        },
        td: {
            border: '1px solid lightgray',
        }
    },
    h: {
        minHeight: '40px !important'
    },
    bgWhite: {
        bgcolor: 'white',
        color: 'black'
    }
}

export default function IncidentListing(props) {
    const [state, setState] = React.useState({
        ParticipantType: '',
        IncidentType: ''
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setState(prevState => ({
            ...prevState,
            [name]: value
        }));
    };


    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };


    return (
        <>
            <React.Fragment>
                <Box>
                    <CssBaseline />
                    <HideOnScroll {...props}>
                        <AppBar
                            sx={{
                                width: { sm: `calc(100% - ${drawerWidth}px)` },
                                ml: { sm: `${drawerWidth}px` },
                                mt: { xs: '53px', sm: '64px' },
                                zIndex: 1099
                            }}
                            elevation={1}
                        >
                            <Toolbar sx={[styles.h, styles.bgWhite]}>
                                <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Grid item xs={12} md={11}>
                                        <Typography variant="h6" noWrap component="div" sx={{ m: 1, fontWeight: 'bold' }}>
                                            Incident Listing
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={1}>
                                        <React.Fragment>
                                            <Button
                                                variant="contained"
                                                startIcon={<AddCircleOutlineOutlinedIcon />}
                                                onClick={handleClickOpen}
                                                item xs={12} md={1} sx={{ textTransform: 'none', m: 1 }}>
                                                Add
                                            </Button>
                                            <Dialog
                                                open={open}
                                                onClose={handleClose}
                                                PaperProps={{
                                                    component: 'form',
                                                    onSubmit: (event) => {
                                                        event.preventDefault();
                                                        const formData = new FormData(event.currentTarget);
                                                        const formJson = Object.fromEntries(formData.entries());
                                                        const email = formJson.email;
                                                        console.log(email);
                                                        handleClose();
                                                    },
                                                }}
                                            >
                                                <DialogTitle>Subscribe</DialogTitle>
                                                <DialogContent>
                                                    <DialogContentText>
                                                        To subscribe to this website, please enter your email address here. We
                                                        will send updates occasionally.
                                                    </DialogContentText>
                                                    <TextField
                                                        autoFocus
                                                        required
                                                        margin="dense"
                                                        id="name"
                                                        name="email"
                                                        label="Email Address"
                                                        type="email"
                                                        fullWidth
                                                        variant="standard"
                                                    />
                                                </DialogContent>
                                                <DialogActions>
                                                    <Button sx={{ textTransform: 'none' }} onClick={handleClose}>Cancel</Button>
                                                    <Button sx={{ textTransform: 'none' }} type="submit">Subscribe</Button>
                                                </DialogActions>
                                            </Dialog>
                                        </React.Fragment>
                                    </Grid>
                                </Grid>
                            </Toolbar>
                        </AppBar>
                    </HideOnScroll>
                    <Box sx={{ mt: 8, mb: 2, mx: 2, bgcolor: 'white', p: 3, borderRadius: 1 }}>
                        <Box component="div" xs={12}>
                            <Grid container spacing={1} sx={{ mb: 1 }}>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Participant Type</Box>
                                    <Grid item xs={12} md={11}>
                                        <FormControl fullWidth>
                                            <Select
                                                name="ParticipantType"
                                                value={state.ParticipantType}
                                                onChange={handleChange}
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Without label' }}
                                            >
                                                <MenuItem sx={{ ml: 3 }} value="">
                                                    <>-Select-</>
                                                </MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={1}>Consumable</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={2}>Laboratory</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={3}>Medicine</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={4}>SETRID-COLD</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Employee Code</Box>
                                    <Grid item xs={12} md={11}>
                                        <TextField
                                            required
                                            id="filled-search"
                                            fullWidth
                                            size='small'
                                            placeholder='Employee Code'
                                        />
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Incident Type</Box>
                                    <Grid item xs={12} md={11}>
                                        <FormControl fullWidth>
                                            <Select
                                                name="IncidentType"
                                                value={state.IncidentType}
                                                onChange={handleChange}
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Without label' }}
                                            >
                                                <MenuItem sx={{ ml: 3 }} value="">
                                                    <>-Select-</>
                                                </MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={1}>Consumable</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={2}>Laboratory</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={3}>Medicine</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={4}>SETRID-COLD</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Incident Time</Box>
                                    <Grid item xs={12} md={11}>
                                        <TextField
                                            required
                                            id="filled-search"
                                            fullWidth
                                            size='small'
                                            placeholder='Incident Time'
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid container spacing={1} sx={{ mb: 3 }}>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>From Date</Box>
                                    <Grid item xs={12} md={11}>
                                        <FormControl fullWidth>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DemoItem >
                                                    <DatePicker />
                                                </DemoItem>
                                            </LocalizationProvider>
                                        </FormControl>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>To Date</Box>
                                    <Grid item xs={12} md={11}>
                                        <FormControl fullWidth>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DemoItem >
                                                    <DatePicker />
                                                </DemoItem>
                                            </LocalizationProvider>
                                        </FormControl>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} md={3} sx={{ display: 'flex', alignItems: 'end' }}>
                                    <FormControlLabel
                                        value="end"
                                        control={<Checkbox />}
                                        label="Pending Fitness"
                                        labelPlacement="end"
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} direction='row' xm={12} sm={12} md={12} lg={12} xl={12} justifyContent='center'>
                                <Grid item>
                                    <Button variant='contained' sx={{ textTransform: 'none' }} startIcon={<SearchOutlinedIcon />}>Search</Button>
                                </Grid>
                                <Grid item>
                                    <Button variant='outlined' sx={{ textTransform: 'none' }} startIcon={<CancelOutlinedIcon />}>Clear</Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                    <Box sx={{ mt: 2, mb: 7, mx: 2, bgcolor: 'white', p: 3, borderRadius: 1 }}>
                        <Box component="div" xs={12}>
                            <Box component='h3' sx={{ m: 0, mb: 2 }}>List</Box>
                            <Grid container spacing={2} direction='row' xm={12} sm={12} md={12} lg={12} xl={12} justifyContent='space-between' sx={{ mb: 2 }}>
                                <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Grid item sx={{ mr: 2, display: 'flex', alignItems: 'center' }}>
                                        <Box component='span' sx={{ fontWeight: 'bold' }} >Show </Box>
                                        <TextField
                                            id="outlined-number"
                                            type="number"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            defaultValue='10'
                                            sx={{ width: '9ch' }}
                                            size='small'
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Box component='span' sx={{ fontWeight: 'bold' }}>entries </Box>
                                        <Button variant='outlined' sx={{ textTransform: 'none' }} startIcon={<FileCopyOutlinedIcon />}>Export</Button>
                                    </Grid>
                                </Grid>
                                <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Box component='span' sx={{ fontWeight: 'bold' }}>Search: </Box>
                                    <TextField
                                        id="filled-search"
                                        type="search"
                                        size='small'
                                    />
                                </Grid>
                            </Grid>
                            <TableContainer component={Paper} sx={{ mb: 2 }}>
                                <Table aria-label="customized table">
                                    <TableHead>
                                        <TableRow sx={styles.TableRow}>
                                            <StyledTableCell>Employee Code<UnfoldMoreOutlinedIcon /></StyledTableCell>
                                            <StyledTableCell>Employee Name<UnfoldMoreOutlinedIcon /></StyledTableCell>
                                            <StyledTableCell>Incident Time<UnfoldMoreOutlinedIcon /></StyledTableCell>
                                            <StyledTableCell>Incident Date<UnfoldMoreOutlinedIcon /></StyledTableCell>
                                            <StyledTableCell>Place of Incident<UnfoldMoreOutlinedIcon /></StyledTableCell>
                                            <StyledTableCell>Incident Type<UnfoldMoreOutlinedIcon /></StyledTableCell>
                                            <StyledTableCell>Further Action<UnfoldMoreOutlinedIcon /></StyledTableCell>
                                            <StyledTableCell><UnfoldMoreOutlinedIcon /></StyledTableCell>
                                            <StyledTableCell><UnfoldMoreOutlinedIcon /></StyledTableCell>
                                            <StyledTableCell><UnfoldMoreOutlinedIcon /></StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {reportListRows.map((row) => (
                                            <StyledTableRow key={row.employeeCode} sx={styles.TableRow}>
                                                <StyledTableCell> {row.employeeCode} </StyledTableCell>
                                                <StyledTableCell>{row.employeeName}</StyledTableCell>
                                                <StyledTableCell>{row.incidentTime}</StyledTableCell>
                                                <StyledTableCell>{row.incidentDate}</StyledTableCell>
                                                <StyledTableCell>{row.placeofIncident}</StyledTableCell>
                                                <StyledTableCell>{row.incidentType}</StyledTableCell>
                                                <StyledTableCell>{row.furtherAction}</StyledTableCell>
                                                <StyledTableCell><ModeOutlinedIcon sx={{ color: 'blue' }} /></StyledTableCell>
                                                <StyledTableCell>Create Log</StyledTableCell>
                                                <StyledTableCell>Fitness</StyledTableCell>
                                            </StyledTableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Grid container spacing={2} direction='row' xm={12} sm={12} md={12} lg={12} xl={12} justifyContent='space-between' sx={{ mb: 2 }}>
                                <Grid item>
                                    Showing 0 to 0 of 0 entries
                                </Grid>
                                <Grid item>
                                    <Button variant='contained' sx={{ mr: 2, textTransform: 'none' }} disabled>Previous</Button>
                                    <Button variant='contained' sx={{ textTransform: 'none' }} disabled>Next</Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Box>
            </React.Fragment >
        </>
    )
}