import sharedImports from '../../../Comman/sharedImports';
const {
    React,
    AppBar,
    Box,
    Paper,
    Radio,
    Stack,
    Grid,
    Card,
    Slide,
    Table,
    Select,
    styled,
    Button,
    Toolbar,
    TableRow,
    PropTypes,
    TableBody,
    MenuItem,
    TableHead,
    TableCell,
    TextField,
    Typography,
    FormControl,
    SearchIcon,
    CardContent,
    UnfoldMoreIcon,
    TableContainer,
    ArrowDropDownIcon,
    HighlightOffIcon,
    tableCellClasses,
    useScrollTrigger,
    FileCopyOutlinedIcon,
} = sharedImports;


const drawerWidth = 300;
function HideOnScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
};


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));
const reportListRows = [
    { EmployeeCode: '30006669', EmployeeName: 'Prerak Thekadi', MobileNumber: '9099055204', CompanyEmail: 'Preak.Thekadi@adani.com', BloodGroup: 'O-', Location: 'Ahmedabad', State: 'Gujarat', City: 'Ahmedabad' },
    { EmployeeCode: '30104736', EmployeeName: 'Amit Mishra', MobileNumber: '9909967793', CompanyEmail: 'Amit.Mishra4@adani.com', BloodGroup: 'O-', Location: 'Ahmedabad', State: 'Gujarat', City: 'Ahmedabad' },
    { EmployeeCode: '30105017', EmployeeName: 'Sanidhya Jain', MobileNumber: '9825030951', CompanyEmail: 'Sanidhya.Jain@adani.com', BloodGroup: 'O-', Location: 'Ahmedabad', State: 'Gujarat', City: 'Ahmedabad' },
];

const styles = {
    TableRow: {
        th: {
            p: 1,
            svg: {
                float: 'right',
                color: '#1976d2'
            },
        },
        td: {
            border: '1px solid lightgray',
            p: 1,
        }
    },
    h: {
        minHeight: '40px !important'
    },
    bgWhite: {
        bgcolor: 'white',
        color: 'black'
    }
}

export default function DenseAppBar(props) {
    const [age, setAge] = React.useState('');
    const [selectedValue, setSelectedValue] = React.useState('a');

    const handleChange = (event) => {
        setAge(event.target.value);
        setSelectedValue(event.target.value);
    };
    return (
        <>
            <HideOnScroll {...props}>
                <AppBar
                    sx={{
                        width: { sm: `calc(100% - ${drawerWidth}px)` },
                        ml: { sm: `${drawerWidth}px` },
                        mt: { xs: '53px', sm: '64px' },
                        zIndex: 1099
                    }}
                    elevation={1}
                >
                    <Toolbar sx={[styles.h, styles.bgWhite]}>
                        <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Grid item>
                                <Typography variant="h6" noWrap component="div" sx={{ m: 1, fontWeight: 'bold' }}>
                                    Privilege Health Service
                                </Typography>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
            </HideOnScroll>

            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    '& > :not(style)': {
                        m: 1.5,
                        mt: 8,
                        width: "100%",
                    },
                }}
            >


                <Card variant="outlined" sx={{ padding: 3 }}>
                    <CardContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={3}>
                                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Service Type</Typography>
                                <Grid item xs={12} md={11}>
                                    <FormControl fullWidth>
                                        <Select
                                            value={age}
                                            onChange={handleChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem sx={{ ml: 3 }} value="">
                                                <>Blood Group List</>
                                            </MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={1}>Councelling</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={2}>Diabetes Prevention Awareness</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={3}>First Aid Training</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={4}>OBESITY MANAGMENT</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>

                    </CardContent>

                    <div>
                        <Radio
                            checked={selectedValue === 'a'}
                            onChange={handleChange}
                            value="a"
                            name="radio-buttons"
                            inputProps={{ 'aria-label': 'A' }}
                        />
                        Location
                        <Radio
                            checked={selectedValue === 'b'}
                            onChange={handleChange}
                            value="b"
                            name="radio-buttons"
                            inputProps={{ 'aria-label': 'B' }}
                        />
                        State
                    </div>

                    <Grid container spacing={2} sx={{ mt: 2 }}>
                        <Grid item xs={12} md={3} >
                            <Typography variant="body1" sx={{ ml: 2, fontWeight: 'bold' }}>State</Typography>
                            <Grid item xs={12} md={11} sx={{ ml: 2 }}>
                                <FormControl fullWidth>
                                    <Select
                                        value={age}
                                        onChange={handleChange}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        <MenuItem sx={{ ml: 3 }} value="">
                                            <>GUJARAT</>
                                        </MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={1}>Councelling</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={2}>Diabetes Prevention Awareness</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={3}>First Aid Training</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={4}>OBESITY MANAGMENT</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <Typography variant="body1">
                                <Box sx={{ fontWeight: 'bold' }}>City</Box>
                            </Typography>
                            <Grid item xs={12} md={11}>
                                <TextField
                                    required
                                    id="filled-search"
                                    fullWidth
                                    size='small'
                                    value='ahmedabad'
                                />
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={3} >
                            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Value</Typography>
                            <Grid item xs={12} md={11}>
                                <FormControl fullWidth>
                                    <Select
                                        value={age}
                                        onChange={handleChange}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        <MenuItem sx={{ ml: 3 }} value="">
                                            <>O-</>
                                        </MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={1}>Councelling</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={2}>Diabetes Prevention Awareness</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={3}>First Aid Training</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={4}>OBESITY MANAGMENT</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Stack spacing={1} direction="row" sx={{ justifyContent: 'center', padding: 2 }}>

                        <Button variant="contained" sx={{ textTransform: 'none', backgroundColor: '#3b6a99' }} startIcon={<SearchIcon />}>
                            Search
                        </Button>

                        <Button variant="outlined" sx={{ textTransform: 'none' }} startIcon={<HighlightOffIcon />}>
                            Clear
                        </Button>

                    </Stack>

                </Card>
            </Box>

            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    '& > :not(style)': {
                        m: 1.5,
                        mt: 0,
                        width: "100%",
                    },
                }}
            >


                <Card variant="outlined" sx={{ padding: 2 }}>
                    <CardContent>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={1} sx={{ display: 'flex', alignItems: 'center' }}>
                                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Show</Typography>
                                <TextField
                                    id="outlined-number"
                                    type="number"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    defaultValue="10"
                                />
                            </Grid>
                            <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center' }}>
                                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Pages</Typography>
                                <Button variant='outlined' sx={{ ml: 1, textTransform: 'none' }} startIcon={<FileCopyOutlinedIcon />}>Export < ArrowDropDownIcon /></Button>
                            </Grid>
                            <Grid item xs={8} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
                                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Search:</Typography>
                                <TextField
                                    required
                                    id="filled-search"
                                />
                            </Grid>
                        </Grid>
                    </CardContent>

                    <TableContainer component={Paper}>
                        <Table aria-label="customized table" sx={{ mr: .1 }} >
                            <TableHead>
                                <TableRow sx={styles.TableRow}>
                                    <StyledTableCell>Employee Code<UnfoldMoreIcon /></StyledTableCell>
                                    <StyledTableCell >Employee Name <UnfoldMoreIcon /></StyledTableCell>
                                    <StyledTableCell >Mobile Number<UnfoldMoreIcon /></StyledTableCell>
                                    <StyledTableCell >CompanyEmail <UnfoldMoreIcon /></StyledTableCell>
                                    <StyledTableCell >BloodGroup <UnfoldMoreIcon /></StyledTableCell>
                                    <StyledTableCell >Location <UnfoldMoreIcon /></StyledTableCell>
                                    <StyledTableCell >State<UnfoldMoreIcon /></StyledTableCell>
                                    <StyledTableCell >City<UnfoldMoreIcon /></StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {reportListRows.map((row) => (
                                    <StyledTableRow key={row.EmployeeCode} sx={styles.TableRow}>

                                        <StyledTableCell> {row.EmployeeCode} </StyledTableCell>
                                        <StyledTableCell>{row.EmployeeName}</StyledTableCell>
                                        <StyledTableCell>{row.MobileNumber}</StyledTableCell>
                                        <StyledTableCell>{row.CompanyEmail}</StyledTableCell>
                                        <StyledTableCell>{row.BloodGroup}</StyledTableCell>
                                        <StyledTableCell>{row.Location}</StyledTableCell>
                                        <StyledTableCell>{row.State}</StyledTableCell>
                                        <StyledTableCell>{row.City}</StyledTableCell>
                                    </StyledTableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Grid sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2, mt: 1 }}>
                        <Typography variant="body1">Showing 1 to 3 of 3 entries</Typography>
                        <Grid>
                            <Button variant="contained" disabled sx={{ mr: 1, textTransform: 'none' }}>Prev</Button>
                            <Button sx={{ backgroundColor: 'lightGrey', mr: 1 }}>1</Button>
                            <Button variant="contained" disabled sx={{ textTransform: 'none' }}>Next</Button>
                        </Grid>
                    </Grid>
                </Card>
            </Box>

        </>
    );
}
