
import sharedImports from '../../../Comman/sharedImports';
const {
    React,
    PropTypes,
    Box,
    Grid,
    Slide,
    Link,
    Select,
    AppBar,
    Button,
    Toolbar,
    MenuItem,
    TextField,
    DeleteOutlineIcon,
    SaveOutlinedIcon,
    Typography,
    styled,
    Paper,
    TableRow,
    TableContainer,
    TableBody,
    TableHead,
    Table,
    CssBaseline,
    TableCell,
    tableCellClasses,
    FormControl,
    EditNoteOutlinedIcon,
    useScrollTrigger,
    CancelOutlinedIcon,
    LocalizationProvider,
    DemoItem,
    DatePicker,
    AdapterDayjs,
    FormatListBulletedOutlinedIcon,
} = sharedImports;

const drawerWidth = 300;
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));
const reportListRows = [
    { expenseType: 'Petrol' },
    { expenseType: 'Maintenance' }
];
function HideOnScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
};
const styles = {
    TableRow: {
        th: {
            border: '1px solid #a3a3a3',
            svg: {
                float: 'right',
                color: '#1976d2'
            },
        },
        td: {
            border: '1px solid lightgray',
        }
    },
    h: {
        minHeight: '40px !important'
    },
    bgWhite: {
        bgcolor: 'white',
        color: 'black'
    }
}

export default function Expense(props) {

    const [state, setState] = React.useState({
        Vehicle: ''
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setState(prevState => ({
            ...prevState,
            [name]: value
        }));
    };


    return (
        <>
            <React.Fragment>
                <Box>
                    <CssBaseline />
                    <HideOnScroll {...props}>
                        <AppBar
                            sx={{
                                width: { sm: `calc(100% - ${drawerWidth}px)` },
                                ml: { sm: `${drawerWidth}px` },
                                mt: { xs: '53px', sm: '64px' },
                                zIndex: 1099
                            }}
                            elevation={1}
                        >
                            <Toolbar sx={[styles.h, styles.bgWhite]}>
                                <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Grid item>
                                        <Typography variant="h6" noWrap component="div" sx={{ m: 1, fontWeight: 'bold' }}>
                                            Vehicle Maintenance update
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Button variant='outlined' startIcon={<SaveOutlinedIcon />} sx={{ m: 1, textTransform: 'none' }}> Save</Button>
                                        <Button variant='contained' startIcon={<EditNoteOutlinedIcon />} sx={{ m: 1, textTransform: 'none' }}> Edit</Button>
                                        <Button variant='outlined' startIcon={<DeleteOutlineIcon />} sx={{ m: 1, textTransform: 'none' }}> Delete</Button>
                                        <Button variant='outlined' startIcon={<CancelOutlinedIcon />} sx={{ m: 1, textTransform: 'none' }}> Clear</Button>
                                    </Grid>
                                    <Grid item>
                                        <Link to='/dashboard/support/expense/create'>
                                            <Button variant='contained' startIcon={<FormatListBulletedOutlinedIcon />} sx={{ m: 1, textTransform: 'none' }}> List </Button>
                                        </Link>
                                    </Grid>
                                </Grid>
                            </Toolbar>
                        </AppBar>
                    </HideOnScroll>
                    <Box sx={{ mt: 6, mb: 2, mx: 2, bgcolor: 'white', p: 3, borderRadius: 1 }}>
                        <Box component="div" xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Vehicle <Box component='span' sx={{ color: 'red' }}>*</Box></Box>
                                    <Grid item xs={12} md={11}>
                                        <FormControl fullWidth>
                                            <Select
                                                name="Vehicle"
                                                value={state.Vehicle}
                                                onChange={handleChange}
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Without label' }}
                                            >
                                                <MenuItem sx={{ ml: 3 }} value="">
                                                    <>-Select-</>
                                                </MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={1}>Ambulance-1</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={2}>MEDICINE</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={3}>Other expense</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Maintenance Date <Box component='span' sx={{ color: 'red' }}>*</Box></Box>
                                    <Grid item xs={12} md={11}>
                                        <FormControl fullWidth>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DemoItem >
                                                    <DatePicker />
                                                </DemoItem>
                                            </LocalizationProvider>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={3}>
                                    <Typography variant="body1">
                                        <Box sx={{ fontWeight: 'bold' }}>KM Reading</Box>
                                    </Typography>
                                    <Grid item xs={12} md={11}>
                                        <TextField
                                            required
                                            id="filled-search"
                                            fullWidth
                                            size='small'
                                            placeholder='KM Reading'
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <Box>Remark</Box>
                                    <TextField
                                        id="filled-search"
                                        type="search"
                                        placeholder='Remark'
                                        multiline
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                    <Box sx={{ mt: 2, mb: 7, mx: 2, bgcolor: 'white', p: 3, borderRadius: 1 }}>
                        <Box component="div" xs={12}>
                            <Box component='p' sx={{ m: 0, mb: 2, color: 'gray', fontSize: 20 }}>Vehicle Maintenance Detail</Box>
                            <TableContainer component={Paper} sx={{ mb: 2 }}>
                                <Table aria-label="customized table">
                                    <TableHead>
                                        <TableRow sx={styles.TableRow}>
                                            <StyledTableCell>Expense Type</StyledTableCell>
                                            <StyledTableCell>Amount</StyledTableCell>
                                            <StyledTableCell>Remark</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {reportListRows.map((row) => (
                                            <StyledTableRow key={row.expenseType} sx={styles.TableRow}>
                                                <StyledTableCell sx={{ width: '15%', p: '8px' }}>{row.expenseType}</StyledTableCell>
                                                <StyledTableCell sx={{ width: '25%', p: '8px' }}>
                                                    <TextField
                                                        id="filled-search"
                                                        type="search"
                                                        size='small'
                                                        fullWidth
                                                    />
                                                </StyledTableCell>
                                                <StyledTableCell sx={{ width: '65%', p: '8px' }}>
                                                    <TextField
                                                        id="filled-search"
                                                        type="search"
                                                        multiline
                                                        fullWidth
                                                    />
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Box>
                </Box>
            </React.Fragment>
        </>
    )
}