import sharedImports from '../../Comman/sharedImports';
const {
    React,
    Box,
    Link,
    clsx,
    alpha,
    styled,
    useTheme,
    Typography,
    useTreeItem,
    TreeItem2Icon,
    TreeItem2Root,
    ArrowRightIcon,
    SimpleTreeView,
    TreeItem2Content,
    ArrowDropDownIcon,
    TreeItem2Provider,
    SaveAsOutlinedIcon,
    TreeItem2IconContainer,
    TreeItem2GroupTransition,
    ArrowRightAltOutlinedIcon,
    DomainAddOutlinedIcon,
} = sharedImports;

const examination = [
    { id: 3, label: 'Examination All', to: 'examination/examination-all' },
    { id: 4, label: 'Letter Issue', to: 'examination/letter-issue' },
    { id: 5, label: 'Unfit Listing', to: 'examination/unfit-listing' },
    { id: 6, label: 'Fitness Certificate', to: 'examination/fitness-certificate' },
    { id: 7, label: 'Periodic Dashboard', to: 'examination/periodic-dashboard' },
    { id: 8, label: 'Examination Counselling', to: 'examination/examination-counselling' },
]
const examinationReport = [
    { id: 10, label: 'Personal Detail Report', to: 'examination/examination-report/personal-detail-report' },
    { id: 11, label: 'Clinical Examination Report', to: 'examination/examination-report/clinical-examination-report' },
    { id: 12, label: 'Compliance Report', to: 'examination/examination-report/compliance-report' },
    { id: 13, label: 'Health Card Report', to: 'examination/examination-report/health-card-report' },
    { id: 14, label: 'Pulmonary Report', to: 'examination/examination-report/pulmonary-report' },
    { id: 15, label: 'Hearing Report', to: 'examination/examination-report/hearing-report' },
    { id: 16, label: 'Vision Report', to: 'examination/examination-report/vision-report' },
    { id: 17, label: 'Diagnosis Report', to: 'examination/examination-report/diagnosis-report' },
    { id: 18, label: 'Examination Status Report', to: 'examination/examination-report/examination-status-report' },
    { id: 19, label: 'Other Examination Report', to: 'examination/examination-report/other-examination-report' },
    { id: 20, label: 'Export Test Report', to: 'examination/examination-report/export-test-report' },
    { id: 21, label: 'Export Clinical Report', to: 'examination/examination-report/export-clinical-report' },
    { id: 22, label: 'Export Vital Report', to: 'examination/examination-report/export-vital-report' },
    { id: 23, label: 'Export Audiometry Report', to: 'examination/examination-report/export-audiometry-report' },
    { id: 24, label: 'Export Personal Detail Report', to: 'examination/examination-report/export-personal-detail-report' },
    { id: 25, label: 'Export Reports', to: 'examination/examination-report/export-reports' },
    { id: 26, label: 'Examination Summary Report', to: 'examination/examination-report/examination-summary-report' },
]
const freezePeriodicList = [
    { id: 28, label: 'Examination Planning', to: 'examination/freeze-periodic-list/examination-planning' },
    { id: 29, label: 'Freeze Periodic All List', to: 'examination/freeze-periodic-list/freeze-periodic-all-list' },
    { id: 30, label: 'Freeze Periodic List', to: 'examination/freeze-periodic-list/freeze-periodic-list' },
    { id: 31, label: 'Test Due', to: 'examination/freeze-periodic-list/test-due' },
    { id: 32, label: 'Criteria Group', to: 'examination/freeze-periodic-list/criteria-group' },
    { id: 33, label: 'Search Criteria Group', to: 'examination/freeze-periodic-list/search-criteria-group' },
    { id: 34, label: 'Employee Freeze Status', to: 'examination/freeze-periodic-list/employee-freeze-status' },
]

const CustomTreeItemRoot = styled(TreeItem2Root)(({ theme }) => ({
    color: theme.palette.text.secondary,
}));
const CustomTreeItemContent = styled(TreeItem2Content)(({ theme }) => ({
    marginBottom: theme.spacing(0.3),
    color: theme.palette.text.secondary,
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    '&.expanded': {
        fontWeight: theme.typography.fontWeightRegular,
    },
    '&:hover': {
        backgroundColor: theme.palette.action.hover,
    },
    '&.focused, &.selected, &.selected.focused': {
        backgroundColor: `var(--tree-view-bg-color, ${theme.palette.action.selected})`,
        color: '#000000',
    },
}));
const CustomTreeItemIconContainer = styled(TreeItem2IconContainer)(({ theme }) => ({
    marginRight: theme.spacing(1),
}));
const CustomTreeItemGroupTransition = styled(TreeItem2GroupTransition)(
    ({ theme }) => ({
        marginLeft: 0,
        [`& .content`]: {
            paddingLeft: theme.spacing(2),
        },
    }),
);
const CustomTreeItem = React.forwardRef(function CustomTreeItem(props, ref) {
    const theme = useTheme();
    const {
        id,
        itemId,
        label,
        disabled,
        children,
        bgColor,
        color,
        labelIcon: LabelIcon,
        labelInfo,
        colorForDarkMode,
        bgColorForDarkMode,
        ...other
    } = props;
    const {
        getRootProps,
        getContentProps,
        getIconContainerProps,
        getLabelProps,
        getGroupTransitionProps,
        status,
    } = useTreeItem({ id, itemId, children, label, disabled, rootRef: ref });
    const style = {
        '--tree-view-color': theme.palette.mode !== 'dark' ? color : colorForDarkMode,
        '--tree-view-bg-color':
            theme.palette.mode !== 'dark' ? bgColor : bgColorForDarkMode,
    };

    return (
        <TreeItem2Provider itemId={itemId}>
            <CustomTreeItemRoot {...getRootProps({ ...other, style })}>
                <CustomTreeItemContent
                    {...getContentProps({
                        className: clsx('content', {
                            expanded: status.expanded,
                            selected: status.selected,
                            focused: status.focused,
                        }),
                    })}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexGrow: 1,
                            alignItems: 'center',
                            p: 0,
                            pr: 0,
                        }}
                    >
                        <Box component={LabelIcon} color="inherit" sx={{ mr: 1 }} />
                        <Typography
                            {...getLabelProps({
                                variant: 'body2',
                                sx: { display: 'flex', fontWeight: 'inherit', flexGrow: 1 },
                            })}
                        />
                        <Typography variant="caption" color="inherit">
                            {labelInfo}
                        </Typography>
                    </Box>
                    <CustomTreeItemIconContainer {...getIconContainerProps()}>
                        <TreeItem2Icon status={status} />
                    </CustomTreeItemIconContainer>
                </CustomTreeItemContent>
                {children && (
                    <CustomTreeItemGroupTransition {...getGroupTransitionProps()} />
                )}
            </CustomTreeItemRoot>
        </TreeItem2Provider>
    );
});

function EndIcon() {
    return <div style={{ width: 24 }} />;
}

export default function Examination() {
    return (
        <SimpleTreeView
            aria-label="Examination"
            defaultExpandedItems={['3']}
            defaultSelectedItems="5"
            slots={{
                expandIcon: ArrowRightIcon,
                collapseIcon: ArrowDropDownIcon,
                endIcon: EndIcon,
            }}
            sx={{ flexGrow: 1, maxWidth: 400 }}
        >
            <CustomTreeItem itemId="1" label="Examination" labelIcon={DomainAddOutlinedIcon}>
                <CustomTreeItem itemId="2" label="Examination" labelIcon={SaveAsOutlinedIcon}>
                    {examination.map((item) => (
                        <Link to={item.to}>
                            <CustomTreeItem
                                itemId={item.id}
                                label={item.label}
                                labelIcon={ArrowRightAltOutlinedIcon}
                                color="#000000"
                                bgColor="#e8f0fe"
                                colorForDarkMode="#B8E7FB"
                                bgColorForDarkMode={alpha('#00b4ff', 0.2)}
                            />
                        </Link>
                    ))}
                </CustomTreeItem>
                <CustomTreeItem itemId="9" label="Examination Report" labelIcon={SaveAsOutlinedIcon}>
                    {examinationReport.map((item) => (
                        <Link to={item.to}>
                            <CustomTreeItem
                                itemId={item.id}
                                label={item.label}
                                labelIcon={ArrowRightAltOutlinedIcon}
                                color="#000000"
                                bgColor="#e8f0fe"
                                colorForDarkMode="#B8E7FB"
                                bgColorForDarkMode={alpha('#00b4ff', 0.2)}
                            />
                        </Link>
                    ))}
                </CustomTreeItem>
                <CustomTreeItem itemId="27" label="Freeze Periodic List" labelIcon={SaveAsOutlinedIcon}>
                    {freezePeriodicList.map((item) => (
                        <Link to={item.to}>
                            <CustomTreeItem
                                itemId={item.id}
                                label={item.label}
                                labelIcon={ArrowRightAltOutlinedIcon}
                                color="#000000"
                                bgColor="#e8f0fe"
                                colorForDarkMode="#B8E7FB"
                                bgColorForDarkMode={alpha('#00b4ff', 0.2)}
                            />
                        </Link>
                    ))}
                </CustomTreeItem>
            </CustomTreeItem>
        </SimpleTreeView>
    );
}