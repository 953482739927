import sharedImports from '../../../Comman/sharedImports';
const {
    React,
    PropTypes,
    Box,
    Grid,
    Slide,
    Select,
    AppBar,
    Button,
    Toolbar,
    MenuItem,
    Typography,
    TextField,
    CssBaseline,
    FormControl,
    useScrollTrigger,
    styled,
    UnfoldMoreOutlinedIcon,
    TableBody,
    CancelOutlinedIcon,
    TableContainer,
    TableCell,
    Paper,
    CheckOutlinedIcon,
    tableCellClasses,
    Table,
    TableHead,
    TableRow,
    NoteAddOutlinedIcon,
} = sharedImports;


const drawerWidth = 300;
const VisuallyHiddenInput = styled('input')({});
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));
const reportListRows = [
    { storeLocation: 'Uttar Pradesh', code: 30006323, employee: 'palak', missingParameterName: 'BloodPressure, BMI', round: 'Annual Health Checkup 2021-22' },
    { storeLocation: 'Uttar Pradesh', code: 30012733, employee: 'Deepak Sharma', missingParameterName: 'BMI', round: 'Annual Health Checkup 2021-22' },
    { storeLocation: 'Uttar Pradesh', code: 30021904, employee: 'Prabhu Dhulipala', missingParameterName: 'BloodPressure, BMI', round: 'Annual Health Checkup 2021-22' },
    { storeLocation: 'Uttar Pradesh', code: 30030150, employee: 'Shrinand Parikh', missingParameterName: 'BMI', round: 'Annual Health Checkup 2021-22' },
    { storeLocation: 'Uttar Pradesh', code: 30033146, employee: 'Ramesh Mamidala', missingParameterName: 'BMI', round: 'Annual Health Checkup 2021-22' },
    { storeLocation: 'Uttar Pradesh', code: 30009887, employee: 'Nirav Chhatiyawala', missingParameterName: 'BloodPressure, BMI', round: 'Annual Health Checkup 2021-22' },
    { storeLocation: 'Uttar Pradesh', code: 30033844, employee: 'Harish Champenera', missingParameterName: 'BMI', round: 'Annual Health Checkup 2021-22' },
];
function HideOnScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
};
const styles = {
    TableRow: {
        th: {
            width: '12.5%',
            svg: {
                float: 'right',
                color: '#1976d2'
            },
        },
        td: {
            border: '1px solid lightgray',
        }
    },
    h: {
        minHeight: '40px !important'
    },
    bgWhite: {
        bgcolor: 'white',
        color: 'black'
    }
}

export default function ImportExaminationAll(props) {
    const [age, setAge] = React.useState('');
    function handleChange(event) {
        setAge(event.target.value);
    }
    return (
        <>
            <React.Fragment>
                <Box>
                    <CssBaseline />
                    <HideOnScroll {...props}>
                        <AppBar
                            sx={{
                                width: { sm: `calc(100% - ${drawerWidth}px)` },
                                ml: { sm: `${drawerWidth}px` },
                                mt: { xs: '53px', sm: '64px' },
                                zIndex: 1099
                            }}
                            elevation={1}
                        >
                            <Toolbar sx={[styles.h, styles.bgWhite]}>
                                <Typography variant="h6" sx={{ fontWeight: 'bold' }} noWrap component="div">
                                    Import Examination All
                                </Typography>
                            </Toolbar>
                        </AppBar>
                    </HideOnScroll>
                    <Box sx={{ mt: 7, mx: 2, bgcolor: 'white', p: 3, borderRadius: 1 }}>
                        <Box component="div" xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Examination Type</Box>
                                    <Grid item xs={12} md={11}>
                                        <FormControl fullWidth>
                                            <Select
                                                value={age}
                                                onChange={handleChange}
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Without label' }}
                                            >
                                                <MenuItem sx={{ ml: 3 }} value="">
                                                    <>-All-</>
                                                </MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={1}>Periodic</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={2}>Pre Employment</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={3}>Pre Placement</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={4}>Pre Retirement</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={5}>Post Sickness</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={6}>Wellness</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={7}>Other</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={8}>Post Accident</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} sx={{ mb: 2 }}>
                                <Grid item xs={12} md={4}>
                                    <Button sx={{ textTransform: 'none' }} href='#text-button'>Download Template</Button>
                                </Grid>
                                <Grid item xs={12} md={8}>
                                    <Button sx={{ textTransform: 'none' }} href='#text-button'>Download Pulmonary And Audiometry Template</Button>
                                </Grid>
                            </Grid>
                            <Grid spacing={2} sx={{ mb: 2 }}>
                                <VisuallyHiddenInput type='file' />
                            </Grid>
                            <Grid container spacing={2} sx={{ mb: 2 }}>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        id="outlined-multiline-static"
                                        placeholder='Copy and paste data here'
                                        multiline
                                        fullWidth
                                        rows={4}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} direction='row' xm={12} sm={12} md={12} lg={12} xl={12} justifyContent='center'>
                                <Grid item>
                                    <Button variant='contained' sx={{ textTransform: 'none' }} startIcon={<CheckOutlinedIcon />}>Validate</Button>
                                </Grid>
                                <Grid item>
                                    <Button variant='outlined' sx={{ textTransform: 'none' }} startIcon={<NoteAddOutlinedIcon />}>Export TargetGroup</Button>
                                </Grid>
                                <Grid item>
                                    <Button variant='outlined' sx={{ textTransform: 'none' }} startIcon={<CancelOutlinedIcon />}>Clear</Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                    <Box sx={{ mt: 2, mb: 2, mx: 2, bgcolor: 'white', p: 3, borderRadius: 1 }}>
                        <Box component="div" xs={12}>
                            <Box component='h4' sx={{ m: 0, mb: 2 }}>Health Index Missing Test Parameter</Box>
                            <Grid container spacing={2} sx={{ mb: 5 }}>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Round Group <Box component='span' sx={{ color: 'red' }}>*</Box></Box>
                                    <Grid item xs={12} md={11}>
                                        <FormControl fullWidth>
                                            <Select
                                                value={age}
                                                onChange={handleChange}
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Without label' }}
                                            >
                                                <MenuItem sx={{ ml: 3 }} value="">
                                                    <>-All-</>
                                                </MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={1}>Hazard Group Checkup 2023</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={2}>Annual Health Checkup 2022-23</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={3}>Hazard Group Checkup 2022</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={4}>Annual Health Checkup 2021-22</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={3}>Hazard Group Checkup 2021</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={4}>Annual Health Checkup 2020-21</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={3}>Hazard Group Checkup 2020</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={4}>Annual Health Checkup 2019-21</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={3}>Hazard Group Checkup 2019</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={4}>Annual Health Checkup 2018-19</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={3}>Hazard Group Checkup 2018</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={4}>Annual Health Checkup 2017-18</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={3}>Hazard Group Checkup 2017</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={4}>Annual Health Checkup 2016-2017</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={3}>Hazard Group Checkup 2016</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={4}>Annual Health Checkup 2015-2016</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={3}>Hazard Group Checkup 2015</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={4}>Annual Health Checkup 2014-2015</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={3}>Hazard Group Checkup 2014</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={4}>Annual Health Checkup 2013-2014</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Sub Location</Box>
                                    <Grid item xs={12} md={11}>
                                        <FormControl fullWidth>
                                            <Select
                                                value={age}
                                                onChange={handleChange}
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Without label' }}
                                            >
                                                <MenuItem sx={{ ml: 3 }} value="">
                                                    <>-All-</>
                                                </MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={1}>Uttar Pradesh</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={2}>Laboratory</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={3}>Medicine</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={4}>SETRID-COLD</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Round</Box>
                                    <Grid item xs={12} md={11}>
                                        <FormControl fullWidth>
                                            <Select
                                                value={age}
                                                onChange={handleChange}
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Without label' }}
                                            >
                                                <MenuItem sx={{ ml: 3 }} value="">
                                                    <>-All-</>
                                                </MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={1}>Uttar Pradesh</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={2}>Laboratory</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={3}>Medicine</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={4}>SETRID-COLD</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} direction='row' xm={12} sm={12} md={12} lg={12} xl={12}>
                                <Grid item>
                                    <Button variant='contained' sx={{textTransform: 'none'}} startIcon={<CheckOutlinedIcon />}>Get Missing Parameter For Test</Button>
                                </Grid>
                                <Grid item>
                                    <Button variant='outlined' sx={{textTransform: 'none'}} startIcon={<NoteAddOutlinedIcon />}>Export TargetGroup</Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                    <Box sx={{ mt: 2, mb: 7, mx: 2, bgcolor: 'white', p: 3, borderRadius: 1 }}>
                        <Box component="div" xs={12}>
                            <TableContainer component={Paper}>
                                <Table aria-label="customized table">
                                    <TableHead>
                                        <TableRow sx={styles.TableRow}>
                                            <StyledTableCell>Store Location<UnfoldMoreOutlinedIcon /></StyledTableCell>
                                            <StyledTableCell>Code<UnfoldMoreOutlinedIcon /></StyledTableCell>
                                            <StyledTableCell>Employee<UnfoldMoreOutlinedIcon /></StyledTableCell>
                                            <StyledTableCell>Missing Parameter Name<UnfoldMoreOutlinedIcon /></StyledTableCell>
                                            <StyledTableCell>Round<UnfoldMoreOutlinedIcon /></StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {reportListRows.map((row) => (
                                            <StyledTableRow key={row.storeLocation} sx={styles.TableRow}>
                                                <StyledTableCell> {row.storeLocation} </StyledTableCell>
                                                <StyledTableCell>{row.code}</StyledTableCell>
                                                <StyledTableCell>{row.employee}</StyledTableCell>
                                                <StyledTableCell>{row.missingParameterName}</StyledTableCell>
                                                <StyledTableCell>{row.round}</StyledTableCell>
                                            </StyledTableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Box>
                </Box>
            </React.Fragment>
        </>
    )
}