import sharedImports from '../../../Comman/sharedImports';
const {
    React,
    PropTypes,
    Box,
    Grid,
    Slide,
    AppBar,
    FormControl,
    Select,
    RadioGroup,
    Radio,
    FileCopyOutlinedIcon,
    TableContainer,
    Paper,
    Table,
    styled,
    FormControlLabel,
    TableCell,
    tableCellClasses,
    TableHead,
    TableBody,
    MenuItem,
    TableRow,
    Button,
    Toolbar,
    Typography,
    CssBaseline,
    useScrollTrigger,
} = sharedImports;


const drawerWidth = 300;
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));
const reportListRows = [
    { no: 1, employeeCode: 10002319, employeeName: 'PRANAV MEHTA', department: 'FINANCE & ACCOUNTS' }
];
function HideOnScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
};
const styles = {
    TableRow: {
        th: {
            svg: {
                float: 'right',
                color: '#1976d2'
            },
        },
        td: {
            border: '1px solid lightgray',
        }
    },
    h: {
        minHeight: '40px !important'
    },
    bgWhite: {
        bgcolor: 'white',
        color: 'black'
    }
}

export default function PeriodicDashboard(props) {

    const [age, setAge] = React.useState('');
    function handleChange(event, newValue) {
        setAge(event.target.value);
    }

    return (
        <>
            <React.Fragment>
                <Box>
                    <CssBaseline />
                    <HideOnScroll {...props}>
                        <AppBar
                            sx={{
                                width: { sm: `calc(100% - ${drawerWidth}px)` },
                                ml: { sm: `${drawerWidth}px` },
                                mt: { xs: '53px', sm: '64px' },
                                zIndex: 1099
                            }}
                            elevation={1}
                        >
                            <Toolbar sx={[styles.h, styles.bgWhite]}>
                                <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Grid item>
                                        <Typography variant="h6" noWrap component="div" sx={{ m: 1, fontWeight: 'bold' }}>
                                            Periodic Dashboard
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Toolbar>
                        </AppBar>
                    </HideOnScroll>
                    <Box sx={{ mt: 5, mb: 2, mx: 2, bgcolor: 'white', p: 3, borderRadius: 1 }}>
                        <Box component="div" xs={12}>
                            <Grid container spacing={4} sx={{ mb: 2 }}>
                                <Grid item xs={12} md={12}>
                                    <FormControl>
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="row-radio-buttons-group"
                                        >
                                            <FormControlLabel value="female" control={<Radio />} label="Employee Wise List" />
                                            <FormControlLabel value="male" control={<Radio />} label="Department Wise List" />
                                            <FormControlLabel value="other" control={<Radio />} label="Company Wise List" />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container spacing={4} sx={{ mb: 2 }}>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Main Type</Box>
                                    <Grid item xs={12} md={11}>
                                        <FormControl fullWidth>
                                            <Select
                                                value={age}
                                                onChange={handleChange}
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Without label' }}
                                            >
                                                <MenuItem sx={{ ml: 3 }} value="">
                                                    <>-All-</>
                                                </MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={1}>Councelling</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={2}>Diabetes Prevention Awareness</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={3}>First Aid Training</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={4}>OBESITY MANAGMENT</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Round</Box>
                                    <Grid item xs={12} md={11}>
                                        <FormControl fullWidth>
                                            <Select
                                                value={age}
                                                onChange={handleChange}
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Without label' }}
                                            >
                                                <MenuItem sx={{ ml: 3 }} value="">
                                                    <>-All-</>
                                                </MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={1}>Councelling</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={2}>Diabetes Prevention Awareness</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={3}>First Aid Training</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={4}>OBESITY MANAGMENT</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container spacing={4} sx={{ mb: 2 }}>
                                <Grid item xs={12} md={3}>
                                    <Box component='div' sx={{ bgcolor: '#00adff', p: 3, borderRadius: 2, color: '#ffffff' }}>
                                        <h1>3476</h1>
                                        <p>Total</p>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Box component='div' sx={{ bgcolor: 'green', p: 3, borderRadius: 2, color: '#ffffff' }}>
                                        <h1>0</h1>
                                        <p>In Progress</p>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Box component='div' sx={{ bgcolor: '#ff8100', p: 3, borderRadius: 2, color: '#ffffff' }}>
                                        <h1>0</h1>
                                        <p>Approved</p>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Box component='div' sx={{ bgcolor: 'red', p: 3, borderRadius: 2, color: '#ffffff' }}>
                                        <h1>0</h1>
                                        <p>Completed</p>
                                    </Box>
                                </Grid>
                            </Grid>
                            <Grid container spacing={4}>
                                <Grid item xs={12} md={3}>
                                    <Box component='div' sx={{ bgcolor: '#d800ff', p: 3, borderRadius: 2, color: '#ffffff' }}>
                                        <h1>0</h1>
                                        <p>Parked</p>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Box component='div' sx={{ bgcolor: '#00b7b7', p: 3, borderRadius: 2, color: '#ffffff' }}>
                                        <h1>0</h1>
                                        <p>Closed</p>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Box component='div' sx={{ bgcolor: '#5244c3', p: 3, borderRadius: 2, color: '#ffffff' }}>
                                        <h1>756</h1>
                                        <p>Not Started</p>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Box>
                <Box sx={{ mt: 2, mb: 7, mx: 2, p: 3, bgcolor: 'white', borderRadius: 1 }}>
                    <Box component="div" xs={12}>
                        <Box sx={{fontWeight: 'bold'}}>Employee List of Total In Annual Health Checkup 2022-23 Round</Box>
                        <Box component="div" xs={12}>
                            <Grid container spacing={2} direction='row' xm={12} sm={12} md={12} lg={12} xl={12} justifyContent='end' sx={{ mb: 2 }}>
                                <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Grid item>
                                        <Button variant='outlined' sx={{textTransform: 'none'}} startIcon={<FileCopyOutlinedIcon />}>Export</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <TableContainer component={Paper} sx={{ mb: 2 }}>
                                <Table aria-label="customized table">
                                    <TableHead>
                                        <TableRow sx={styles.TableRow}>
                                            <StyledTableCell>No</StyledTableCell>
                                            <StyledTableCell>Employee Code</StyledTableCell>
                                            <StyledTableCell>Employee Name</StyledTableCell>
                                            <StyledTableCell>Department</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {reportListRows.map((row) => (
                                            <StyledTableRow key={row.no} sx={styles.TableRow}>
                                                <StyledTableCell>{row.no}</StyledTableCell>
                                                <StyledTableCell>{row.employeeCode}</StyledTableCell>
                                                <StyledTableCell>{row.employeeName}</StyledTableCell>
                                                <StyledTableCell>{row.department}</StyledTableCell>
                                            </StyledTableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Grid container spacing={2} direction='row' xm={12} sm={12} md={12} lg={12} xl={12} justifyContent='space-between'>
                                <Grid item sx={{fontWeight: 'bold'}}>
                                    Showing 1 to 10 of 10 entries
                                </Grid>
                                <Grid item>
                                    <Button variant='contained' sx={{ mr: 2, textTransform: 'none' }} disabled>Previous</Button>
                                    <Button variant='contained' sx={{textTransform: 'none'}} disabled>Next</Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Box>
            </React.Fragment>
        </>
    )
}