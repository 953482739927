import sharedImports from '../../../Comman/sharedImports';
const {
    React,
    Box,
    Grid,
    Stack,
    Tab,
    Tabs,
    Slide,
    Alert,
    styled,
    Button,
    AppBar,
    Toolbar,
    PropTypes,
    Typography,
    TextField,
    useMediaQuery,
    CloudUploadIcon,
    useScrollTrigger,
    CachedOutlinedIcon,
    DeleteOutlinedIcon,
    ControlPointOutlinedIcon,
} = sharedImports;

const drawerWidth = 300;
function HideOnScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
};


const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});
function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

export default function UploadEmployeePhoto(props) {

    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const isSmallScreenBtn = useMediaQuery('(max-width:1193px)');
    const styles = {
        root: {
            backgroundColor: '#f1efef',
        },
        firstForm: {
            padding: '16px 16px 16px 16px',
        },
        formControl: {
            width: 1
        },
        TableRow: {
            th: {
                width: '50%',
            },
            td: {
                border: '1px solid lightgray',
            }
        },
        h: {
            minHeight: '40px !important'
        },
        mb2: {
            marginBottom: 2,
        },
        bgWhite: {
            backgroundColor: 'white !important',
            color: 'black'
        },
        bRadius: {
            borderRadius: 1,
            marginTop: '55px'
        },
        wFull: {
            width: '100%'
        },
        dFlex: {
            display: 'flex'
        },
        justifySB: {
            justifyContent: 'space-between',
        },
        justifyEnd: {
            justifyContent: 'end',
        },
        align: {
            alignItems: 'center'
        },
        color: {
            color: 'red',
        },
        bgGray: {
            backgroundColor: 'lightgray',
        },
        fwBold: {
            fontWeight: 'bold'
        },
        padding: {
            padding: 1
        },
        pAlart: {
            padding: '0px 30px'
        }
    }


    return (
        <Box sx={[styles.root, styles.wFull]}>
            <HideOnScroll {...props}>
                <AppBar
                    sx={{
                        width: { sm: `calc(100% - ${drawerWidth}px)` },
                        ml: { sm: `${drawerWidth}px` },
                        mt: { xs: '53px', sm: '64px' },
                        zIndex: 1099
                    }}
                    elevation={1}
                >
                    <Toolbar sx={[styles.h, styles.bgWhite]}>
                        <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Grid item>
                                <Typography variant="h6" noWrap component="div" sx={{ m: 1, fontWeight: 'bold' }}>
                                    Upload Employee Photo
                                </Typography>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
            </HideOnScroll>
            <Box sx={[styles.firstForm]}>
                <Box sx={[styles.bgWhite, styles.bRadius]}>
                    <Grid container spacing={2} sx={styles.pAlart}>
                        <Grid item xs={12}>
                            <Alert variant="soft" sx={styles.color}>
                                Notes: Upload 120*120 image for best quality <br />Node: Photo selected to be uploaded must be renamed by Employee Code
                            </Alert>
                        </Grid>
                    </Grid>
                    <Box sx={{ maxWidth: { xs: 1, sm: 1 }, bgcolor: 'background.paper', borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            variant="scrollable"
                            scrollButtons
                            allowScrollButtonsMobile
                            aria-label="scrollable force tabs example"
                        >
                            <Tab sx={{ textTransform: 'none', color: '#1976d2' }} label="Employee Photo" />
                            <Tab sx={{ textTransform: 'none', color: '#1976d2' }} label="Employee Sign" />
                            <Tab sx={{ textTransform: 'none', color: '#1976d2' }} label="Employee Certificate" />
                            <Tab sx={{ textTransform: 'none', color: '#1976d2' }} label="Search" />
                        </Tabs>
                    </Box>
                    <CustomTabPanel value={value} index={0}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} md={3}>
                                <Box sx={{ fontWeight: 'bold' }}>Enter Employee Code for Remove</Box>
                                <Grid item xs={12} md={11}>
                                    <TextField
                                        required
                                        id="filled-search"
                                        fullWidth
                                        size='small'
                                        placeholder="Code Like"
                                    />
                                </Grid>
                            </Grid>

                            <Grid item xs={12}>
                                <Stack spacing={2} direction={isSmallScreenBtn ? 'column' : 'row'} justifyContent='center' sx={styles.marginBottom2}>
                                    <Button variant="contained" sx={{ textTransform: 'none' }} startIcon={<ControlPointOutlinedIcon />}>Add Photo</Button>
                                    <Button
                                        component="label"
                                        role={undefined}
                                        variant="outlined"
                                        sx={{ textTransform: 'none' }}
                                        tabIndex={-1}
                                        startIcon={<CloudUploadIcon />}
                                    >
                                        Upload file
                                        <VisuallyHiddenInput type="file" />
                                    </Button>
                                    <Button variant="outlined" sx={{ textTransform: 'none' }} startIcon={<DeleteOutlinedIcon />}>Remove Photo</Button>
                                    <Button variant="outlined" sx={{ textTransform: 'none' }} startIcon={<CachedOutlinedIcon />}>Refresh</Button>
                                </Stack>
                            </Grid>
                        </Grid>
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={1}>
                        CustomTabPanel 2
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={2}>
                        CustomTabPanel 3
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={3}>
                        CustomTabPanel 4
                    </CustomTabPanel>
                </Box>
            </Box>
        </Box>
    )
}