import sharedImports from '../../../Comman/sharedImports';
const {
    React,
    Box,
    Grid,
    Alert,
    Radio,
    Slide,
    Select,
    Button,
    AppBar,
    Toolbar,
    MenuItem,
    TextField,
    PropTypes,
    RadioGroup,
    FormControl,
    Typography,
    useScrollTrigger,
    FormControlLabel,
    CheckOutlinedIcon,
    NoteAddOutlinedIcon,
} = sharedImports;

const drawerWidth = 300;
function HideOnScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
};

export default function ImportContractEmployee(props) {

    const [age, setAge] = React.useState('');
    const handleChange = (event, newValue) => {
        setAge(event.target.value);
    };
    const styles = {
        root: {
            backgroundColor: '#f1efef',
        },
        firstForm: {
            padding: '16px 16px 16px 16px',
        },
        formControl: {
            width: 1
        },
        TableRow: {
            th: {
                width: '50%',
            },
            td: {
                border: '1px solid lightgray',
            }
        },
        h: {
            minHeight: '40px !important'
        },
        mb2: {
            marginBottom: 2,
        },
        bgWhite: {
            backgroundColor: 'white !important',
            color: 'black'
        },
        bRadius: {
            borderRadius: 1,
            marginTop: '65px'
        },
        wFull: {
            width: '100%'
        },
        dFlex: {
            display: 'flex'
        },
        justifySB: {
            justifyContent: 'space-between',
        },
        justifyEnd: {
            justifyContent: 'end',
        },
        align: {
            alignItems: 'center'
        },
        color: {
            color: 'red',
        },
        bgGray: {
            backgroundColor: 'lightgray',
        },
        fwBold: {
            fontWeight: 'bold'
        },
        padding: {
            padding: 1
        },
        pAlart: {
            padding: '0px 30px'
        }
    }


    return (
        <Box sx={[styles.root, styles.wFull]}>
            <HideOnScroll {...props}>
                <AppBar
                    sx={{
                        width: { sm: `calc(100% - ${drawerWidth}px)` },
                        ml: { sm: `${drawerWidth}px` },
                        mt: { xs: '53px', sm: '64px' },
                        zIndex: 1099
                    }}
                    elevation={1}
                >
                    <Toolbar sx={[styles.h, styles.bgWhite]}>
                        <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Grid item>
                                <Typography variant="h6" noWrap component="div" sx={{ m: 1, fontWeight: 'bold' }}>
                                    View Report
                                </Typography>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
            </HideOnScroll>
            <Box sx={styles.firstForm}>
                <Box sx={[styles.bgWhite, styles.wFull, styles.p20, styles.bRadius]} className='MainPadding'>
                    <Grid container spacing={2} sx={styles.pAlart}>
                        <Grid item xs={12}>
                            <Alert variant="soft" sx={styles.color}>
                                Notes : Text data to be Separated <br /> Mandatoy Fields : Code, First Name, Last Name, Date Of Birth, Gender, Status, Date Of Joining, Department, Employee Type Group, <br /> Employee Type, Employee Sub Type, Grade, Designation, Target Group, Process Type & Employee Group
                            </Alert>
                        </Grid>
                    </Grid>

                    <Grid container spacing={1} sx={[styles.pAlart, { padding: '13px 30px 30px 30px' }]}>
                        <Grid item xs={12} md={3}>
                            <Box sx={{ fontWeight: 'bold' }}>Employee Type</Box>
                            <Grid item xs={12} md={11}>
                                <FormControl fullWidth>
                                    <Select
                                        value={age}
                                        onChange={handleChange}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        <MenuItem sx={{ ml: 3 }} value="">
                                            <>-Select-</>
                                        </MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={10}>Ten</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={20}>Twenty</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={30}>Thirty</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Box sx={{ fontWeight: 'bold' }}>Employee Sub Type</Box>
                            <Grid item xs={12} md={11}>
                                <FormControl fullWidth>
                                    <Select
                                        value={age}
                                        onChange={handleChange}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        <MenuItem sx={{ ml: 3 }} value="">
                                            <>-Select-</>
                                        </MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={10}>Ten</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={20}>Twenty</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={30}>Thirty</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={3}></Grid>
                        <Grid item xs={12} md={3}></Grid>

                        <Grid item xs={12} md={3}>
                            <Box sx={{ fontWeight: 'bold' }}>Employee Type</Box>
                            <Grid item xs={12} md={11}>
                                <FormControl fullWidth>
                                    <Select
                                        value={age}
                                        onChange={handleChange}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        <MenuItem sx={{ ml: 3 }} value="">
                                            <>-Select-</>
                                        </MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={10}>Ten</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={20}>Twenty</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={30}>Thirty</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Box sx={{ fontWeight: 'bold' }}>Employee Sub Type</Box>
                            <Grid item xs={12} md={11}>
                                <FormControl fullWidth>
                                    <Select
                                        value={age}
                                        onChange={handleChange}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        <MenuItem sx={{ ml: 3 }} value="">
                                            <>-Select-</>
                                        </MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={10}>Ten</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={20}>Twenty</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={30}>Thirty</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={3}></Grid>
                        <Grid item xs={12} md={3}></Grid>

                        <Grid item xs={12} md={3}>
                            <Box sx={{ fontWeight: 'bold' }}>Employee Type</Box>
                            <Grid item xs={12} md={11}>
                                <FormControl fullWidth>
                                    <Select
                                        value={age}
                                        onChange={handleChange}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        <MenuItem sx={{ ml: 3 }} value="">
                                            <>-Select-</>
                                        </MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={10}>Ten</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={20}>Twenty</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={30}>Thirty</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Box sx={{ fontWeight: 'bold' }}>Employee Sub Type</Box>
                            <Grid item xs={12} md={11}>
                                <FormControl fullWidth>
                                    <Select
                                        value={age}
                                        onChange={handleChange}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        <MenuItem sx={{ ml: 3 }} value="">
                                            <>-Select-</>
                                        </MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={10}>Ten</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={20}>Twenty</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={30}>Thirty</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={3}></Grid>
                        <Grid item xs={12} md={3}></Grid>

                        <Grid item xs={12} md={3}>
                            <Box sx={{ fontWeight: 'bold' }}>Employee Type</Box>
                            <Grid item xs={12} md={11}>
                                <FormControl fullWidth>
                                    <Select
                                        value={age}
                                        onChange={handleChange}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        <MenuItem sx={{ ml: 3 }} value="">
                                            <>-Select-</>
                                        </MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={10}>Ten</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={20}>Twenty</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={30}>Thirty</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Box sx={{ fontWeight: 'bold' }}>Employee Sub Type</Box>
                            <Grid item xs={12} md={11}>
                                <FormControl fullWidth>
                                    <Select
                                        value={age}
                                        onChange={handleChange}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        <MenuItem sx={{ ml: 3 }} value="">
                                            <>-Select-</>
                                        </MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={10}>Ten</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={20}>Twenty</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={30}>Thirty</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={3}></Grid>
                        <Grid item xs={12} md={3}></Grid>

                        <Grid item xs={12} md={3}>
                            <Box sx={{ fontWeight: 'bold' }}>Employee Type</Box>
                            <Grid item xs={12} md={11}>
                                <FormControl fullWidth>
                                    <Select
                                        value={age}
                                        onChange={handleChange}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        <MenuItem sx={{ ml: 3 }} value="">
                                            <>-Select-</>
                                        </MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={10}>Ten</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={20}>Twenty</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={30}>Thirty</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Box sx={{ fontWeight: 'bold' }}>Employee Sub Type</Box>
                            <Grid item xs={12} md={11}>
                                <FormControl fullWidth>
                                    <Select
                                        value={age}
                                        onChange={handleChange}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        <MenuItem sx={{ ml: 3 }} value="">
                                            <>-Select-</>
                                        </MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={10}>Ten</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={20}>Twenty</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={30}>Thirty</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={3}></Grid>
                        <Grid item xs={12} md={3}></Grid>

                        <Grid item xs={12} md={3}>
                            <Box sx={{ fontWeight: 'bold' }}>Employee Type</Box>
                            <Grid item xs={12} md={11}>
                                <FormControl fullWidth>
                                    <Select
                                        value={age}
                                        onChange={handleChange}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        <MenuItem sx={{ ml: 3 }} value="">
                                            <>-Select-</>
                                        </MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={10}>Ten</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={20}>Twenty</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={30}>Thirty</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={3} sx={{ display: 'flex', alignItems: 'end' }}>
                            <Button sx={{ textTransform: 'none' }} variant='contained'>By Default Set</Button>
                        </Grid>
                        <Grid item xs={12} md={6}></Grid>

                        <Grid item xs={12}>
                            <RadioGroup
                                row
                                aria-labelledby="demo-form-control-label-placement"
                                name="position"
                                defaultValue="top"
                            >
                                <FormControlLabel value="New Employee" control={<Radio />} label="New Employee" />
                                <FormControlLabel value="Update Employee" control={<Radio />} label="Update Employee" />
                            </RadioGroup>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Box sx={{ fontWeight: 'bold' }}>Download Template</Box>
                            <TextField
                                id="outlined-multiline-static"
                                placeholder='Copy and paste data here'
                                multiline
                                rows={8}
                                sx={{ width: '100%' }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button variant='outlined' sx={{ textTransform: 'none' }} startIcon={<CheckOutlinedIcon />}>Validate</Button>
                        </Grid>
                        <Grid item xs={12} md={3}></Grid>
                        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Button variant='contained' startIcon={<NoteAddOutlinedIcon />} sx={{ mr: 1, mb: 2, textTransform: 'none' }}>Export Department</Button>
                            <Button variant='contained' startIcon={<NoteAddOutlinedIcon />} sx={{ mr: 1, mb: 2, textTransform: 'none' }}>Export Designation</Button>
                            <Button variant='contained' startIcon={<NoteAddOutlinedIcon />} sx={{ mr: 1, mb: 2, textTransform: 'none' }}>Export Grade</Button>
                        </Grid>
                        <Grid item xs={12} md={3}></Grid>
                    </Grid>
                </Box>
            </Box>
        </Box>
    )
}