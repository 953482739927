import sharedImports from '../../../Comman/sharedImports';
const {
    React,
    PropTypes,
    Box,
    Grid,
    Slide,
    Select,
    AppBar,
    Button,
    Toolbar,
    MenuItem,
    TextField,
    styled,
    Paper,
    TableRow,
    TableContainer,
    TableBody,
    TableHead,
    VisibilityOutlinedIcon,
    Table,
    Typography,
    CssBaseline,
    FormControl,
    useScrollTrigger,
    CancelOutlinedIcon,
    TableCell,
    tableCellClasses,
} = sharedImports;


const drawerWidth = 300;
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));
const reportListRows = [
    { srNo: 1, employeeName: 'Mahesh Kumar Bhavsar', designation: '-' },
    { srNo: 2, employeeName: 'NILESH PARMAR', designation: 'ASSISTANT' },
];
function HideOnScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
};
const styles = {
    TableRow: {
        th: {
            svg: {
                float: 'right',
                color: '#1976d2'
            },
        },
        td: {
            padding: '5px',
            border: '1px solid lightgray',
        }
    },
    h: {
        minHeight: '40px !important'
    },
    bgWhite: {
        bgcolor: 'white',
        color: 'black'
    }
}

export default function InspectionReport(props) {

    const [state, setState] = React.useState({
        Report: '',
        Viewmode: '',
        Canteen: ''
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setState(prevState => ({
            ...prevState,
            [name]: value
        }));
    };


    return (
        <>
            <React.Fragment>
                <Box>
                    <CssBaseline />
                    <HideOnScroll {...props}>
                        <AppBar
                            sx={{
                                width: { sm: `calc(100% - ${drawerWidth}px)` },
                                ml: { sm: `${drawerWidth}px` },
                                mt: { xs: '58px', sm: '64px' },
                                zIndex: 1099
                            }}
                            elevation={1}
                        >
                            <Toolbar sx={[styles.h, styles.bgWhite]}>
                                <Grid container sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Grid item>
                                        <Typography variant="h6" noWrap component="div" sx={{ m: { xs: '0px', sm: '0px', fontWeight: 'bold' } }}>
                                            Inspection Report
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Toolbar>
                        </AppBar>
                    </HideOnScroll>
                    <Box sx={{ mt: 6, mb: 2, mx: 2, bgcolor: 'white', p: 3, borderRadius: 1 }}>
                        <Box component="div" xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Report <Box component='span' sx={{ color: 'red' }}>*</Box></Box>
                                    <Grid item xs={12} md={11}>
                                        <FormControl fullWidth>
                                            <Select
                                                name="Report"
                                                value={state.Report}
                                                onChange={handleChange}
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Without label' }}
                                            >
                                                <MenuItem sx={{ ml: 3 }} value="">
                                                    <>-All-</>
                                                </MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={1}>BP Instrument- First-aid room</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={2}>ECG</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={3}>Oxygen concentrator</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={4}>Concentrator</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={5}>Infusion pump</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={6}>Thermal Gun</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={7}>Glucometer</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={8}>Two para oximeter</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>View mode</Box>
                                    <Grid item xs={12} md={11}>
                                        <FormControl fullWidth>
                                            <Select
                                                name="Viewmode"
                                                value={state.Viewmode}
                                                onChange={handleChange}
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Without label' }}
                                            >
                                                <MenuItem sx={{ ml: 3 }} value="">
                                                    <>-All-</>
                                                </MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={1}>BP Instrument- First-aid room</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={2}>ECG</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={3}>Oxygen concentrator</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={4}>Concentrator</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={5}>Infusion pump</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={6}>Thermal Gun</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={7}>Glucometer</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={8}>Two para oximeter</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={12} xl={3} sx={{ display: 'flex', justifyContent: 'end', alignItems: 'end' }}>
                                    <Button variant='contained' startIcon={<VisibilityOutlinedIcon />} sx={{ mr: 0.5, mb: 2, textTransform: 'none' }}>View Report</Button>
                                    <Button variant='outlined' startIcon={<CancelOutlinedIcon />} sx={{ ml: 0.5, mb: 2, textTransform: 'none' }}>Clear</Button>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={3}>
                                    <Box>Canteen</Box>
                                    <Grid item xs={12} md={11}>
                                    <FormControl fullWidth>
                                        <Select
                                            name="Canteen"
                                            value={state.Canteen}
                                            onChange={handleChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem sx={{ ml: 3 }} value="">
                                                <em>-All-</em>
                                            </MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={1}>Councelling</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={2}>Diabetes Prevention Awareness</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={3}>First Aid Training</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={4}>OBESITY MANAGMENT</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                    <Box sx={{ mt: 2, mx: 2, bgcolor: 'white', p: 3, borderRadius: 1 }}>
                        <Box component="div" xs={12}>
                            <Box component='h2' sx={{ m: 0, mb: 3, p: 0 }}>Employee List</Box>
                            <TableContainer component={Paper} sx={{ mb: 2 }}>
                                <Table aria-label="customized table">
                                    <TableHead>
                                        <TableRow sx={styles.TableRow}>
                                            <StyledTableCell>Sr No</StyledTableCell>
                                            <StyledTableCell>Employee Name</StyledTableCell>
                                            <StyledTableCell>PPE`s Uses & Knowledge</StyledTableCell>
                                            <StyledTableCell>Condition of Uniform</StyledTableCell>
                                            <StyledTableCell>Status of Footwear</StyledTableCell>
                                            <StyledTableCell>Hair Status</StyledTableCell>
                                            <StyledTableCell>Nails Status</StyledTableCell>
                                            <StyledTableCell>Uniform</StyledTableCell>
                                            <StyledTableCell>Hand Washing Habits</StyledTableCell>
                                            <StyledTableCell>Personal Habits</StyledTableCell>
                                            <StyledTableCell>Dewormin</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {reportListRows.map((row) => (
                                            <StyledTableRow key={row.srNo} sx={styles.TableRow}>
                                                <StyledTableCell>{row.srNo}</StyledTableCell>
                                                <StyledTableCell>{row.employeeName}</StyledTableCell>
                                                <StyledTableCell> <TextField id="filled-search" type="search" fullWidth /> </StyledTableCell>
                                                <StyledTableCell> <TextField id="filled-search" type="search" fullWidth /> </StyledTableCell>
                                                <StyledTableCell> <TextField id="filled-search" type="search" fullWidth /> </StyledTableCell>
                                                <StyledTableCell> <TextField id="filled-search" type="search" fullWidth /> </StyledTableCell>
                                                <StyledTableCell> <TextField id="filled-search" type="search" fullWidth /> </StyledTableCell>
                                                <StyledTableCell> <TextField id="filled-search" type="search" fullWidth /> </StyledTableCell>
                                                <StyledTableCell> <TextField id="filled-search" type="search" fullWidth /> </StyledTableCell>
                                                <StyledTableCell> <TextField id="filled-search" type="search" fullWidth /> </StyledTableCell>
                                                <StyledTableCell> <TextField id="filled-search" type="search" fullWidth /> </StyledTableCell>
                                            </StyledTableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Box>
                </Box>
            </React.Fragment>
        </>
    )
}