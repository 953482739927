import sharedImports from '../../../Comman/sharedImports';
const {
    React,
    AppBar,
    Box,
    Tab,
    Tabs,
    Stack,
    Grid,
    Card,
    Paper,
    Table,
    CardContent,
    LocalizationProvider,
    DemoItem,
    DatePicker,
    AdapterDayjs,
    Button,
    styled,
    Toolbar,
    TextField,
    TableCell,
    tableCellClasses,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    FormControl,
    SearchIcon,
    Slide,
    PropTypes,
    useScrollTrigger,
    HighlightOffIcon,
    ArrowDropDownIcon,
    FileCopyOutlinedIcon,
    UnfoldMoreOutlinedIcon,
} = sharedImports;


const drawerWidth = 300;
function HideOnScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
};

const styles = {
    TableRow: {
        th: {
            p: 1,
            svg: {
                float: 'right',
                color: '#1976d2'
            },
        },
        td: {
            border: '1px solid lightgray',
            p: 1,
        }
    },
    h: {
        minHeight: '40px !important'
    },
    bgWhite: {
        bgcolor: 'white',
        color: 'black'
    }
}


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));



const style = {
    tableRow: {
        th: {
            p: 1,
            svg: {
                float: 'right'
            }
        }
    }
}



function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;



    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 6 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function DenseAppBar(props) {

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <>
            <HideOnScroll {...props}>
                <AppBar
                    sx={{
                        width: { sm: `calc(100% - ${drawerWidth}px)` },
                        ml: { sm: `${drawerWidth}px` },
                        mt: { xs: '53px', sm: '64px' },
                        zIndex: 1099
                    }}
                    elevation={1}
                >
                    <Toolbar sx={[styles.h, styles.bgWhite]}>
                        <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Grid item>
                                <Typography variant="h6" noWrap component="div" sx={{ m: 1, fontWeight: 'bold' }}>
                                    Nursing Listing
                                </Typography>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
            </HideOnScroll>

            <Card sx={{ m: 1, ml: 2, mt: 7, }}>

                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label="Regular Listing" sx={{ ml: 5, color: '#1976d2', textTransform: 'none' }}{...a11yProps(0)} />
                        <Tab label="Nursing Listing" sx={{ color: '#1976d2', textTransform: 'none' }} {...a11yProps(1)} />
                    </Tabs>
                </Box>

                <CustomTabPanel value={value} index={0}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={3} sx={{ mt: -6 }}>
                            <Box sx={{ fontWeight: 'bold' }}>Date </Box>
                            <Grid item xs={12} md={11}>
                                <FormControl fullWidth>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoItem >
                                            <DatePicker />
                                        </DemoItem>
                                    </LocalizationProvider>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={3} sx={{ mt: -6 }}>
                            <Typography variant="body1">
                                <Box sx={{ fontWeight: 'bold' }}>Doctor</Box>
                            </Typography>
                            <Grid item xs={12} md={11}>
                                <TextField
                                    required
                                    id="filled-search"
                                    fullWidth
                                    size='small'
                                    placeholder='Doctor'
                                />
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={3} sx={{ mt: -6 }}>
                            <Typography variant="body1">
                                <Box sx={{ fontWeight: 'bold' }}>Patient Code</Box>
                            </Typography>
                            <Grid container spacing={0}>
                                <Grid item xs={12} md={11}>
                                    <TextField
                                        required
                                        id="filled-search"
                                        fullWidth
                                        size='small'
                                        placeholder='Patient Code'
                                    />
                                </Grid>
                                <Grid item xs={12} md={1} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
                                    <SearchIcon />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={3} sx={{ mt: -6 }}>
                            <Typography variant="body1">
                                <Box sx={{ fontWeight: 'bold' }}>Patient Name </Box>
                            </Typography>
                            <Grid item xs={12} md={11}>
                                <TextField
                                    required
                                    id="filled-search"
                                    fullWidth
                                    size='small'
                                    placeholder='Patient Name '
                                />
                            </Grid>
                        </Grid>

                    </Grid>
                    <Stack spacing={1} direction="row" sx={{ justifyContent: 'center', mt: 2 }}>

                        <Button variant="contained" sx={{ textTransform: 'none', backgroundColor: '#0e559d' }} startIcon={<SearchIcon />}>
                            Search
                        </Button>

                        <Button variant="outlined" sx={{ textTransform: 'none' }} startIcon={<HighlightOffIcon />}>
                            Clear
                        </Button>
                    </Stack>

                    <CardContent>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={12} md={1} sx={{ display: 'flex', alignItems: 'center' }}>
                                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Show</Typography>
                                <TextField
                                    id="outlined-number"
                                    type="number"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    defaultValue="10"
                                />
                            </Grid>
                            <Grid item xs={12} md={3} sx={{ display: 'flex', alignItems: 'center' }}>
                                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>entries</Typography>
                                <Button variant='outlined' sx={{ textTransform: 'none', ml: 1 }} startIcon={<FileCopyOutlinedIcon />}>Export < ArrowDropDownIcon /> </Button>
                            </Grid>
                            <Grid item xs={12} md={8} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
                                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Search:</Typography>
                                <TextField
                                    required
                                    id="filled-search"
                                />
                            </Grid>
                        </Grid>
                    </CardContent>

                    <TableContainer component={Paper} sx={[style.tableRow, { mb: 2, ml: 2, width: '98%' }]} >
                        <Table aria-label="customized table" >
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>Employee Code <UnfoldMoreOutlinedIcon /> </StyledTableCell>
                                    <StyledTableCell >Employee Name <UnfoldMoreOutlinedIcon /> </StyledTableCell>
                                    <StyledTableCell >User Name <UnfoldMoreOutlinedIcon /> </StyledTableCell>
                                    <StyledTableCell >Remark <UnfoldMoreOutlinedIcon />  </StyledTableCell>
                                    <StyledTableCell > </StyledTableCell>
                                    <StyledTableCell > </StyledTableCell>
                                </TableRow>
                            </TableHead>
                        </Table>
                        <Grid sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>No Record Found!</Typography>
                        </Grid>
                    </TableContainer>
                    <Grid sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 3, ml: 2 }}>
                        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Showing 0 to 0 of 0 entries true</Typography>
                        <Grid item>
                            <Button variant='contained' sx={{ textTransform: 'none', mr: 1 }} disabled>Prev</Button>
                            <Button variant='contained' sx={{ textTransform: 'none', mr: 1.5 }} disabled>Next</Button>
                        </Grid>
                    </Grid>
                </CustomTabPanel>


                <CustomTabPanel value={value} index={1}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={3} sx={{ mt: -6 }}>
                            <Box sx={{ fontWeight: 'bold' }}>From Date </Box>
                            <Grid item xs={12} md={11}>
                                <FormControl fullWidth>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoItem >
                                            <DatePicker />
                                        </DemoItem>
                                    </LocalizationProvider>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={3} sx={{ mt: -6 }}>
                            <Box sx={{ fontWeight: 'bold' }}>To Date </Box>
                            <Grid item xs={12} md={11}>
                                <FormControl fullWidth>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoItem >
                                            <DatePicker />
                                        </DemoItem>
                                    </LocalizationProvider>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={3} sx={{ mt: -6 }}>
                            <Typography variant="body1">
                                <Box sx={{ fontWeight: 'bold' }}>Doctor </Box>
                            </Typography>
                            <Grid item xs={12} md={11}>
                                <TextField
                                    required
                                    id="filled-search"
                                    fullWidth
                                    size='small'
                                    placeholder='Doctor '
                                />
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={3} sx={{ mt: -6 }}>
                            <Typography variant="body1">
                                <Box sx={{ fontWeight: 'bold' }}>Patient Code</Box>
                            </Typography>
                            <Grid container spacing={0}>
                                <Grid item xs={12} md={11}>
                                    <TextField
                                        required
                                        id="filled-search"
                                        fullWidth
                                        size='small'
                                        placeholder='Patient Code'
                                    />
                                </Grid>
                                <Grid item xs={12} md={1} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
                                    <SearchIcon />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <Typography variant="body1">
                                <Box sx={{ fontWeight: 'bold' }}>Patient Name </Box>
                            </Typography>
                            <Grid item xs={12} md={11}>
                                <TextField
                                    required
                                    id="filled-search"
                                    fullWidth
                                    size='small'
                                    placeholder='Patient Name '
                                />
                            </Grid>
                        </Grid>




                    </Grid>
                    <Stack spacing={1} direction="row" sx={{ justifyContent: 'center', mt: 2 }}>

                        <Button variant="contained" sx={{ textTransform: 'none', backgroundColor: '#0e559d' }} startIcon={<SearchIcon />}>
                            Search
                        </Button>

                        <Button variant="outlined" sx={{ textTransform: 'none' }} startIcon={<HighlightOffIcon />}>
                            Clear
                        </Button>
                    </Stack>

                    <CardContent>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={12} md={1} sx={{ display: 'flex', alignItems: 'center' }}>
                                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Show</Typography>
                                <TextField
                                    id="outlined-number"
                                    type="number"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    defaultValue="10"
                                />
                            </Grid>
                            <Grid item xs={12} md={3} sx={{ display: 'flex', alignItems: 'center' }}>
                                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>entries</Typography>
                                <Button variant='outlined' sx={{ textTransform: 'none', ml: 1 }} startIcon={<FileCopyOutlinedIcon />}>Export < ArrowDropDownIcon /> </Button>
                            </Grid>
                            <Grid item xs={12} md={8} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
                                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Search:</Typography>
                                <TextField
                                    required
                                    id="filled-search"
                                />
                            </Grid>
                        </Grid>
                    </CardContent>

                    <TableContainer component={Paper} sx={[style.tableRow, { mb: 2, ml: 2, width: '98%' }]} >
                        <Table aria-label="customized table" >
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>Employee Code <UnfoldMoreOutlinedIcon /> </StyledTableCell>
                                    <StyledTableCell >Employee Name <UnfoldMoreOutlinedIcon /> </StyledTableCell>
                                    <StyledTableCell >User Name <UnfoldMoreOutlinedIcon /> </StyledTableCell>
                                    <StyledTableCell >Remark <UnfoldMoreOutlinedIcon />  </StyledTableCell>
                                    <StyledTableCell > </StyledTableCell>
                                    <StyledTableCell > </StyledTableCell>
                                </TableRow>
                            </TableHead>
                        </Table>
                        <Grid sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>No Record Found!</Typography>
                        </Grid>
                    </TableContainer>
                    <Grid sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 3, ml: 2 }}>
                        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Showing 0 to 0 of 0 entries true</Typography>
                        <Grid item>
                            <Button variant='contained' sx={{ textTransform: 'none', mr: 1 }} disabled>Prev</Button>
                            <Button variant='contained' sx={{ textTransform: 'none', mr: 1.5 }} disabled>Next</Button>
                        </Grid>
                    </Grid>



                </CustomTabPanel>

            </Card>


        </>
    );
}