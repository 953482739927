import sharedImports from '../../../Comman/sharedImports';
const {
    React,
    AppBar,
    Box,
    Radio,
    Stack,
    Grid,
    Card,
    Slide,
    CardContent,
    Select,
    Button,
    Toolbar,
    DemoItem,
    MenuItem,
    TextField,
    DatePicker,
    Typography,
    FormControl,
    AdapterDayjs,
    SearchIcon,
    PropTypes,
    useScrollTrigger,
    LocalizationProvider,
    HighlightOffIcon,
    RestartAltIcon,
    CheckIcon,
} = sharedImports;

const drawerWidth = 300;
function HideOnScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
};

const styles = {
    TableRow: {
        th: {
            p: 1,
            svg: {
                float: 'right',
                color: '#1976d2'
            },
        },
        td: {
            border: '1px solid lightgray',
            p: 1,
        }
    },
    h: {
        minHeight: '40px !important'
    },
    bgWhite: {
        bgcolor: 'white',
        color: 'black'
    }
}

export default function DenseAppBar(props) {
    const [age, setAge] = React.useState('');
    const [selectedValue, setSelectedValue] = React.useState('a');


    const handleChange = (event) => {
        setAge(event.target.value);
        setSelectedValue(event.target.value);
    };

    return (
        <>
            <HideOnScroll {...props}>
                <AppBar
                    sx={{
                        width: { sm: `calc(100% - ${drawerWidth}px)` },
                        ml: { sm: `${drawerWidth}px` },
                        mt: { xs: '53px', sm: '64px' },
                        zIndex: 1099
                    }}
                    elevation={1}
                >
                    <Toolbar sx={[styles.h, styles.bgWhite]}>
                        <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Grid item>
                                <Typography variant="h6" noWrap component="div" sx={{ m: 1, fontWeight: 'bold' }}>
                                    Stock Reset
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Stack spacing={1} direction="row" sx={{ m: 1 }} >

                                    <Button variant="contained" sx={{ textTransform: 'none', backgroundColor: '#0e559d' }} startIcon={<RestartAltIcon />}>
                                        Reset
                                    </Button>

                                    <Button variant="outlined" sx={{ textTransform: 'none' }} startIcon={<HighlightOffIcon />}>
                                        Clear
                                    </Button>
                                </Stack>
                            </Grid>

                            <Grid>
                                <Button variant="contained" sx={{ textTransform: 'none', backgroundColor: '#515c66', m: 1 }} startIcon={<SearchIcon />}>
                                    Search
                                </Button>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
            </HideOnScroll>

            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    '& > :not(style)': {
                        m: 1.5,
                        mt: 7,
                        width: "100%",
                    },
                }}
            >


                <Card variant="outlined" sx={{ padding: 2, }}>
                    <Typography variant="h6" color="black" component="div" sx={{ ml: 2, fontWeight: 'bold' }}>
                        Stock Reset
                    </Typography>

                    <CardContent>
                        <Grid container spacing={1}>
                            <Grid item xs={12} md={3}>
                                <Typography variant="body1">
                                    <Box sx={{ fontWeight: 'bold' }}>Reset No</Box>
                                </Typography>
                                <Grid container spacing={0}>
                                    <Grid item xs={12} md={11}>
                                        <TextField
                                            required
                                            id="filled-search"
                                            fullWidth
                                            size='small'
                                            placeholder='Reset No'
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={1} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
                                        <SearchIcon />
                                    </Grid>
                                </Grid>
                            </Grid>


                            <Grid item xs={12} md={3}>
                                <Box sx={{ fontWeight: 'bold' }}>Reset Date</Box>
                                <Grid item xs={12} md={11}>
                                    <FormControl fullWidth>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoItem >
                                                <DatePicker />
                                            </DemoItem>
                                        </LocalizationProvider>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} md={3}>
                                <Typography variant="body1">
                                    <Box sx={{ fontWeight: 'bold' }}>Reset By <Box component='span' sx={{ color: 'red' }}>*</Box> </Box>
                                </Typography>
                                <Grid item xs={12} md={11}>
                                    <TextField
                                        required
                                        id="filled-search"
                                        fullWidth
                                        size='small'
                                        value='Mayur'
                                    />
                                </Grid>
                            </Grid>

                            <Grid item xs={12} md={3}></Grid>

                            <Grid item xs={12} md={3}>
                                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>To SubLocation <Box component='span' sx={{ color: 'red' }}>*</Box></Typography>
                                <Grid item xs={12} md={11}>
                                    <FormControl fullWidth>
                                        <Select
                                            value={age}
                                            onChange={handleChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem sx={{ ml: 3 }} value="">
                                                <>Ahmedabad</>
                                            </MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={1}>Councelling</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={2}>Diabetes Prevention Awareness</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={3}>First Aid Training</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={4}>OBESITY MANAGMENT</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>

                        </Grid>
                    </CardContent>
                </Card>
            </Box>

            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    '& > :not(style)': {
                        m: 1.5, mt: 0,
                        width: "100%",
                    },
                }}
            >


                <Card variant="outlined">
                    <CardContent>

                        <Typography variant="h6" color="black" sx={{ ml: 2, fontWeight: 'bold' }}>
                            Stock Reset Details
                        </Typography>


                        <div>
                            <Radio
                                checked={selectedValue === 'a'}
                                onChange={handleChange}
                                value="a"
                                name="radio-buttons"
                                inputProps={{ 'aria-label': 'A' }}
                            />
                            Copy
                            <Radio
                                checked={selectedValue === 'b'}
                                onChange={handleChange}
                                value="b"
                                name="radio-buttons"
                                inputProps={{ 'aria-label': 'B' }}
                            />
                            Upload
                        </div>

                        <Button sx={{ textTransform: 'none' }}>1) Stock Reset Template</Button>
                        <Button sx={{ textTransform: 'none' }}>2) Export Stock Item List</Button>


                        <Grid item xs={12} md={6} sx={{ ml: 2 }}>
                            <textarea
                                name="postContent"
                                rows={12}
                                cols={180}
                                placeholder='Copy and Paste data here'
                            />
                        </Grid>

                        <Stack spacing={3} direction="row" sx={{ justifyContent: 'center', mr: 12 }}>
                            <Button variant="outlined" sx={{ textTransform: 'none', ml: 2 }} startIcon={< CheckIcon />}>
                                Validate
                            </Button>
                        </Stack>

                    </CardContent>
                </Card>
            </Box>
        </>

    )
}
