import sharedImports from '../../../Comman/sharedImports';
const {
    React,
    Box,
    Tabs,
    Tab,
    List,
    Grid,
    Select,
    Paper,
    Table,
    Stack,
    Slide,
    styled,
    Button,
    Checkbox,
    TableRow,
    MenuItem,
    TextField,
    PropTypes,
    TableBody,
    Typography,
    FormControl,
    DatePicker,
    TableHead,
    InputLabel,
    DemoItem,
    SearchIcon,
    AdapterDayjs,
    ListItemIcon,
    ListItemText,
    TableContainer,
    TableCell,
    Toolbar,
    AppBar,
    useScrollTrigger,
    tableCellClasses,
    NoteAddIcon,
    ListItemButton,
    FileCopyOutlinedIcon,
    FormControlLabel,
    HighlightOffIcon,
    SaveAsOutlinedIcon,
    LocalizationProvider,
    UnfoldMoreOutlinedIcon,
} = sharedImports;

function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
}
function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
}
function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },

    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));
const rows = [
    { temporaryCode: 65325421, employeToolbareCode: null, name: 'palak', department: 'Finance', status: 'Not Started', fitness: 'Pending', reason: null },
    { temporaryCode: 65325421, employeeCode: null, name: 'palak', department: 'Finance', status: 'Not Started', fitness: 'Pending', reason: null },
    { temporaryCode: 879879879, employeeCode: null, name: 'palak', department: 'Finance', status: 'Not Started', fitness: 'Pending', reason: null },
    { temporaryCode: 879879879, employeeCode: null, name: 'palak', department: 'Finance', status: 'Not Started', fitness: 'Pending', reason: null },
    { temporaryCode: 986544, employeeCode: null, name: 'palak', department: 'Finance', status: 'Not Started', fitness: 'Pending', reason: null },
    { temporaryCode: 986544, employeeCode: null, name: 'palak', department: 'Finance', status: 'Not Started', fitness: 'Pending', reason: null },
    { temporaryCode: 986544, employeeCode: null, name: 'palak', department: 'Finance', status: 'Not Started', fitness: 'Pending', reason: null },
];
const reportListRows = [
    { temporaryCode: 65325421, employeeCode: null, name: 'palak' },
    { temporaryCode: 65325421, employeeCode: null, name: 'palak' },
    { temporaryCode: 879879879, employeeCode: null, name: 'palak' },
    { temporaryCode: 879879879, employeeCode: null, name: 'palak' },
    { temporaryCode: 986544, employeeCode: null, name: 'palak' },
    { temporaryCode: 986544, employeeCode: null, name: 'palak' },
    { temporaryCode: 986544, employeeCode: null, name: 'palak' },
];

const drawerWidth = 300;
function HideOnScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
};


export default function EmployeeCustomSearch(props) {
    const [state, setState] = React.useState({
        TargetGroup: '',
        Show: ''
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setState(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const [value, setValue] = React.useState(0);
    const handleChanges = (event, newValue) => {
        setValue(newValue);
    };

    const styles = {
        firstForm: {
            padding: '16px 16px 34px 16px',
            backgroundColor: '#f1f1f1',
        },
        bgWhite: {
            backgroundColor: 'white',
            color: 'black'
        },
        wFull: {
            width: '100%'
        },
        marginBottom5: {
            marginBottom: 5,
            mt: 5
        },
        TableRow: {
            th: {
                width: '12.5%',
                svg: {
                    float: 'right',
                    color: '#1976d2'
                },
            },
            td: {
                border: '1px solid lightgray',
            },
        },
        h: {
            minHeight: '40px !important'
        },
    }

    const items = [
        'Active Upto',
        'Address Of Occupier',
        'Blood Group',
        'Business',
        'By Product',
        'City Name',
        'Cmo',
        'Code',
        'Company Email',
        'Company Group',
        'Contractor',
        'Country Id',
        'Country Name',
        'Dangerous Operation',
        'Date Of Birth',
        'Date Of Joining',
        'Date Of Leaving',
        'Disability Certified Remark',
        'Disability Remark',
        'Employee Company'
    ]
    const [checked, setChecked] = React.useState([]);
    const [left, setLeft] = React.useState(items.length);
    const [right, setRight] = React.useState(items.length);
    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, right);
    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };
    const handleAllRight = () => {
        setRight(right.concat(left));
        setLeft([]);
    };
    const handleCheckedRight = () => {
        setRight(right.concat(leftChecked));
        setLeft(not(left, leftChecked));
        setChecked(not(checked, leftChecked));
    };
    const handleCheckedLeft = () => {
        setLeft(left.concat(rightChecked));
        setRight(not(right, rightChecked));
        setChecked(not(checked, rightChecked));
    };
    const handleAllLeft = () => {
        setLeft(left.concat(right));
        setRight([]);
    };
    const customList = () => (
        <Paper sx={{ width: '100%', height: 400, overflow: 'auto' }}>
            <List dense component="div" role="list">
                {items.map((value) => {
                    const labelId = value;

                    return (
                        <ListItemButton
                            key={value}
                            role="listitem"
                            onClick={handleToggle(value)}
                        >
                            <ListItemIcon>
                                <Checkbox
                                    checked={checked.indexOf(value) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{
                                        'aria-labelledby': labelId,
                                    }}
                                />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={value} />
                        </ListItemButton>
                    );
                })}
            </List>
        </Paper>
    );

    return (
        <Box sx={styles.root}>
            <HideOnScroll {...props}>
                <AppBar
                    sx={{
                        width: { sm: `calc(100% - ${drawerWidth}px)` },
                        ml: { sm: `${drawerWidth}px` },
                        mt: { xs: '53px', sm: '64px' },
                        zIndex: 1099
                    }}
                    elevation={1}
                >
                    <Toolbar sx={[styles.h, styles.bgWhite]}>
                        <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Grid item>
                                <Typography variant="h6" noWrap component="div" sx={{ m: 1, fontWeight: 'bold' }}>
                                    Employee Custom Search
                                </Typography>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
            </HideOnScroll>

            <Box sx={styles.firstForm}>
                <Box sx={{ maxWidth: { xs: 1, sm: 1, marginTop: '45px' }, bgcolor: 'background.paper', borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs
                        value={value}
                        onChange={handleChanges}
                        variant="scrollable"
                        scrollButtons
                        allowScrollButtonsMobile
                        aria-label="scrollable force tabs example"
                    >
                        <Tab sx={{ textTransform: 'none', color: '#1976d2' }} label="Employee Custom Search" />
                        <Tab sx={{ textTransform: 'none', color: '#1976d2' }} label="Custom Field" />
                        <Tab sx={{ textTransform: 'none', color: '#1976d2' }} label="Report List" />
                    </Tabs>
                </Box>
                <Box sx={[styles.bgWhite, styles.wFull]} className='MainPadding'>
                    <CustomTabPanel value={value} index={0}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} md={3}>
                                <Box sx={{ fontWeight: 'bold' }}>Code</Box>
                                <Grid item xs={12} md={11}>
                                    <TextField
                                        required
                                        id="filled-search"
                                        fullWidth
                                        size='small'
                                        placeholder="Code Like"
                                    />
                                </Grid>
                            </Grid>

                            <Grid item xs={12} md={3}>
                                <Box sx={{ fontWeight: 'bold' }}>First Name</Box>
                                <Grid item xs={12} md={11}>
                                    <TextField
                                        required
                                        id="filled-search"
                                        fullWidth
                                        size='small'
                                        placeholder="First Name Like"
                                    />
                                </Grid>
                            </Grid>

                            <Grid item xs={12} md={3}>
                                <Box sx={{ fontWeight: 'bold' }}>Last Name</Box>
                                <Grid item xs={12} md={11}>
                                    <TextField
                                        required
                                        id="filled-search"
                                        fullWidth
                                        size='small'
                                        placeholder="Last Name "
                                    />
                                </Grid>
                            </Grid>

                            <Grid item xs={12} md={3}>
                                <Box sx={{ fontWeight: 'bold' }}>Target Group</Box>
                                <Grid item xs={12} md={11}>
                                    <FormControl fullWidth>
                                        <Select
                                            name="TargetGroup"
                                            value={state.TargetGroup}
                                            onChange={handleChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem sx={{ ml: 3 }} value="">
                                                <>-All-</>
                                            </MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={10}>Ten</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={20}>Twenty</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value={30}>Thirty</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} md={3}>
                                <Box sx={{ fontWeight: 'bold' }}>DOB(From Date)</Box>
                                <Grid item xs={12} md={11}>
                                    <FormControl fullWidth>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoItem >
                                                <DatePicker />
                                            </DemoItem>
                                        </LocalizationProvider>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} md={3}>
                                <Box sx={{ fontWeight: 'bold' }}>DOB(To Date)</Box>
                                <Grid item xs={12} md={11}>
                                    <FormControl fullWidth>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoItem >
                                                <DatePicker />
                                            </DemoItem>
                                        </LocalizationProvider>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} md={3}>
                                <Box sx={{ fontWeight: 'bold' }}>DOJ(From Date)</Box>
                                <Grid item xs={12} md={11}>
                                    <FormControl fullWidth>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoItem >
                                                <DatePicker />
                                            </DemoItem>
                                        </LocalizationProvider>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} md={3}>
                                <Box sx={{ fontWeight: 'bold' }}>DOB(To Date)</Box>
                                <Grid item xs={12} md={11}>
                                    <FormControl fullWidth>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoItem >
                                                <DatePicker />
                                            </DemoItem>
                                        </LocalizationProvider>
                                    </FormControl>
                                </Grid>
                            </Grid>


                            <Grid item xs={12} md={3}>
                                <FormControlLabel
                                    value="end"
                                    control={<Checkbox />}
                                    label="Company Group"
                                    labelPlacement="end"
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <FormControlLabel
                                    value="end"
                                    control={<Checkbox />}
                                    label="Company"
                                    labelPlacement="end"
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <FormControlLabel
                                    value="end"
                                    control={<Checkbox />}
                                    label="Job Title"
                                    labelPlacement="end"
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <FormControlLabel
                                    value="end"
                                    control={<Checkbox />}
                                    label="Status"
                                    labelPlacement="end"
                                />
                            </Grid>

                            <Grid item xs={12} md={3}>
                                <FormControlLabel
                                    value="end"
                                    control={<Checkbox />}
                                    label="Religion"
                                    labelPlacement="end"
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <FormControlLabel
                                    value="end"
                                    control={<Checkbox />}
                                    label="Marital Status"
                                    labelPlacement="end"
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <FormControlLabel
                                    value="end"
                                    control={<Checkbox />}
                                    label="Qualification"
                                    labelPlacement="end"
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <FormControlLabel
                                    value="end"
                                    control={<Checkbox />}
                                    label="Gender"
                                    labelPlacement="end"
                                />
                            </Grid>

                            <Grid item xs={12} md={3}>
                                <FormControlLabel
                                    value="end"
                                    control={<Checkbox />}
                                    label="Contractor"
                                    labelPlacement="end"
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <FormControlLabel
                                    value="end"
                                    control={<Checkbox />}
                                    label="Designation"
                                    labelPlacement="end"
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <FormControlLabel
                                    value="end"
                                    control={<Checkbox />}
                                    label="Employee Type"
                                    labelPlacement="end"
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <FormControlLabel
                                    value="end"
                                    control={<Checkbox />}
                                    label="Blood Group"
                                    labelPlacement="end"
                                />
                            </Grid>

                            <Grid item xs={12} md={3}>
                                <FormControlLabel
                                    value="end"
                                    control={<Checkbox />}
                                    label="Medical Coverage"
                                    labelPlacement="end"
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <FormControlLabel
                                    value="end"
                                    control={<Checkbox />}
                                    label="Department Group"
                                    labelPlacement="end"
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <FormControlLabel
                                    value="end"
                                    control={<Checkbox />}
                                    label="Department"
                                    labelPlacement="end"
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <FormControlLabel
                                    value="end"
                                    control={<Checkbox />}
                                    label="Save Report"
                                    labelPlacement="end"
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Stack spacing={2} direction='row' justifyContent='center' sx={styles.marginBottom5}>
                                    <Button variant="contained" sx={{ textTransform: 'none' }} startIcon={<SearchIcon />}>Search</Button>
                                    <Button variant="outlined" sx={{ textTransform: 'none' }} startIcon={<HighlightOffIcon />}>Clear</Button>
                                    <Button variant="outlined" sx={{ textTransform: 'none' }} startIcon={<NoteAddIcon />}>Export Contractor Detail</Button>
                                    <Button variant="outlined" sx={{ textTransform: 'none' }} startIcon={<NoteAddIcon />}>Export Employee Detail</Button>
                                    <Button variant="outlined" sx={{ textTransform: 'none' }} startIcon={<NoteAddIcon />} disabled>Generate Report</Button>
                                </Stack>
                            </Grid>

                            <Grid item xs={12} sm={3} md={2} lg={2} sx={{ sm: { display: 'block' } }}>
                                <Box sx={{ fontWeight: 'bold' }}>Show</Box>
                                <FormControl sx={{ width: '100%' }}>
                                    <InputLabel id="demo-simple-select-helper-label" size="small">10</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-helper-label"
                                        id="demo-simple-select-helper"
                                        label="10"
                                        name="Show"
                                        value={state.Show}
                                        onChange={handleChange}
                                        size="small"
                                    >
                                        <MenuItem sx={{ ml: 3 }} value="">
                                            <em>None</em>
                                        </MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={10}>Ten</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={20}>Twenty</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={30}>Thirty</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={2} md={2} lg={2} sx={{ sm: { display: 'block' } }}>
                                <Box sx={{ fontWeight: 'bold' }}>entries</Box>
                                <Button
                                    variant="outlined"
                                    sx={{ textTransform: 'none' }}
                                    startIcon={<FileCopyOutlinedIcon />}>
                                    Export
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={4} md={6} lg={6} sx={{ sm: { display: 'block' } }}></Grid>
                            <Grid item xs={12} sm={3} md={2} lg={2} sx={{ sm: { display: 'block' } }}>
                                <Box sx={{ fontWeight: 'bold' }}>Search: </Box>
                                <TextField size="small" sx={{ width: '100%' }} />
                            </Grid>

                            <Grid item sx={{ minWidth: { xs: 1, sm: 1, xl: 1 }, maxWidth: { md: 1, lg: 1 } }}>
                                <TableContainer component={Paper}>
                                    <Table aria-label="customized table">
                                        <TableHead>
                                            <TableRow sx={styles.TableRow}>
                                                <StyledTableCell>Code <UnfoldMoreOutlinedIcon /></StyledTableCell>
                                                <StyledTableCell>Name <UnfoldMoreOutlinedIcon /></StyledTableCell>
                                                <StyledTableCell>Employee Type <UnfoldMoreOutlinedIcon /></StyledTableCell>
                                                <StyledTableCell>Company <UnfoldMoreOutlinedIcon /></StyledTableCell>
                                                <StyledTableCell>Status <UnfoldMoreOutlinedIcon /></StyledTableCell>
                                                <StyledTableCell>Fitness <UnfoldMoreOutlinedIcon /></StyledTableCell>
                                                <StyledTableCell>Location <UnfoldMoreOutlinedIcon /></StyledTableCell>
                                                <StyledTableCell>Department <UnfoldMoreOutlinedIcon /></StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {rows.map((row) => (
                                                <StyledTableRow key={row.temporaryCode} sx={styles.TableRow}>
                                                    <StyledTableCell> {row.temporaryCode} </StyledTableCell>
                                                    <StyledTableCell>{row.employeeCode}</StyledTableCell>
                                                    <StyledTableCell>{row.name}</StyledTableCell>
                                                    <StyledTableCell>{row.department}</StyledTableCell>
                                                    <StyledTableCell>{row.status}</StyledTableCell>
                                                    <StyledTableCell>{row.fitness}</StyledTableCell>
                                                    <StyledTableCell>{row.reason}</StyledTableCell>
                                                    <StyledTableCell><SaveAsOutlinedIcon color='primary' /></StyledTableCell>
                                                </StyledTableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>

                        </Grid>
                    </CustomTabPanel>

                    <CustomTabPanel value={value} index={1}>
                        <Grid container spacing={2} justifyContent="center" alignItems="center">

                            <Grid item xs={12} md={4}>{customList(left)}</Grid>
                            <Grid item xs={12} md={4}>
                                <Grid container direction="column" alignItems="center">
                                    <Button
                                        sx={{ my: 0.5 }}
                                        variant="outlined"
                                        size="small"
                                        onClick={handleAllRight}
                                        disabled={left.length === 0}
                                        aria-label="move all right"
                                    >
                                        ≫
                                    </Button>
                                    <Button
                                        sx={{ my: 0.5 }}
                                        variant="outlined"
                                        size="small"
                                        onClick={handleCheckedRight}
                                        disabled={leftChecked.length === 0}
                                        aria-label="move selected right"
                                    >
                                        &gt;
                                    </Button>
                                    <Button
                                        sx={{ my: 0.5 }}
                                        variant="outlined"
                                        size="small"
                                        onClick={handleCheckedLeft}
                                        disabled={rightChecked.length === 0}
                                        aria-label="move selected left"
                                    >
                                        &lt;
                                    </Button>
                                    <Button
                                        sx={{ my: 0.5 }}
                                        variant="outlined"
                                        size="small"
                                        onClick={handleAllLeft}
                                        disabled={right.length === 0}
                                        aria-label="move all left"
                                    >
                                        ≪
                                    </Button>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={4}>{customList(right)}</Grid>

                        </Grid>
                    </CustomTabPanel>

                    <CustomTabPanel value={value} index={2}>
                        <Grid container spacing={1}>

                            <Grid item xs={12} sm={3} md={2} lg={2} sx={{ sm: { display: 'block' } }}>
                                <Box sx={{ fontWeight: 'bold' }}>Show</Box>
                                <FormControl sx={{ width: '100%' }}>
                                    <InputLabel id="demo-simple-select-helper-label" size="small">10</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-helper-label"
                                        id="demo-simple-select-helper"
                                        label="10"
                                        name="Show"
                                        value={state.Show}
                                        onChange={handleChange}
                                        size="small"
                                    >
                                        <MenuItem sx={{ ml: 3 }} value="">
                                            <em>None</em>
                                        </MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={10}>Ten</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={20}>Twenty</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={30}>Thirty</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={2} md={2} lg={2} sx={{ sm: { display: 'block' } }}>
                                <Box sx={{ fontWeight: 'bold' }}>entries</Box>
                                <Button
                                    variant="outlined"
                                    sx={{ textTransform: 'none' }}
                                    startIcon={<FileCopyOutlinedIcon />}>
                                    Export
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={4} md={6} lg={6} sx={{ sm: { display: 'block' } }}></Grid>
                            <Grid item xs={12} sm={3} md={2} lg={2} sx={{ sm: { display: 'block' } }}>
                                <Box sx={{ fontWeight: 'bold' }}>Search: </Box>
                                <TextField size="small" sx={{ width: '100%' }} />
                            </Grid>

                            <Grid item sx={{ minWidth: { xs: 1, sm: 1, xl: 1 }, maxWidth: { md: 1, lg: 1 } }}>
                                <TableContainer component={Paper}>
                                    <Table aria-label="customized table">
                                        <TableHead>
                                            <TableRow sx={styles.TableRow}>
                                                <StyledTableCell>Name <UnfoldMoreOutlinedIcon /></StyledTableCell>
                                                <StyledTableCell>Column Name <UnfoldMoreOutlinedIcon /></StyledTableCell>
                                                <StyledTableCell> <UnfoldMoreOutlinedIcon /></StyledTableCell>
                                                <StyledTableCell>Delete <UnfoldMoreOutlinedIcon /></StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {reportListRows.map((row) => (
                                                <StyledTableRow key={row.temporaryCode} sx={styles.TableRow}>
                                                    <StyledTableCell> {row.temporaryCode} </StyledTableCell>
                                                    <StyledTableCell>{row.employeeCode}</StyledTableCell>
                                                    <StyledTableCell>{row.name}</StyledTableCell>
                                                    <StyledTableCell><SaveAsOutlinedIcon color='primary' /></StyledTableCell>
                                                </StyledTableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>

                        </Grid>
                    </CustomTabPanel>
                </Box>
            </Box>
        </Box>
    )
}