import sharedImports from '../../../Comman/sharedImports';
const {
    React,
    Box,
    Grid,
    Button,
    Dialog,
    Slide,
    Toolbar,
    AppBar,
    PropTypes,
    TextField,
    Typography,
    DialogTitle,
    DialogContent,
    DialogActions,
    HighlightOffIcon,
    useScrollTrigger,
    SaveOutlinedIcon,
    DialogContentText,
    FormatListBulletedIcon,
} = sharedImports;

const drawerWidth = 300;
function HideOnScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
};

export default function ChangePassword(props) {

    const [open, setOpen] = React.useState(false);
    const handleClickOpen = (newValue) => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    
    const styles = {
        firstForm: {
            padding: '16px 16px 0px 16px',
            backgroundColor: '#f1f1f1'
        },
        bgWhite: {
            backgroundColor: 'white',
            color: 'black'
        },
        wFull: {
            width: '100%'
        },
        marginBottom2: {
            marginBottom: 2,
        },
        TableRow: {
            th: {
                width: '12.5%',
                svg: {
                    float: 'right',
                    color: '#1976d2'
                },
            },
            td: {
                border: '1px solid lightgray',
            }
        },
        h: {
            minHeight: '40px !important'
        },
        p20: {
            padding: '20px'
        },
        bRadius: {
            borderRadius: '5px',
            marginTop: '45px'
        },
        color: {
            color: 'red',
        }
    }

    return (
        <Box sx={styles.root}>
            <HideOnScroll {...props}>
                <AppBar
                    sx={{
                        width: { sm: `calc(100% - ${drawerWidth}px)` },
                        ml: { sm: `${drawerWidth}px` },
                        mt: { xs: '53px', sm: '64px' },
                        zIndex: 1099
                    }}
                    elevation={1}
                >
                    <Toolbar sx={[styles.h, styles.bgWhite]}>
                        <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Grid item xs={12} md={5.5}>
                                <Typography variant="h6" noWrap component="div" sx={{ m: 1, fontWeight: 'bold' }}>
                                    Change Password
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={5.5}>
                                <Button variant='contained' startIcon={<SaveOutlinedIcon />} sx={{ mr: 2, m: 1, textTransform: 'none' }}> Save </Button>
                                <Button variant='outlined' startIcon={<HighlightOffIcon />} sx={{ textTransform: 'none' }}> Clear </Button>
                            </Grid>
                            <Grid item xs={12} md={1}>
                                <React.Fragment>
                                    <Button
                                        variant="contained"
                                        startIcon={<FormatListBulletedIcon />}
                                        onClick={handleClickOpen}
                                        item xs={12} md={1} sx={{ textTransform: 'none', m: 1 }}>
                                        List
                                    </Button>
                                    <Dialog
                                        open={open}
                                        onClose={handleClose}
                                        PaperProps={{
                                            component: 'form',
                                            onSubmit: (event) => {
                                                event.preventDefault();
                                                const formData = new FormData(event.currentTarget);
                                                const formJson = Object.fromEntries(formData.entries());
                                                const email = formJson.email;
                                                console.log(email);
                                                handleClose();
                                            },
                                        }}
                                    >
                                        <DialogTitle>Subscribe</DialogTitle>
                                        <DialogContent>
                                            <DialogContentText>
                                                To subscribe to this website, please enter your email address here. We
                                                will send updates occasionally.
                                            </DialogContentText>
                                            <TextField
                                                autoFocus
                                                required
                                                margin="dense"
                                                id="name"
                                                name="email"
                                                label="Email Address"
                                                type="email"
                                                fullWidth
                                                variant="standard"
                                            />
                                        </DialogContent>
                                        <DialogActions>
                                            <Button sx={{ textTransform: 'none' }} onClick={handleClose}>Cancel</Button>
                                            <Button sx={{ textTransform: 'none' }} type="submit">Subscribe</Button>
                                        </DialogActions>
                                    </Dialog>
                                </React.Fragment>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
            </HideOnScroll>
            {/* <Box sx={[styles.subHeader, styles.bgWhite, styles.justifySB, styles.alignC, isSmallScreen ? { width: '100%' } : { width: '100%' }]} display={isSmallScreenBtn ? 'block' : 'flex'}>
                <Box component="h3" sx={isSmallScreen ? { minWidth: '100%' } : { width: '70%' }}>Change Password</Box>
                <Box sx={isSmallScreen ? { minWidth: '100%isSmallScr', mb: 2 } : { width: '100%' }}>
                    <Stack spacing={2} direction='row'>
                        <Button variant="contained" sx={{textTransform: 'none'}} startIcon={<SaveIcon />}>Save</Button>
                        <Button variant="outlined" sx={{textTransform: 'none'}} startIcon={<CancelOutlinedIcon />}>Clear</Button>
                    </Stack>
                </Box>
                <React.Fragment>
                    <Button
                        variant="contained"
                        startIcon={<FormatListBulletedIcon />}
                        onClick={handleClickOpen}
                        xs={12} md={2}>
                        List
                    </Button>
                    <Dialog
                        open={open}
                        onClose={handleClose}
                        PaperProps={{
                            component: 'form',
                            onSubmit: (event) => {
                                event.preventDefault();
                                const formData = new FormData(event.currentTarget);
                                const formJson = Object.fromEntries(formData.entries());
                                const email = formJson.email;
                                console.log(email);
                                handleClose();
                            },
                        }}
                    >
                        <DialogTitle sx={{fontWeight: 'bold'}}>Subscribe</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                To subscribe to this website, please enter your email address here. We
                                will send updates occasionally.
                            </DialogContentText>
                            <TextField
                                autoFocus
                                required
                                margin="dense"
                                id="name"
                                name="email"
                                label="Email Address"
                                type="email"
                                fullWidth
                                variant="standard"
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button sx={{textTransform: 'none'}} onClick={handleClose}>Cancel</Button>
                            <Button sx={{textTransform: 'none'}} type="submit">Subscribe</Button>
                        </DialogActions>
                    </Dialog>
                </React.Fragment>
            </Box> */}
            <Box sx={styles.firstForm}>
                <Box sx={[styles.bgWhite, styles.wFull, styles.p20, styles.bRadius]} className='MainPadding'>
                    <Grid container spacing={1} sx={styles.pAlart}>

                        <Grid item xs={12} md={3}>
                            <Box sx={{ fontWeight: 'bold' }}>User</Box>
                            <Grid item xs={12} md={11}>
                                <TextField
                                    required
                                    id="filled-search"
                                    fullWidth
                                    size='small'
                                    placeholder=" 30053101"
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={3}></Grid>
                        <Grid item xs={12} md={3}></Grid>
                        <Grid item xs={12} md={3}></Grid>

                        <Grid item xs={12} md={3}>
                            <Box sx={{ fontWeight: 'bold' }}>Old Password <Box component='span' sx={{ color: 'red' }}>*</Box></Box>
                            <Grid item xs={12} md={11}>
                                <TextField
                                    required
                                    id="filled-search"
                                    fullWidth
                                    size='small'
                                    placeholder=" Old Password "
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={3}></Grid>
                        <Grid item xs={12} md={3}></Grid>
                        <Grid item xs={12} md={3}></Grid>

                        <Grid item xs={12} md={3}>
                            <Box sx={{ fontWeight: 'bold' }}>New Password <Box component='span' sx={{ color: 'red' }}>*</Box></Box>
                            <Grid item xs={12} md={11}>
                                <TextField
                                    required
                                    id="filled-search"
                                    fullWidth
                                    size='small'
                                    placeholder=" New Password "
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={3}></Grid>
                        <Grid item xs={12} md={3}></Grid>
                        <Grid item xs={12} md={3}></Grid>

                        <Grid item xs={12} md={3}>
                            <Box sx={{ fontWeight: 'bold' }}>Confirm Password <Box component='span' sx={{ color: 'red' }}>*</Box></Box>
                            <Grid item xs={12} md={11}>
                                <TextField
                                    required
                                    id="filled-search"
                                    fullWidth
                                    size='small'
                                    placeholder=" Confirm Password "
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={3}></Grid>
                        <Grid item xs={12} md={3}></Grid>
                        <Grid item xs={12} md={3}></Grid>
                    </Grid>
                </Box>
            </Box>
        </Box>
    )
}