import sharedImports from '../../../Comman/sharedImports';
const {
    React,
    PropTypes,
    Box,
    Link,
    Grid,
    Slide,
    Tooltip,
    Select,
    AppBar,
    IconButton,
    Button,
    Toolbar,
    TableBody,
    MenuItem,
    Typography,
    TextField,
    CssBaseline,
    EditNoteOutlinedIcon,
    SearchOutlinedIcon,
    FormControl,
    useScrollTrigger,
    styled,
    FileCopyOutlinedIcon,
    UnfoldMoreOutlinedIcon,
    CancelOutlinedIcon,
    TableContainer,
    TableCell,
    Paper,
    tableCellClasses,
    Table,
    AddCircleOutlineOutlinedIcon,
    TableHead,
    TableRow,
} = sharedImports;


const drawerWidth = 300;
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));
const reportListRows = [
    { title: 'Health benefits of Magnesium', category: 'Physiotherapy', subCategory: 'BENEFITS OF MULTIVITAMINS', type: 'Physiotherapy', fileType: 'PDF', },
    { title: 'Health benefits of Magnesium', category: 'Physiotherapy', subCategory: 'BENEFITS OF MULTIVITAMINS', type: 'Physiotherapy', fileType: 'PDF', },
    { title: 'Health benefits of Magnesium', category: 'Physiotherapy', subCategory: 'BENEFITS OF MULTIVITAMINS', type: 'Physiotherapy', fileType: 'PDF', },
    { title: 'Health benefits of Magnesium', category: 'Physiotherapy', subCategory: 'BENEFITS OF MULTIVITAMINS', type: 'Physiotherapy', fileType: 'PDF', },
    { title: 'Health benefits of Magnesium', category: 'Physiotherapy', subCategory: 'BENEFITS OF MULTIVITAMINS', type: 'Physiotherapy', fileType: 'PDF', },
    { title: 'Health benefits of Magnesium', category: 'Physiotherapy', subCategory: 'BENEFITS OF MULTIVITAMINS', type: 'Physiotherapy', fileType: 'PDF', },
    { title: 'Health benefits of Magnesium', category: 'Physiotherapy', subCategory: 'BENEFITS OF MULTIVITAMINS', type: 'Physiotherapy', fileType: 'PDF', },
    { title: 'Health benefits of Magnesium', category: 'Physiotherapy', subCategory: 'BENEFITS OF MULTIVITAMINS', type: 'Physiotherapy', fileType: 'PDF', },
    { title: 'Health benefits of Magnesium', category: 'Physiotherapy', subCategory: 'BENEFITS OF MULTIVITAMINS', type: 'Physiotherapy', fileType: 'PDF', },
];
function HideOnScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
};
const styles = {
    TableRow: {
        th: {
            padding: '5px',
            svg: {
                float: 'right',
                color: '#1976d2'
            },
        },
        td: {
            padding: '5px',
            border: '1px solid lightgray',
        }
    },
    h: {
        minHeight: '40px !important'
    },
    bgWhite: {
        bgcolor: 'white',
        color: 'black'
    }
}

export default function HealthDocumentUpload(props) {

    const [state, setState] = React.useState({
        Category: '',
        SubCategory: '',
        Type: '',
        FileType: ''
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setState(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    return (
        <>
            <React.Fragment>
                <Box>
                    <CssBaseline />
                    <HideOnScroll {...props}>
                        <AppBar
                            sx={{
                                width: { sm: `calc(100% - ${drawerWidth}px)` },
                                ml: { sm: `${drawerWidth}px` },
                                mt: { xs: '53px', sm: '64px' },
                                zIndex: 1099
                            }}
                            elevation={1}
                        >
                            <Toolbar sx={[styles.h, styles.bgWhite]}>
                                <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Grid item xs={12} md={10}>
                                        <Typography variant="h6" noWrap component="div" sx={{ m: 1, fontWeight: 'bold' }}>
                                            Health Document Upload
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                        <Link to='/dashboard/support/health-library/health-document-upload/create'>
                                            <Button variant='contained' startIcon={<AddCircleOutlineOutlinedIcon />} sx={{ bgcolor: 'gray', textTransform: 'none', m: 1 }}> Add </Button>
                                        </Link>
                                    </Grid>
                                </Grid>
                            </Toolbar>
                        </AppBar>
                    </HideOnScroll>
                    <Box sx={{ mt: 8, mb: 2, mx: 2, bgcolor: 'white', p: 3, borderRadius: 1 }}>
                        <Box component="div" xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Title </Box>
                                    <Grid item xs={12} md={11}>
                                        <TextField
                                            required
                                            id="filled-search"
                                            fullWidth
                                            size='small'
                                            placeholder="Title "
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Category</Box>
                                    <Grid item xs={12} md={11}>
                                        <FormControl fullWidth>
                                            <Select
                                                name="Category"
                                                value={state.Category}
                                                onChange={handleChange}
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Without label' }}
                                            >
                                                <MenuItem sx={{ ml: 3 }} value="">
                                                    <>-All-</>
                                                </MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={1}>HC18-00001</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={2}>HC21-2</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Sub Category</Box>
                                    <Grid item xs={12} md={11}>
                                        <FormControl fullWidth>
                                            <Select
                                                name="SubCategory"
                                                value={state.SubCategory}
                                                onChange={handleChange}
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Without label' }}
                                            >
                                                <MenuItem sx={{ ml: 3 }} value="">
                                                    <>-All-</>
                                                </MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={1}>Security</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={2}>Alert</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={3}>Configuration</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={4}>Globalization</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={5}>Utilities</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={6}>Admin Tool Master</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={7}>Doctor</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={8}>Appointment</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={9}>Inventory Management</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={10}>Order Entry</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={11}>History</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Type</Box>
                                    <Grid item xs={12} md={11}>
                                        <FormControl fullWidth>
                                            <Select
                                                name="Type"
                                                value={state.Type}
                                                onChange={handleChange}
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Without label' }}
                                            >
                                                <MenuItem sx={{ ml: 3 }} value="">
                                                    <>-All-</>
                                                </MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={1}>Security</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={2}>Alert</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={3}>Configuration</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={4}>Globalization</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={5}>Utilities</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={6}>Admin Tool Master</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={7}>Doctor</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={8}>Appointment</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={9}>Inventory Management</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={10}>Order Entry</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={11}>History</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>File Type</Box>
                                    <Grid item xs={12} md={11}>
                                        <FormControl fullWidth>
                                            <Select
                                                name="FileType"
                                                value={state.FileType}
                                                onChange={handleChange}
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Without label' }}
                                            >
                                                <MenuItem sx={{ ml: 3 }} value="">
                                                    <>-All-</>
                                                </MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={1}>Security</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={2}>Alert</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={3}>Configuration</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={4}>Globalization</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={5}>Utilities</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={6}>Admin Tool Master</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={7}>Doctor</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={8}>Appointment</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={9}>Inventory Management</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={10}>Order Entry</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={11}>History</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={9} sx={{ display: 'flex', justifyContent: 'end', alignItems: 'end' }}>
                                    <Button variant='contained' startIcon={<SearchOutlinedIcon />} sx={{ m: 1, textTransform: 'none' }}>Search</Button>
                                    <Button variant='outlined' startIcon={<CancelOutlinedIcon />} sx={{ m: 1, textTransform: 'none' }}>Clear</Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                    <Box sx={{ mt: 2, mx: 2, bgcolor: 'white', p: 3, borderRadius: 1 }}>
                        <Box component="div" xs={12}>
                            <Grid container spacing={2} direction='row' xm={12} sm={12} md={12} lg={12} xl={12} justifyContent='space-between' sx={{ mb: 2 }}>
                                <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Grid item sx={{ mr: 2, display: 'flex', alignItems: 'center' }}>
                                        <Box component='span' sx={{ fontWeight: 'bold' }}>Show </Box>
                                        <TextField
                                            id="outlined-number"
                                            type="number"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            defaultValue='10'
                                            sx={{ width: '9ch' }}
                                            size='small'
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Box component='span' sx={{ fontWeight: 'bold' }}>entries </Box>
                                        <Button variant='outlined' sx={{ textTransform: 'none' }} startIcon={<FileCopyOutlinedIcon />}>Export</Button>
                                    </Grid>
                                </Grid>
                                <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Box component='span' sx={{ fontWeight: 'bold' }}>Search: </Box>
                                    <TextField
                                        id="filled-search"
                                        type="search"
                                        size='small'
                                    />
                                </Grid>
                            </Grid>
                            <TableContainer component={Paper} sx={{ mb: 2 }}>
                                <Table aria-label="customized table">
                                    <TableHead>
                                        <TableRow sx={styles.TableRow}>
                                            <StyledTableCell>Title<UnfoldMoreOutlinedIcon /></StyledTableCell>
                                            <StyledTableCell>Category<UnfoldMoreOutlinedIcon /></StyledTableCell>
                                            <StyledTableCell>Sub Category<UnfoldMoreOutlinedIcon /></StyledTableCell>
                                            <StyledTableCell>Type<UnfoldMoreOutlinedIcon /></StyledTableCell>
                                            <StyledTableCell>File Type<UnfoldMoreOutlinedIcon /></StyledTableCell>
                                            <StyledTableCell><UnfoldMoreOutlinedIcon /></StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {reportListRows.map((row) => (
                                            <StyledTableRow key={row.title} sx={styles.TableRow}>
                                                <StyledTableCell>{row.title}</StyledTableCell>
                                                <StyledTableCell>{row.category}</StyledTableCell>
                                                <StyledTableCell>{row.subCategory}</StyledTableCell>
                                                <StyledTableCell>{row.type}</StyledTableCell>
                                                <StyledTableCell>{row.fileType}</StyledTableCell>
                                                <StyledTableCell>
                                                    <Tooltip title="Edit">
                                                        <Link to='/support/health-library/health-document-upload/update'>
                                                            <IconButton>
                                                                <EditNoteOutlinedIcon sx={{ color: '#1785e3' }} />
                                                            </IconButton>
                                                        </Link>
                                                    </Tooltip>
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Grid container spacing={2} direction='row' xm={12} sm={12} md={12} lg={12} xl={12} justifyContent='space-between'>
                                <Grid item sx={{ fontWeight: 'bold' }}>
                                    Showing 0 to 0 of 0 entries
                                </Grid>
                                <Grid item>
                                    <Button variant='contained' sx={{ mr: 2, textTransform: 'none' }} disabled>Previous</Button>
                                    <Button variant='contained' sx={{ textTransform: 'none' }} disabled>Next</Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Box>
            </React.Fragment >
        </>
    )
}