import sharedImports from '../../../Comman/sharedImports';
const {
    React,
    PropTypes,
    Box,
    Tabs,
    Tab,
    Grid,
    styled,
    DemoItem,
    DatePicker,
    Slide,
    Select,
    AdapterDayjs,
    Checkbox,
    AppBar,
    Button,
    Toolbar,
    FormControlLabel,
    LocalizationProvider,
    MenuItem,
    Typography,
    TextField,
    CssBaseline,
    FormControl,
    FileUploadOutlinedIcon,
    AddCircleOutlineOutlinedIcon,
    useScrollTrigger,
    CancelOutlinedIcon,
    FileCopyOutlinedIcon,
} = sharedImports;


const drawerWidth = 300;
const VisuallyHiddenInput = styled('input')({});
function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
function HideOnScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
};
const styles = {
    TableRow: {
        th: {
            width: '11.111111%',
            svg: {
                float: 'right',
                color: '#1976d2'
            },
        },
        td: {
            border: '1px solid lightgray',
        }
    },
    h: {
        minHeight: '40px !important'
    },
    bgWhite: {
        bgcolor: 'white',
        color: 'black'
    }
}

export default function FreezePeriodicList(props) {

    const [age, setAge] = React.useState('');
    const [value, setValue] = React.useState(0);
    function handleChange(event, newValue) {
        setAge(event.target.value);
        setValue(newValue);
    }

    return (
        <>
            <React.Fragment>
                <Box>
                    <CssBaseline />
                    <HideOnScroll {...props}>
                        <AppBar
                            sx={{
                                width: { sm: `calc(100% - ${drawerWidth}px)` },
                                ml: { sm: `${drawerWidth}px` },
                                mt: { xs: '53px', sm: '64px' },
                                zIndex: 1099
                            }}
                            elevation={1}
                        >
                            <Toolbar sx={[styles.h, styles.bgWhite]}>
                                <Grid container>
                                    <Grid item xs={12} md={12}>
                                        <Typography variant="h6" sx={{ fontWeight: 'bold' }} noWrap component="div">
                                            Freeze Periodic List
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Toolbar>
                        </AppBar>
                    </HideOnScroll>
                    <Box sx={{ mt: 7, mb: 2, mx: 2, bgcolor: 'white', p: 3, borderRadius: 1 }}>
                        <Box component="div" xs={12}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                    <Tab sx={{ textTransform: 'none', color: '#1976d2' }} label="Search" {...a11yProps(0)} />
                                    <Tab sx={{ textTransform: 'none', color: '#1976d2' }} label="Add Employee" {...a11yProps(1)} />
                                </Tabs>
                            </Box>
                            <CustomTabPanel value={value} index={0}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={3}>
                                        <Box sx={{ fontWeight: 'bold' }}>Sub Location</Box>
                                        <Grid item xs={12} md={11}>
                                            <FormControl fullWidth>
                                                <Select
                                                    value={age}
                                                    onChange={handleChange}
                                                    displayEmpty
                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                >
                                                    <MenuItem sx={{ ml: 3 }} value="">
                                                        <>-All-</>
                                                    </MenuItem>
                                                    <MenuItem sx={{ ml: 3 }} value={1}>Councelling</MenuItem>
                                                    <MenuItem sx={{ ml: 3 }} value={2}>Diabetes Prevention Awareness</MenuItem>
                                                    <MenuItem sx={{ ml: 3 }} value={3}>First Aid Training</MenuItem>
                                                    <MenuItem sx={{ ml: 3 }} value={4}>OBESITY MANAGMENT</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <Box sx={{ fontWeight: 'bold' }}>Round <Box component='span' sx={{ color: 'red' }}>*</Box></Box>
                                        <Grid item xs={12} md={11}>
                                            <FormControl fullWidth>
                                                <Select
                                                    value={age}
                                                    onChange={handleChange}
                                                    displayEmpty
                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                >
                                                    <MenuItem sx={{ ml: 3 }} value="">
                                                        <>-All-</>
                                                    </MenuItem>
                                                    <MenuItem sx={{ ml: 3 }} value={1}>Councelling</MenuItem>
                                                    <MenuItem sx={{ ml: 3 }} value={2}>Diabetes Prevention Awareness</MenuItem>
                                                    <MenuItem sx={{ ml: 3 }} value={3}>First Aid Training</MenuItem>
                                                    <MenuItem sx={{ ml: 3 }} value={4}>OBESITY MANAGMENT</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <Box sx={{ fontWeight: 'bold' }}>Designation</Box>
                                        <Grid item xs={12} md={11}>
                                            <FormControl fullWidth>
                                                <Select
                                                    value={age}
                                                    onChange={handleChange}
                                                    displayEmpty
                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                >
                                                    <MenuItem sx={{ ml: 3 }} value="">
                                                        <>-All-</>
                                                    </MenuItem>
                                                    <MenuItem sx={{ ml: 3 }} value={1}>Councelling</MenuItem>
                                                    <MenuItem sx={{ ml: 3 }} value={2}>Diabetes Prevention Awareness</MenuItem>
                                                    <MenuItem sx={{ ml: 3 }} value={3}>First Aid Training</MenuItem>
                                                    <MenuItem sx={{ ml: 3 }} value={4}>OBESITY MANAGMENT</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <Box sx={{ fontWeight: 'bold' }}>Frequency Days</Box>
                                        <Grid item xs={12} md={11}>
                                            <TextField
                                                required
                                                id="filled-search"
                                                fullWidth
                                                size='small'
                                                placeholder="Frequency Days"
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={3}>
                                        <Box sx={{ fontWeight: 'bold' }}>Code</Box>
                                        <Grid item xs={12} md={11}>
                                            <TextField
                                                required
                                                id="filled-search"
                                                fullWidth
                                                size='small'
                                                placeholder="Code"
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <Box sx={{ fontWeight: 'bold' }}>First Name</Box>
                                        <Grid item xs={12} md={11}>
                                            <TextField
                                                required
                                                id="filled-search"
                                                fullWidth
                                                size='small'
                                                placeholder="First Name"
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <Box sx={{ fontWeight: 'bold' }}>Status</Box>
                                        <Grid item xs={12} md={11}>
                                            <FormControl fullWidth>
                                                <Select
                                                    value={age}
                                                    onChange={handleChange}
                                                    displayEmpty
                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                >
                                                    <MenuItem sx={{ ml: 3 }} value="">
                                                        <>-All-</>
                                                    </MenuItem>
                                                    <MenuItem sx={{ ml: 3 }} value={1}>Councelling</MenuItem>
                                                    <MenuItem sx={{ ml: 3 }} value={2}>Diabetes Prevention Awareness</MenuItem>
                                                    <MenuItem sx={{ ml: 3 }} value={3}>First Aid Training</MenuItem>
                                                    <MenuItem sx={{ ml: 3 }} value={4}>OBESITY MANAGMENT</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={3}>
                                        <Box sx={{ fontWeight: 'bold' }}>Target Group</Box>
                                        <Grid item xs={12} md={11}>
                                            <FormControl fullWidth>
                                                <Select
                                                    value={age}
                                                    onChange={handleChange}
                                                    displayEmpty
                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                >
                                                    <MenuItem sx={{ ml: 3 }} value="">
                                                        <>-All-</>
                                                    </MenuItem>
                                                    <MenuItem sx={{ ml: 3 }} value={1}>Councelling</MenuItem>
                                                    <MenuItem sx={{ ml: 3 }} value={2}>Diabetes Prevention Awareness</MenuItem>
                                                    <MenuItem sx={{ ml: 3 }} value={3}>First Aid Training</MenuItem>
                                                    <MenuItem sx={{ ml: 3 }} value={4}>OBESITY MANAGMENT</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <FormControlLabel
                                            value="end"
                                            control={<Checkbox />}
                                            label="Department Group"
                                            labelPlacement="end"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <FormControlLabel
                                            value="end"
                                            control={<Checkbox />}
                                            label="Department"
                                            labelPlacement="end"
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={3}>
                                        <Box sx={{ fontWeight: 'bold' }}>Business</Box>
                                        <Grid item xs={12} md={11}>
                                            <FormControl fullWidth>
                                                <Select
                                                    value={age}
                                                    onChange={handleChange}
                                                    displayEmpty
                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                >
                                                    <MenuItem sx={{ ml: 3 }} value="">
                                                        <>-All-</>
                                                    </MenuItem>
                                                    <MenuItem sx={{ ml: 3 }} value={1}>Councelling</MenuItem>
                                                    <MenuItem sx={{ ml: 3 }} value={2}>Diabetes Prevention Awareness</MenuItem>
                                                    <MenuItem sx={{ ml: 3 }} value={3}>First Aid Training</MenuItem>
                                                    <MenuItem sx={{ ml: 3 }} value={4}>OBESITY MANAGMENT</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <Box sx={{ fontWeight: 'bold' }}>Sub Business</Box>
                                        <Grid item xs={12} md={11}>
                                            <FormControl fullWidth>
                                                <Select
                                                    value={age}
                                                    onChange={handleChange}
                                                    displayEmpty
                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                >
                                                    <MenuItem sx={{ ml: 3 }} value="">
                                                        <>-All-</>
                                                    </MenuItem>
                                                    <MenuItem sx={{ ml: 3 }} value={1}>Councelling</MenuItem>
                                                    <MenuItem sx={{ ml: 3 }} value={2}>Diabetes Prevention Awareness</MenuItem>
                                                    <MenuItem sx={{ ml: 3 }} value={3}>First Aid Training</MenuItem>
                                                    <MenuItem sx={{ ml: 3 }} value={4}>OBESITY MANAGMENT</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <Box sx={{ fontWeight: 'bold' }}>Company</Box>
                                        <Grid item xs={12} md={11}>
                                            <FormControl fullWidth>
                                                <Select
                                                    value={age}
                                                    onChange={handleChange}
                                                    displayEmpty
                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                >
                                                    <MenuItem sx={{ ml: 3 }} value="">
                                                        <>-All-</>
                                                    </MenuItem>
                                                    <MenuItem sx={{ ml: 3 }} value={1}>Councelling</MenuItem>
                                                    <MenuItem sx={{ ml: 3 }} value={2}>Diabetes Prevention Awareness</MenuItem>
                                                    <MenuItem sx={{ ml: 3 }} value={3}>First Aid Training</MenuItem>
                                                    <MenuItem sx={{ ml: 3 }} value={4}>OBESITY MANAGMENT</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </CustomTabPanel>
                            <CustomTabPanel value={value} index={1}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={3}>
                                        <Box sx={{ fontWeight: 'bold' }}>Round <Box component='span' sx={{ color: 'red' }}>*</Box></Box>
                                        <Grid item xs={12} md={11}>
                                            <FormControl fullWidth>
                                                <Select
                                                    value={age}
                                                    onChange={handleChange}
                                                    displayEmpty
                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                >
                                                    <MenuItem sx={{ ml: 3 }} value="">
                                                        <>-All-</>
                                                    </MenuItem>
                                                    <MenuItem sx={{ ml: 3 }} value={1}>Councelling</MenuItem>
                                                    <MenuItem sx={{ ml: 3 }} value={2}>Diabetes Prevention Awareness</MenuItem>
                                                    <MenuItem sx={{ ml: 3 }} value={3}>First Aid Training</MenuItem>
                                                    <MenuItem sx={{ ml: 3 }} value={4}>OBESITY MANAGMENT</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <Box sx={{ fontWeight: 'bold' }}>Employee Code <Box component='span' sx={{ color: 'red' }}>*</Box></Box>
                                        <Grid item xs={12} md={11}>
                                            <TextField
                                                required
                                                id="filled-search"
                                                fullWidth
                                                size='small'
                                                placeholder="Employee Code "
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <Box sx={{ fontWeight: 'bold' }}>Employee Name</Box>
                                        <Grid item xs={12} md={11}>
                                            <FormControl fullWidth>
                                                <Select
                                                    value={age}
                                                    onChange={handleChange}
                                                    displayEmpty
                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                >
                                                    <MenuItem sx={{ ml: 3 }} value="">
                                                        <>-All-</>
                                                    </MenuItem>
                                                    <MenuItem sx={{ ml: 3 }} value={1}>Councelling</MenuItem>
                                                    <MenuItem sx={{ ml: 3 }} value={2}>Diabetes Prevention Awareness</MenuItem>
                                                    <MenuItem sx={{ ml: 3 }} value={3}>First Aid Training</MenuItem>
                                                    <MenuItem sx={{ ml: 3 }} value={4}>OBESITY MANAGMENT</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <Box sx={{ fontWeight: 'bold' }}>Target Group <Box component='span' sx={{ color: 'red' }}>*</Box></Box>
                                        <Grid item xs={12} md={11}>
                                            <FormControl fullWidth>
                                                <Select
                                                    value={age}
                                                    onChange={handleChange}
                                                    displayEmpty
                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                >
                                                    <MenuItem sx={{ ml: 3 }} value="">
                                                        <>-All-</>
                                                    </MenuItem>
                                                    <MenuItem sx={{ ml: 3 }} value={1}>Councelling</MenuItem>
                                                    <MenuItem sx={{ ml: 3 }} value={2}>Diabetes Prevention Awareness</MenuItem>
                                                    <MenuItem sx={{ ml: 3 }} value={3}>First Aid Training</MenuItem>
                                                    <MenuItem sx={{ ml: 3 }} value={4}>OBESITY MANAGMENT</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={3}>
                                        <Box sx={{ fontWeight: 'bold' }}>Planned Examination Date</Box>
                                        <Grid item xs={12} md={11}>
                                            <FormControl fullWidth>
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DemoItem >
                                                        <DatePicker />
                                                    </DemoItem>
                                                </LocalizationProvider>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={12}>
                                        <Box component='div'>
                                            <Button sx={{ textTransform: 'none' }} href='#text-button'>Download Template</Button>
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={3}>
                                        <VisuallyHiddenInput type='file' />
                                    </Grid>
                                </Grid>
                            </CustomTabPanel>
                            <Grid container spacing={2} direction='row' xm={12} sm={12} md={12} lg={12} xl={12} justifyContent='center'>
                                <Grid item>
                                    <Button variant='contained' sx={{ textTransform: 'none' }} startIcon={<AddCircleOutlineOutlinedIcon />}>Add</Button>
                                </Grid>
                                <Grid item>
                                    <Button variant='outlined' sx={{ textTransform: 'none' }} startIcon={<CancelOutlinedIcon />}>Clear</Button>
                                </Grid>
                                <Grid item>
                                    <Button variant='outlined' sx={{ textTransform: 'none' }} startIcon={<FileCopyOutlinedIcon />}>Export Target Group</Button>
                                </Grid>
                                <Grid item>
                                    <Button variant='contained' sx={{ textTransform: 'none' }} startIcon={<FileUploadOutlinedIcon />}>Upload Employee</Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Box>
            </React.Fragment >
        </>
    )
}