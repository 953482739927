import React from 'react';
import { Box } from '@mui/material';
import SideBar from './Navbar/sideBar';

export default function Dashboard() {
    return (
        <>
            <Box sx={{ display: 'flex', width: '100%' }}>
                <SideBar />
            </Box>
        </>
    )
}