import sharedImports from '../../../Comman/sharedImports';
const {
    React,
    PropTypes,
    Box,
    Grid,
    Slide,
    AppBar,
    FormControl,
    Select,
    FileCopyOutlinedIcon,
    TableContainer,
    UnfoldMoreOutlinedIcon,
    Paper,
    Table,
    styled,
    TableCell,
    Tabs,
    Tab,
    tableCellClasses,
    TableHead,
    TableBody,
    SearchOutlinedIcon,
    MenuItem,
    LocalizationProvider,
    AdapterDayjs,
    DemoItem,
    TableRow,
    DatePicker,
    Button,
    Toolbar,
    TextField,
    Typography,
    CssBaseline,
    useScrollTrigger,
    CancelOutlinedIcon,
} = sharedImports;


const drawerWidth = 300;
function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));
const reportListRows = [
    { employeeType: '-', activities: null, counselling: null, firstAidTraining: 25, healthInduction: null, healthPEPTalk: null, healthTip: null, total: 25, },
    { employeeType: 'Contract Worker', activities: null, counselling: null, firstAidTraining: 25, healthInduction: null, healthPEPTalk: null, healthTip: null, total: 25, },
    { employeeType: 'Regular', activities: null, counselling: null, firstAidTraining: 25, healthInduction: null, healthPEPTalk: null, healthTip: null, total: 25, },
    { employeeType: 'Third Party Contract', activities: null, counselling: null, firstAidTraining: 25, healthInduction: null, healthPEPTalk: null, healthTip: null, total: 25, },
    { employeeType: 'Total', activities: 0, counselling: 0, firstAidTraining: 100, healthInduction: 0, healthPEPTalk: 0, healthTip: 0, total: 100, },
];
function HideOnScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
};
const styles = {
    TableRow: {
        th: {
            svg: {
                float: 'right',
                color: '#1976d2'
            },
        },
        td: {
            border: '1px solid lightgray',
        }
    },
    h: {
        minHeight: '40px !important'
    },
    bgWhite: {
        bgcolor: 'white',
        color: 'black'
    }
}

export default function MISDashboard(props) {

    const [age, setAge] = React.useState('');
    const [value, setValue] = React.useState(0);
    function handleChange(event, newValue) {
        setAge(event.target.value);
        setValue(newValue);
    }

    return (
        <>
            <React.Fragment>
                <Box>
                    <CssBaseline />
                    <HideOnScroll {...props}>
                        <AppBar
                            sx={{
                                width: { sm: `calc(100% - ${drawerWidth}px)` },
                                ml: { sm: `${drawerWidth}px` },
                                mt: { xs: '53px', sm: '64px' },
                                zIndex: 1099
                            }}
                            elevation={1}
                        >
                            <Toolbar sx={[styles.h, styles.bgWhite]}>
                                <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Grid item>
                                        <Typography variant="h6" noWrap component="div" sx={{ m: 1, fontWeight: 'bold' }}>
                                            MIS Dashboard
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Toolbar>
                        </AppBar>
                    </HideOnScroll>
                    <Box sx={{ mt: 5, mb: 2, mx: 2, bgcolor: 'white', p: 3, borderRadius: 1 }}>
                        <Box component="div" xs={12}>
                            <Grid container spacing={2} sx={{ mb: 2 }}>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Main Type</Box>
                                    <Grid item xs={12} md={11}>
                                        <FormControl fullWidth>
                                            <Select
                                                value={age}
                                                onChange={handleChange}
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Without label' }}
                                            >
                                                <MenuItem sx={{ ml: 3 }} value="">
                                                    <>-All-</>
                                                </MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={1}>Councelling</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={2}>Diabetes Prevention Awareness</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={3}>First Aid Training</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={3}>OBESITY MANAGMENT</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Sub Location</Box>
                                    <Grid item xs={12} md={11}>
                                        <FormControl fullWidth>
                                            <Select
                                                value={age}
                                                onChange={handleChange}
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Without label' }}
                                            >
                                                <MenuItem sx={{ ml: 3 }} value="">
                                                    <>-All-</>
                                                </MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={1}>Councelling</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={2}>Diabetes Prevention Awareness</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={3}>First Aid Training</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={3}>OBESITY MANAGMENT</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Round</Box>
                                    <Grid item xs={12} md={11}>
                                        <FormControl fullWidth>
                                            <Select
                                                value={age}
                                                onChange={handleChange}
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Without label' }}
                                            >
                                                <MenuItem sx={{ ml: 3 }} value="">
                                                    <>-All-</>
                                                </MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={1}>Councelling</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={2}>Diabetes Prevention Awareness</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={3}>First Aid Training</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={3}>OBESITY MANAGMENT</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} sx={{ mb: 2 }}>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>From Date</Box>
                                    <Grid item xs={12} md={11}>
                                        <FormControl fullWidth>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DemoItem >
                                                    <DatePicker />
                                                </DemoItem>
                                            </LocalizationProvider>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>To Date</Box>
                                    <Grid item xs={12} md={11}>
                                        <FormControl fullWidth>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DemoItem >
                                                    <DatePicker />
                                                </DemoItem>
                                            </LocalizationProvider>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} sx={{ mb: 2 }}>
                                <Grid item xs={12} md={12} align='center'>
                                    <Button variant='contained' startIcon={<SearchOutlinedIcon />} sx={{ m: 1, textTransform: 'none' }}>Search</Button>
                                    <Button variant='outlined' startIcon={<CancelOutlinedIcon />} sx={{ m: 1, textTransform: 'none' }}>Clear</Button>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                <Tab sx={{ textTransform: 'none', color: '#1976d2', }} label="OPD Movement" {...a11yProps(0)} />
                                <Tab sx={{ textTransform: 'none', color: '#1976d2', }} label="PreEmployment Medical Exam" {...a11yProps(1)} />
                                <Tab sx={{ textTransform: 'none', color: '#1976d2', }} label="Periodic Medical Exam" {...a11yProps(2)} />
                                <Tab sx={{ textTransform: 'none', color: '#1976d2', }} label="Health Connect" {...a11yProps(3)} />
                            </Tabs>
                        </Box>
                        <CustomTabPanel value={value} index={0}>
                            <Box component='h1' align='center' sx={{ m: 0, p: 0 }}>OPD Movement</Box>
                            <Box component='div' align='center' sx={{ m: 0, p: 1, bgcolor: '#f1f1f1', borderRadius: 1 }}>No Record Found</Box>
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={1}>
                            Item Two
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={2}>
                            Item Three
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={3}>
                            <Box component="div" xs={12}>
                                <Grid container spacing={2} direction='row' xm={12} sm={12} md={12} lg={12} xl={12} justifyContent='space-between' sx={{ mb: 2 }}>
                                    <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                                        <Grid item sx={{ mr: 2, display: 'flex', alignItems: 'center' }}>
                                            <Box component='span' sx={{ fontWeight: 'bold' }}>Show </Box>
                                            <TextField
                                                id="outlined-number"
                                                type="number"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                defaultValue='10'
                                                sx={{ width: '9ch' }}
                                                size='small'
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Box component='span' sx={{ fontWeight: 'bold' }}>entries </Box>
                                            <Button variant='outlined' sx={{ textTransform: 'none' }} startIcon={<FileCopyOutlinedIcon />}>Export</Button>
                                        </Grid>
                                    </Grid>
                                    <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                                        <Box component='span' sx={{ fontWeight: 'bold' }}>Search: </Box>
                                        <TextField
                                            id="filled-search"
                                            type="search"
                                            size='small'
                                        />
                                    </Grid>
                                </Grid>
                                <TableContainer component={Paper} sx={{ mb: 2 }}>
                                    <Table aria-label="customized table">
                                        <TableHead>
                                            <TableRow sx={styles.TableRow}>
                                                <StyledTableCell>Employee Type<UnfoldMoreOutlinedIcon /></StyledTableCell>
                                                <StyledTableCell>Activities<UnfoldMoreOutlinedIcon /></StyledTableCell>
                                                <StyledTableCell>Counselling<UnfoldMoreOutlinedIcon /></StyledTableCell>
                                                <StyledTableCell>First Aid Training<UnfoldMoreOutlinedIcon /></StyledTableCell>
                                                <StyledTableCell>Health Induction<UnfoldMoreOutlinedIcon /></StyledTableCell>
                                                <StyledTableCell>Health PEP Talk<UnfoldMoreOutlinedIcon /></StyledTableCell>
                                                <StyledTableCell>Health Tip<UnfoldMoreOutlinedIcon /></StyledTableCell>
                                                <StyledTableCell>Total<UnfoldMoreOutlinedIcon /></StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {reportListRows.map((row) => (
                                                <StyledTableRow key={row.employeeType} sx={styles.TableRow}>
                                                    <StyledTableCell>{row.employeeType}</StyledTableCell>
                                                    <StyledTableCell>{row.activities}</StyledTableCell>
                                                    <StyledTableCell>{row.counselling}</StyledTableCell>
                                                    <StyledTableCell>{row.firstAidTraining}</StyledTableCell>
                                                    <StyledTableCell>{row.healthInduction}</StyledTableCell>
                                                    <StyledTableCell>{row.healthPEPTalk}</StyledTableCell>
                                                    <StyledTableCell>{row.healthTip}</StyledTableCell>
                                                    <StyledTableCell>{row.total}</StyledTableCell>
                                                </StyledTableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <Grid container spacing={2} direction='row' xm={12} sm={12} md={12} lg={12} xl={12} justifyContent='space-between'>
                                    <Grid item sx={{ fontWeight: 'bold' }}>
                                        Showing 1 to 10 of 10 entries
                                    </Grid>
                                    <Grid item>
                                        <Button variant='contained' sx={{ mr: 2, textTransform: 'none' }} disabled>Previous</Button>
                                        <Button variant='contained' sx={{ textTransform: 'none' }} disabled>Next</Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </CustomTabPanel>
                    </Box>
                </Box>
            </React.Fragment>
        </>
    )
}