import sharedImports from '../../../Comman/sharedImports';
const {
    React,
    PropTypes,
    Box,
    Grid,
    Slide,
    Select,
    AppBar,
    Button,
    Toolbar,
    MenuItem,
    Typography,
    TextField,
    CssBaseline,
    FormControl,
    useScrollTrigger,
    styled,
    FileCopyOutlinedIcon,
    UnfoldMoreOutlinedIcon,
    TableBody,
    CancelOutlinedIcon,
    LocalizationProvider,
    TableContainer,
    TableCell,
    Paper,
    tableCellClasses,
    Table,
    FormControlLabel,
    TableHead,
    TableRow,
    Checkbox,
    SearchOutlinedIcon,
    DemoItem,
    DatePicker,
    VisibilityOutlinedIcon,
    AdapterDayjs,
} = sharedImports;


const drawerWidth = 300;
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));
const reportListRows = [
    { catagory: null, assetTag: null, assetName: null, modelNumber: null, lastMaintDate: null, nextMaintDate: null, status: null, location: null, critical: null, }
];
function HideOnScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
};
const styles = {
    TableRow: {
        th: {
            width: '11.111111%',
            svg: {
                float: 'right',
                color: '#1976d2'
            },
        },
        td: {
            border: '1px solid lightgray',
        }
    },
    h: {
        minHeight: '40px !important'
    },
    bgWhite: {
        bgcolor: 'white',
        color: 'black'
    }
}

export default function AssetMaintenanceSchedule(props) {

    const [state, setState] = React.useState({
        AssetCategory: '',
        Status: '',
        Location: ''
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setState(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    return (
        <>
            <React.Fragment>
                <Box>
                    <CssBaseline />
                    <HideOnScroll {...props}>
                        <AppBar
                            sx={{
                                width: { sm: `calc(100% - ${drawerWidth}px)` },
                                ml: { sm: `${drawerWidth}px` },
                                mt: { xs: '53px', sm: '64px' },
                                zIndex: 1099
                            }}
                            elevation={1}
                        >
                            <Toolbar sx={[styles.h, styles.bgWhite]}>
                                <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Grid item>
                                        <Typography variant="h6" noWrap component="div" sx={{ m: 1, fontWeight: 'bold' }}>
                                            Asset Maintenance Schedule
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Toolbar>
                        </AppBar>
                    </HideOnScroll>
                    <Box sx={{ mt: 8, mb: 2, mx: 2, bgcolor: 'white', p: 3, borderRadius: 1 }}>
                        <Box component="div" xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>From Date</Box>
                                    <Grid item xs={12} md={11}>
                                        <FormControl fullWidth>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DemoItem >
                                                    <DatePicker />
                                                </DemoItem>
                                            </LocalizationProvider>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>To Date</Box>
                                    <Grid item xs={12} md={11}>
                                        <FormControl fullWidth>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DemoItem >
                                                    <DatePicker />
                                                </DemoItem>
                                            </LocalizationProvider>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Asset Category</Box>
                                    <Grid item xs={12} md={11}>
                                        <FormControl fullWidth>
                                            <Select
                                                name="AssetCategory"
                                                value={state.AssetCategory}
                                                onChange={handleChange}
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Without label' }}
                                            >
                                                <MenuItem sx={{ ml: 3 }} value="">
                                                    <>-All-</>
                                                </MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={1}>Male nurse salary</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={2}>MEDICINE</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={3}>Other expense</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Asset Tag</Box>
                                    <Grid item xs={12} md={11}>
                                        <TextField
                                            required
                                            id="filled-search"
                                            fullWidth
                                            size='small'
                                            placeholder="Asset Tag"
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Asset Name</Box>
                                    <Grid item xs={12} md={11}>
                                        <TextField
                                            required
                                            id="filled-search"
                                            fullWidth
                                            size='small'
                                            placeholder="Asset Name"
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Status</Box>
                                    <Grid item xs={12} md={11}>
                                        <FormControl fullWidth>
                                            <Select
                                                name="Status"
                                                value={state.Status}
                                                onChange={handleChange}
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Without label' }}
                                            >
                                                <MenuItem sx={{ ml: 3 }} value="">
                                                    <>-All-</>
                                                </MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={1}>Male nurse salary</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={2}>MEDICINE</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={3}>Other expense</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} sx={{ mb: 3 }}>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Location</Box>
                                    <Grid item xs={12} md={11}>
                                        <FormControl fullWidth>
                                            <Select
                                                name="Location"
                                                value={state.Location}
                                                onChange={handleChange}
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Without label' }}
                                            >
                                                <MenuItem sx={{ ml: 3 }} value="">
                                                    <>-All-</>
                                                </MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={1}>Male nurse salary</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={2}>MEDICINE</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={3}>Other expense</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Model No.</Box>
                                    <Grid item xs={12} md={11}>
                                        <TextField
                                            required
                                            id="filled-search"
                                            fullWidth
                                            size='small'
                                            placeholder="Model No."
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} sx={{ mb: 5 }}>
                                <Grid item xs={12} md={3}>
                                    <FormControlLabel
                                        value="end"
                                        control={<Checkbox />}
                                        label="Critical"
                                        labelPlacement="end"
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} direction='row' xm={12} sm={12} md={12} lg={12} xl={12} justifyContent='center'>
                                <Grid item>
                                    <Button variant='contained' sx={{ textTransform: 'none' }} startIcon={<SearchOutlinedIcon />}>Search</Button>
                                </Grid>
                                <Grid item>
                                    <Button variant='outlined' sx={{ textTransform: 'none' }} startIcon={<CancelOutlinedIcon />}>Clear</Button>
                                </Grid>
                                <Grid item>
                                    <Button variant='contained' sx={{ textTransform: 'none' }} startIcon={<VisibilityOutlinedIcon />}>View Report</Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                    <Box sx={{ mt: 2, mb: 7, mx: 2, bgcolor: 'white', p: 3, borderRadius: 1 }}>
                        <Box component="div" xs={12}>
                            <Grid container spacing={2} direction='row' xm={12} sm={12} md={12} lg={12} xl={12} justifyContent='space-between' sx={{ mb: 2 }}>
                                <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Grid item sx={{ mr: 2, display: 'flex', alignItems: 'center' }}>
                                        <Box component='span' sx={{ fontWeight: 'bold' }}>Show </Box>
                                        <TextField
                                            id="outlined-number"
                                            type="number"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            defaultValue='10'
                                            sx={{ width: '9ch' }}
                                            size='small'
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Box component='span' sx={{ fontWeight: 'bold' }}>entries </Box>
                                        <Button variant='outlined' sx={{ textTransform: 'none' }} startIcon={<FileCopyOutlinedIcon />}>Export</Button>
                                    </Grid>
                                </Grid>
                                <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Box component='span' sx={{ fontWeight: 'bold' }}>Search: </Box>
                                    <TextField
                                        id="filled-search"
                                        type="search"
                                        size='small'
                                    />
                                </Grid>
                            </Grid>
                            <TableContainer component={Paper} sx={{ mb: 2 }}>
                                <Table aria-label="customized table">
                                    <TableHead>
                                        <TableRow sx={styles.TableRow}>
                                            <StyledTableCell>Catagory<UnfoldMoreOutlinedIcon /></StyledTableCell>
                                            <StyledTableCell>Asset Tag<UnfoldMoreOutlinedIcon /></StyledTableCell>
                                            <StyledTableCell>Asset Name<UnfoldMoreOutlinedIcon /></StyledTableCell>
                                            <StyledTableCell>Model Number<UnfoldMoreOutlinedIcon /></StyledTableCell>
                                            <StyledTableCell>Last Maint. Date<UnfoldMoreOutlinedIcon /></StyledTableCell>
                                            <StyledTableCell>Next Maint. Date<UnfoldMoreOutlinedIcon /></StyledTableCell>
                                            <StyledTableCell>Status<UnfoldMoreOutlinedIcon /></StyledTableCell>
                                            <StyledTableCell>Location<UnfoldMoreOutlinedIcon /></StyledTableCell>
                                            <StyledTableCell>Critical<UnfoldMoreOutlinedIcon /></StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {reportListRows.map((row) => (
                                            <StyledTableRow key={row.catagory} sx={styles.TableRow}>
                                                <StyledTableCell>{row.catagory}</StyledTableCell>
                                                <StyledTableCell>{row.assetTag}</StyledTableCell>
                                                <StyledTableCell>{row.assetName}</StyledTableCell>
                                                <StyledTableCell>{row.modelNumber}</StyledTableCell>
                                                <StyledTableCell>{row.lastMaintDate}</StyledTableCell>
                                                <StyledTableCell>{row.nextMaintDate}</StyledTableCell>
                                                <StyledTableCell>{row.status}</StyledTableCell>
                                                <StyledTableCell>{row.location}</StyledTableCell>
                                                <StyledTableCell>{row.critical}</StyledTableCell>
                                            </StyledTableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Grid container spacing={2} direction='row' xm={12} sm={12} md={12} lg={12} xl={12} justifyContent='space-between'>
                                <Grid item sx={{ fontWeight: 'bold' }}>
                                    Showing 0 to 0 of 0 entries
                                </Grid>
                                <Grid item>
                                    <Button variant='contained' sx={{ mr: 2, textTransform: 'none' }} disabled>Prev</Button>
                                    <Button variant='contained' sx={{ textTransform: 'none' }} disabled>Next</Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Box>
            </React.Fragment>
        </>
    )
}