import sharedImports from '../../../Comman/sharedImports';
const {
    React,
    PropTypes,
    Box,
    Tabs,
    Tab,
    Grid,
    Slide,
    AppBar,
    Button,
    Toolbar,
    TextField,
    MenuItem,
    LocalizationProvider,
    Select,
    TableRow,
    TableBody,
    AdapterDayjs,
    Paper,
    Checkbox,
    Typography,
    CssBaseline,
    SearchOutlinedIcon,
    TableContainer,
    TableHead,
    FormControl,
    DatePicker,
    DemoItem,
    Table,
    TableCell,
    styled,
    tableCellClasses,
    useScrollTrigger,
    CancelOutlinedIcon,
} = sharedImports;


const drawerWidth = 300;
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));
const reportListRows = [
    { assetTag: null, assetName: null }
];
function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
function HideOnScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
};
const styles = {
    TableRow: {
        th: {
            border: '1px solid #a3a3a3',
            svg: {
                float: 'right',
                color: '#1976d2'
            },
        },
        td: {
            border: '1px solid lightgray',
        }
    },
    h: {
        minHeight: '40px !important'
    },
    bgWhite: {
        bgcolor: 'white',
        color: 'black'
    }
}

export default function AssetIssueReturn(props) {
    const [state, setState] = React.useState({
        AssetCategory: '',
        AssetCategorys: ''
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setState(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const [value, setValue] = React.useState(0);

    function handleChanges(event, newValue) {
        setValue(newValue);
    }

    return (
        <>
            <React.Fragment>
                <Box>
                    <CssBaseline />
                    <HideOnScroll {...props}>
                        <AppBar
                            sx={{
                                width: { sm: `calc(100% - ${drawerWidth}px)` },
                                ml: { sm: `${drawerWidth}px` },
                                mt: { xs: '53px', sm: '64px' },
                                zIndex: 1099
                            }}
                            elevation={1}
                        >
                            <Toolbar sx={[styles.h, styles.bgWhite]}>
                                <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Grid item>
                                        <Typography variant="h6" noWrap component="div" sx={{ m: 1, fontWeight: 'bold' }}>
                                            Asset Issue Return
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Button variant='contained' startIcon={<SearchOutlinedIcon />} sx={{ m: 1, textTransform: 'none' }}> Issue Log </Button>
                                    </Grid>
                                </Grid>
                            </Toolbar>
                        </AppBar>
                    </HideOnScroll>
                    <Box sx={{ mt: 6, mb: 2, mx: 2, bgcolor: 'white', p: 3, borderRadius: 1 }}>
                        <Box component="div" xs={12}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <Tabs value={value} onChange={handleChanges} aria-label="basic tabs example">
                                    <Tab sx={{ textTransform: 'none', color: '#1976d2', }} label="Asset Issue" {...a11yProps(0)} />
                                    <Tab sx={{ textTransform: 'none', color: '#1976d2', }} label="Asset Return" {...a11yProps(1)} />
                                </Tabs>
                            </Box>
                            <CustomTabPanel value={value} index={0}>
                                <Grid container spacing={2} sx={{ mb: 5 }}>
                                    <Grid item xs={12} md={3}>
                                        <Box sx={{ fontWeight: 'bold' }}>Asset Category <Box component='span' sx={{ color: 'red' }}>*</Box></Box>
                                        <Grid item xs={12} md={11}>
                                            <FormControl fullWidth>
                                                <Select
                                                    name="AssetCategory"
                                                    value={state.AssetCategory}
                                                    onChange={handleChange}
                                                    displayEmpty
                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                >
                                                    <MenuItem sx={{ ml: 3 }} value="">
                                                        <>-All-</>
                                                    </MenuItem>
                                                    <MenuItem sx={{ ml: 3 }} value={1}>BP Instrument- First-aid room</MenuItem>
                                                    <MenuItem sx={{ ml: 3 }} value={2}>ECG</MenuItem>
                                                    <MenuItem sx={{ ml: 3 }} value={3}>Oxygen Concentrator</MenuItem>
                                                    <MenuItem sx={{ ml: 3 }} value={4}>Concentrator</MenuItem>
                                                    <MenuItem sx={{ ml: 3 }} value={5}>Infusion pump</MenuItem>
                                                    <MenuItem sx={{ ml: 3 }} value={6}>Thermal Gun</MenuItem>
                                                    <MenuItem sx={{ ml: 3 }} value={7}>Glucometer</MenuItem>
                                                    <MenuItem sx={{ ml: 3 }} value={8}>Two para oximeter</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <Box sx={{ fontWeight: 'bold' }}>Employee Code <Box component='span' sx={{ color: 'red' }}>*</Box></Box>
                                        <Grid item xs={12} md={11}>
                                            <TextField
                                                required
                                                id="filled-search"
                                                fullWidth
                                                size='small'
                                                placeholder="Employee Code "
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} md={3}>
                                        <Box sx={{ fontWeight: 'bold' }}>Employee Name <Box component='span' sx={{ color: 'red' }}>*</Box></Box>
                                        <Grid item xs={12} md={11}>
                                            <TextField
                                                required
                                                id="filled-search"
                                                fullWidth
                                                size='small'
                                                placeholder="Employee Name "
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} md={3}>
                                        <Box sx={{ fontWeight: 'bold' }}>Date <Box component='span' sx={{ color: 'red' }}>*</Box></Box>
                                        <Grid item xs={12} md={11}>
                                            <FormControl fullWidth>
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DemoItem >
                                                        <DatePicker />
                                                    </DemoItem>
                                                </LocalizationProvider>
                                            </FormControl>
                                        </Grid>
                                    </Grid>

                                </Grid>
                                <TableContainer component={Paper} sx={{ mb: 7 }}>
                                    <Table aria-label="customized table">
                                        <TableHead>
                                            <TableRow sx={styles.TableRow}>
                                                <StyledTableCell>
                                                    <Checkbox sx={{ p: 0 }} />
                                                </StyledTableCell>
                                                <StyledTableCell>Asset Tag</StyledTableCell>
                                                <StyledTableCell>Asset Name</StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {reportListRows.map((row) => (
                                                <StyledTableRow key={row.assetTag} sx={styles.TableRow}>
                                                    <StyledTableCell></StyledTableCell>
                                                    <StyledTableCell>{row.assetTag}</StyledTableCell>
                                                    <StyledTableCell>{row.assetName}</StyledTableCell>
                                                </StyledTableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <Button variant='contained' sx={{ mr: 1, textTransform: 'none' }}>Issue</Button>
                                    <Button variant='outlined' sx={{ textTransform: 'none' }} startIcon={<CancelOutlinedIcon />}>Clear</Button>
                                </Box>
                            </CustomTabPanel>
                            <CustomTabPanel value={value} index={1}>
                                <Grid container spacing={2} sx={{ mb: 5 }}>
                                    <Grid item xs={12} md={3}>
                                        <Box sx={{ fontWeight: 'bold' }}>Asset Category <Box component='span' sx={{ color: 'red' }}>*</Box></Box>
                                        <Grid item xs={12} md={11}>
                                            <FormControl fullWidth>
                                                <Select
                                                    name="AssetCategorys"
                                                    value={state.AssetCategorys}
                                                    onChange={handleChange}
                                                    displayEmpty
                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                >
                                                    <MenuItem sx={{ ml: 3 }} value="">
                                                        <>-All-</>
                                                    </MenuItem>
                                                    <MenuItem sx={{ ml: 3 }} value={1}>BP Instrument- First-aid room</MenuItem>
                                                    <MenuItem sx={{ ml: 3 }} value={2}>ECG</MenuItem>
                                                    <MenuItem sx={{ ml: 3 }} value={3}>Oxygen Concentrator</MenuItem>
                                                    <MenuItem sx={{ ml: 3 }} value={4}>Concentrator</MenuItem>
                                                    <MenuItem sx={{ ml: 3 }} value={5}>Infusion pump</MenuItem>
                                                    <MenuItem sx={{ ml: 3 }} value={6}>Thermal Gun</MenuItem>
                                                    <MenuItem sx={{ ml: 3 }} value={7}>Glucometer</MenuItem>
                                                    <MenuItem sx={{ ml: 3 }} value={8}>Two para oximeter</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>

                                </Grid>
                                <TableContainer component={Paper} sx={{ mb: 7 }}>
                                    <Table aria-label="customized table">
                                        <TableHead>
                                            <TableRow sx={styles.TableRow}>
                                                <StyledTableCell>
                                                    <Checkbox sx={{ p: 0 }} />
                                                </StyledTableCell>
                                                <StyledTableCell>Asset Tag</StyledTableCell>
                                                <StyledTableCell>Asset Name</StyledTableCell>
                                                <StyledTableCell>Employee Code</StyledTableCell>
                                                <StyledTableCell>Employee Name</StyledTableCell>
                                                <StyledTableCell>Issued Date</StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {reportListRows.map((row) => (
                                                <StyledTableRow key={row.assetTag} sx={styles.TableRow}>
                                                    <StyledTableCell></StyledTableCell>
                                                    <StyledTableCell>{row.assetTag}</StyledTableCell>
                                                    <StyledTableCell>{row.assetName}</StyledTableCell>
                                                </StyledTableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <Button variant='contained' sx={{ mr: 1, textTransform: 'none' }}>Return</Button>
                                    <Button variant='outlined' sx={{ textTransform: 'none' }} startIcon={<CancelOutlinedIcon />}>Clear</Button>
                                </Box>
                            </CustomTabPanel>
                        </Box>
                    </Box>
                </Box>
            </React.Fragment>
        </>
    )
}