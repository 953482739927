import sharedImports from '../../../Comman/sharedImports';
const {
    React,
    PropTypes,
    Box,
    Grid,
    Radio,
    Alert,
    Slide,
    AppBar,
    Button,
    Toolbar,
    RadioGroup,
    Typography,
    CssBaseline,
    FormControlLabel,
    useScrollTrigger,
    NoteAddOutlinedIcon,
    FileUploadOutlinedIcon,
    styled,
} = sharedImports;

const drawerWidth = 300;
const VisuallyHiddenInput = styled('input')({});

function HideOnScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
};

const styles = {
    h: {
        minHeight: '40px !important'
    },
    bgWhite: {
        bgcolor: 'white',
        color: 'black'
    }
}

export default function ImportEmployee(props) {
    return (
        <>
            <React.Fragment>
                <Box>
                    <CssBaseline />
                    <HideOnScroll {...props}>
                        <AppBar
                            sx={{
                                width: { sm: `calc(100% - ${drawerWidth}px)` },
                                ml: { sm: `${drawerWidth}px` },
                                mt: { xs: '53px', sm: '64px' },
                                zIndex: 1099
                            }}
                            elevation={1}
                        >
                            <Toolbar sx={[styles.h, styles.bgWhite]}>
                                <Typography variant="h6" sx={{fontWeight: 'bold'}} noWrap component="div">
                                    Import Employee
                                </Typography>
                            </Toolbar>
                        </AppBar>
                    </HideOnScroll>
                    <Box sx={{ mt: 7, mb: 2, mx: 2, bgcolor: 'white', p: 3, borderRadius: 1 }}>
                        <Alert severity="info" sx={{ color: 'red', fontWeight: 'bold', fontSize: '16px', bgcolor: 'lightgray' }}>
                            Notes: Text data to be Separated
                            <br />
                            Auto-entry Fields: Employee Group, Employee Type Group, Employee Type, Employee Sub Type, Company, Legal Entity, Designation, Location, Target Group, Grade
                            <br />
                            Mandatoy Fields: Code, First Name, Date of Birth, Gender, Status, Date of Joining, Department, Employee Type Group, Employee Type, Employee Sub Type,
                            <br />
                            Location, Grade, Designation, Target Group, Process Type & Employee Group
                        </Alert>
                        <Box component="div" xs={12}>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                            >
                                <FormControlLabel
                                    value='New Employee'
                                    control={<Radio />}
                                    label='New Employee'
                                />
                                <FormControlLabel
                                    value='Update Employee'
                                    control={<Radio />}
                                    label='Update Employee'
                                />
                            </RadioGroup>
                            <Box component='div'>
                                <Button sx={{textTransform: 'none'}} href='#text-button'>Download Template</Button>
                            </Box>
                            <Grid spacing={2} xm={12} sm={12} md={12} lg={12} xl={12} sx={{mb: 2}}>
                                <VisuallyHiddenInput type='file' />
                                <Button variant='contained' sx={{textTransform: 'none'}} startIcon={<FileUploadOutlinedIcon />}>Import</Button>
                            </Grid>
                            <Grid container spacing={2} direction='row' xm={12} sm={12} md={12} lg={12} xl={12} justifyContent='center'>
                                <Grid item>
                                    <Button variant='outlined' sx={{textTransform: 'none'}} startIcon={<NoteAddOutlinedIcon />}>Export Emp Sub Type</Button>
                                </Grid>
                                <Grid item>
                                    <Button variant='outlined' sx={{textTransform: 'none'}} startIcon={<NoteAddOutlinedIcon />}>Export Department</Button>
                                </Grid>
                                <Grid item>
                                    <Button variant='outlined' sx={{textTransform: 'none'}} startIcon={<NoteAddOutlinedIcon />}>Export Designation</Button>
                                </Grid>
                                <Grid item>
                                    <Button variant='outlined' sx={{textTransform: 'none'}} startIcon={<NoteAddOutlinedIcon />}>Export TargetGroup</Button>
                                </Grid>
                                <Grid item>
                                    <Button variant='outlined' sx={{textTransform: 'none'}} startIcon={<NoteAddOutlinedIcon />}>Export Grade</Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Box>
            </React.Fragment>
        </>
    )
}