import React from 'react';
import HR from './SideBarMenus/hr';
import MIS from './SideBarMenus/mis';
import Support from './SideBarMenus/support';
import AdminTool from './SideBarMenus/adminTool';
import Diagnostic from './SideBarMenus/diagnostic';
import Clinical from './SideBarMenus/clinical';
import Safety from './SideBarMenus/safety';
import '../style.css'
import Examination from './SideBarMenus/examination';
import OPD from './SideBarMenus/opd';
import AppointmentModule from './SideBarMenus/appointmentModule';
import MyDetails from './SideBarMenus/myDetails';
import Stock from './SideBarMenus/stock';

export default function NavbarSideBarMenu() {

    return (
        <>
            <Diagnostic />
            <Clinical />
            <Safety />
            <HR />
            <Support />
            <MIS />
            <AdminTool />
            <Examination />
            <OPD />
            <AppointmentModule />
            <MyDetails />
            <Stock />
        </>
    )
}